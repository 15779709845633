@use "_mixins";
@use "_extends";
@use "_colors";

/* Content Block Feefo */
.content-block-feefo { 
    section { text-align: center;
        h2 { @include mixins.font(1.2, 1.4, 18px, 400, 0,  #1A1A1A); text-align:center; text-transform:uppercase; margin:0 0 8px;
            @include mixins.mq(500) { margin:0 0 4px; }   
        }
        h3 { @include mixins.font(3.2, 5.2, 56px, 700, 0,  #1A1A1A); text-align:center; text-transform:uppercase; margin:0 0 32px; padding: 0 15px;
            @include mixins.mq(940) { margin:0 0 40px; @include mixins.font(3.2, 3.2, 36px, 700, 0,  #1A1A1A, "Magazine Grotesque"); }
            @include mixins.mq(500) { margin:0 0 0px;}
        }
    }
    &>.container{
        @include mixins.mq(450){overflow: hidden; 
            #feefo-service-review-carousel-widgetId{transform: scale(0.85); display: grid; justify-content: center;}
        }
        
    }
}