@use "_mixins";
@use "_extends";
@use "_colors";

/* Content Block Inspiration */
.content-block-inspiration { 
    @include mixins.mq(520) { padding:40px 0; }
    .container { text-align:center;
        h2 { width:100%; @include mixins.font(1.2, 1.4, 18px, 400, 0,  #1A1A1A); text-align:center; text-transform:uppercase; margin:0 0 8px;
            @include mixins.mq(520) { margin:0 0 4px; }   
        }
        h3 { width:100%; @include mixins.font(3.2, 5.2, 56px, 700, 0,  #1A1A1A); text-align:center; text-transform:uppercase; margin:0 0 32px;
            @include mixins.mq(940) { margin:0 0 32px; @include mixins.font(3.2, 3.2, 36px, 700, 0,  #1A1A1A); }
        }
        .three-col { padding-bottom:64px; 
            @include mixins.mq(940) { padding-bottom:0; }
        }
        .cards {
            @include mixins.mq(520) { gap:0; }   
            .card {
                .card-title {
                    p { text-align:left; }
                }
                .description { text-align:left; }
            }
        }
        button { padding:8px 16px !important; min-height:40px !important; height:auto !important; margin:0 auto !important;
            @include mixins.mq(520) { width:100%; }
            span { @include mixins.font(1.6, 1.6, 22px, 700, 0,  #1A1A1A, "Magazine Grotesque"); font-size:16px !important; }
        }
    }
}

