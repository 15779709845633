@use "_mixins";
@use "_extends";
@use "_colors";
.search-destinations-cards{position: relative;
    .swiper{
        .swiper-wrapper{
            .swiper-slide{height: 180px; width: 248px; position: relative;}
        }
    }
    .search-destinations-card{height: 180px; position: relative; display: flex; flex-direction: column; justify-content: flex-end; gap:2px; padding: 20px; border-radius: 8px; overflow: hidden;
        span{@include mixins.font(1.2, 1.4, 1.63rem, 400, 0, white); text-transform: uppercase; z-index: 2;}
        label{@include mixins.font(1.8, 2.2, 2.88rem, 700, 0, var(--color-yellow), "Magazine Grotesque"); text-transform: uppercase; z-index: 2;}
        &:after{content:""; position: absolute; z-index: 1; bottom:0; left:0; right:0; height: 50%; background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.71) 100%);}
    }
    .swiper-button-prev, .swiper-button-next{margin-top: 0; transform: translateY(-50%);}
    .swiper-button-prev{left:0;}
    .swiper-button-next{right: 0;}
    &.has-carousel{padding:0 20px;}
}

.our-picks{padding: 0 0 80px;
    @include mixins.mq(500){padding: 0 0 60px;}
}