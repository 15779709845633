@use "_mixins";
@use "_extends";
@use "_colors";
// Project specfic overrides for the froala editor
// This is designed to spot fix any layout requirements, without altering the "base" froala css

.wysiwyg{
  @include mixins.font(1.4, 1.6, 150%, 400, 0, var(--color-off-black));
  p, label, span, ul, ol, li { @include mixins.font(1.4, 1.6, 150%, 400, 0, var(--color-off-black)); }
  p { margin-bottom:18px; }
  li { margin-bottom:0; }
  
  a { color:var(--color-off-black); text-decoration:underline; position: relative;
    &.btn {font-size: clamp(1.6rem, 1.6vw, 1.6rem); text-decoration: none; background-color: var(--color-yellow); padding: 12px 18px; font-family: "Magazine Grotesque", sans-serif; font-weight: 700; border-radius: 8px; color: var(--color-off-black); display: inline-block; }
    &.arrow { text-transform:uppercase;
        &:after {content:""; background: url('/Content/images/icons/arrow-right-black.svg') no-repeat; width: 17px; display: block; position: absolute;  right: -25px; top: 0; height: 15px; background-size: 18px;}
    }
  }

  .fr-video{
    &.fr-dvi.fr-fvr{margin-left: 40px !important; border-radius: 8px;
      @include mixins.mq(1100){margin-left: 20px !important; width: 50% !important;}       
      @include mixins.mq(670){margin-left: 0 !important; width: 100% !important; margin-bottom: 20px !important;}    
    }
    &.fr-dvi.fr-fvl{margin-right: 40px !important; border-radius: 8px;
      @include mixins.mq(1100){margin-right: 20px !important; width: 50% !important;}       
      @include mixins.mq(670){margin-right: 0 !important; width: 100% !important; margin-bottom: 20px !important;}    
    }   
  }

  img{
    &.fr-dii.fr-fir{margin-left: 40px !important; border-radius: 8px;
      @include mixins.mq(1100){margin-left: 20px !important; width: 50% !important;}       
      @include mixins.mq(670){margin-left: 0 !important; width: 100% !important; margin-bottom: 20px !important;}    
    }
    &.fr-dii.fr-fil{margin-right: 40px !important; border-radius: 8px;
      @include mixins.mq(1100){margin-right: 20px !important; width: 50% !important;}       
      @include mixins.mq(670){margin-right: 0 !important; width: 100% !important; margin-bottom: 20px !important;}    
    }
  }

  h1{@include mixins.font(3.4, 6.4, 1, 400, 0, var(--color-off-black), "Magazine Grotesque");}
  h2{@include mixins.font(3.2, 5.2, 1, 400, 0, var(--color-off-black), "Magazine Grotesque"); margin-top: 40px; margin-bottom: 40px; text-transform: uppercase; text-align: center}
  h3{@include mixins.font(2.4, 2.8, 1, 400, 0, var(--color-off-black), "Magazine Grotesque"); margin-top: 40px; margin-bottom: 40px;}
  h4{@include mixins.font(2.2, 2.4, 1, 400, 0, var(--color-off-black), "Magazine Grotesque"); margin-top: 30px; margin-bottom: 30px;}
  h5{@include mixins.font(1.6, 1.8, 1, 400, 0, var(--color-off-black), "Magazine Grotesque"); margin-bottom: 10px;}
  h6{@include mixins.font(1.4, 1.6, 1, 700, 0, var(--color-off-black)); margin-bottom: 3px; text-align: left;}

  table{margin-bottom: 35px; margin-top: 35px;
    th{@include mixins.font(1.4, 1.6, 130%, 700, 0, var(--color-off-black));}
    td{@include mixins.font(1.4, 1.6, 130%, 400, 0, var(--color-off-black));}
    td, th{padding: 20px;}
  }

  /* Custom paragraph styles */
  .fr-text-gray { color:#6C7175 !important; }
  .fr-text-intro { font-size:1.6rem; font-size:clamp(1.6rem, 2.4vw, 2.4rem); line-height:1.3; font-weight:400; color:#1e1e1e; letter-spacing:0rem; font-family:"Magazine Grotesque", sans-serif;  }
  //.fr-text-narrow { margin-left:120px; margin-right:120px; }
  .fr-text-burgundy { color:var(--color-off-black) !important; }

  /* Custom table styles */
  table {
    &.fr-narrow { margin-left:120px; margin-right:120px; width:auto !important;
      @include mixins.mq(1000){ margin-left:0px; margin-right:0px; margin-bottom: 0;
        tbody{
          &>tr{
            &>td{padding-left: 0;}
          }
        }
      }   
      @include mixins.mq(620){ 
        tbody{
          &>tr{
            &>td{padding-bottom: 0;
              p{
                &:last-of-type{margin-bottom: 0;}
              }
            }
          }
        }
      }       
    }
  }

  ul{margin: 0; padding: 0;
    li {list-style: none; 
      &:before{background-color: var(--color-off-black) !important;}
    }
  }
}

.article-intro{
  &>.row{   
    &>.rich_text_part{
      &:first-child{
        &>.part{
          &>h2{margin-bottom: 30px;}
        }
      }
    }
  }
}

.body-text{
  &:last-child{margin-bottom: 80px;}
}