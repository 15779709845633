@use "_mixins";
@use "_extends";
@use "_colors";
@use "./get-in-touch";

.modal{position:fixed; top:0; right:0; bottom:0; left:0; background: rgba(black, 0.2); z-index: 10100000001; display:flex; justify-content:center; align-items:center; pointer-events:none; opacity:0; transition: opacity 0.3s ease-out; transition-delay:0.14s; cursor:pointer;
    &.link-popup .modal__content{ justify-content: normal; height:auto;}
    &#agent-sign-up-modal{     
        .modal__content{max-width: 920px; max-height: 90vh; max-height: 90dvh;
            p{max-width: 550px;}
            form{gap: 45px; display: grid; grid-auto-rows: auto;}
        }       
        h3{margin-bottom:20px; @include mixins.font(2.4, 2.8, 3.36rem, 400, -0.07rem, black); font-family: "Kuoni Feijoa", sans-serif}
        h4, .h4{grid-column-start: span 3; margin-bottom: 10px; text-align:center; @include mixins.font(1.8, 2.0, 2.4rem, 400, -0.07rem, black); font-family: "Kuoni Feijoa", sans-serif;
            @media(max-width: 900px){grid-column-start:span 2;}
            @media(max-width: 700px){grid-column-start:initial;}
        }
        p.error { color:red; }
        .form-section { margin: 10px 0 20px 0; display: grid; gap:10px 20px;}
        .agent-checkbox, .agent-radios{font-size: 16px;
            label{font-size: 16px;}
        }
        .agent-input{ border: none; border-bottom: 1px solid #ddd; margin: 10px 5px; font-size: 16px; height: 50px; padding: 14px;
            &.solo { margin: 20px auto; display: block; width: 70% }
            &::placeholder {text-align: center; font-size: 16px; }
            &::-webkit-input-placeholder { text-align: center; font-size: 16px;}
            &:-moz-placeholder { /* Firefox 18- */ text-align: center; font-size: 16px;}
            &::-moz-placeholder {  /* Firefox 19+ */ text-align: center; font-size: 16px;}
            &:-ms-input-placeholder { text-align: center; font-size: 16px;}
        }
    }
    &__content{max-width:623px; max-height:498px; height: 100%; width:100%; background:white; box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.2); padding:80px 30px 30px; border-radius : 6px; cursor:default; position:relative; text-align:center; display:flex; justify-content:center; flex-direction: column; opacity:0; transition: opacity 0.3s, transform 0.3s ease-out; transform: translateY(8px); transition-delay:0s; margin: 30px;
        &.onrequest-modal-content{padding: 65px 42px 40px; max-width:1200px; max-height:90vh; height:auto; justify-content: flex-start; overflow-y: auto;
            .heading-1{ margin:0px 0 4px; font-family: "Magazine Grotesque", serif; font-size: clamp(2.4rem, 3.2vw, 3.2rem);}
            .heading-2{ margin:0 0 55px; font-family: "Archivo", sans-serif; @include mixins.font(1.6, 2.0, 2.4rem, 400, -0.07rem, black);
                @include mixins.mq(500) { margin-bottom: 35px; }
            }
            .boldbit{ font-size: 1.4rem ;}
            .get-in-touch{padding-bottom: 0;
                &>.container{
                    &:nth-of-type(1){padding: 0 0px !important;}
                }
                .content-column{
                    h2{
                        @media(max-width:500px){gap:0;}
                    }
                    .content-footer{
                        .opening-hours{
                            .toggleOpeningHours{color:var(--color-off-black);}
                        }
                        p.bold-bit{ margin-top: 0px; color: #666;}
                    }
                }
                .content-enquiries-tab{
                    &>div{                       
                        @media(max-width:500px){display: grid; gap:30px;}
                    }
                }
                .btns{
                    .btn{
                        a{
                            &.cta-component__cta-button{color:var(--color-off-black); padding: 0; margin-bottom: 0;}
                        }
                    }
                }
            }
        }
        &.nabyk-popup { background-color: #27a1c6; color: #fff; height: auto; padding: 9px 0; max-width: 450px; 
            p { text-align: center; margin:14px auto; }
            @include mixins.mq(1680) { max-width: 350px; padding: 0;
                p { font-size: 14px; margin:12px auto; }
                .modal-close { top:16px; right:16px; }
            }
            @include mixins.mq(1440) { padding: 0;
                p { font-size: 14px; margin:10px auto; }
            }
            @include mixins.mq(760) { margin-top: 0; padding: 0; max-width: 320px; 
                p { font-size: 12px; margin-bottom: 8px; }
            }
            @include mixins.mq(450) { margin-top: 0; padding: 0 4px; max-width: 175px; 
                p { font-size: 12px; margin-bottom: 8px; }
                .modal-close { top:50px; right:10px; }
            }
        }
        .modal-close{position:absolute; top:26px; right:26px; cursor:pointer;}
        h4, .h4{@include mixins.font(2.4, 2.8, 3.36rem, 400, -0.07rem, black); font-family: "Kuoni Feijoa", sans-serif}
        p{
            &.modal__phone-number{@include mixins.font(2.0, 2.4, 3.36rem, 400, 0, black); margin-top:21px; margin-bottom:43px;
                span{@include mixins.font(2.4, 2.8, 3.36rem, 700, 0, black); margin-left: 6px;}
            }
            &.modal__paragraph{@include mixins.font(1.6, 1.6, 2.56rem, 400, 0, black);}            
        }
        .modal__footer{@include mixins.font(1.4, 1.4, 3.36rem, 400, 0, colors.$darkGray); display:flex; align-items:center; justify-content:center;
            img{width:15px; margin-right:4px;}
        }
        p, h4{max-width:415px; margin: 0 auto;}
        button{font-size:1.4rem; font-weight:600; padding: 0 20px; height:51px; margin: 19px auto 14px; min-height:51px;}
    }
    &.active{pointer-events:all; opacity:1; transition-delay:0s;
        .modal__content{opacity:1; transform: translateY(0); pointer-events:all; transition-delay:0.22s;}
    }
    @include mixins.mq(520){
        &__content{padding: 62px 30px 30px;
            h4{line-height: 125%;}
            p{
                &.modal__phone-number{margin-bottom: 19px;
                    span{display:block;}
                }
                &.modal__paragraph{line-height: 140%;}            
            }          
        }       
    }
    &.offer-modal, &.retail-modal, &.tailormade-modal{
        .modal__content{justify-content: flex-start; height: auto; gap:35px; padding-top: 50px; padding-bottom: 40px;
            .h2{line-height: 1; letter-spacing: 0px; font-size: clamp(2.2rem, 3.2vw, 3.2rem);}
            .modal__body{display: flex; justify-content: center; gap:60px; 
                .modal__left{display: grid; align-items: center; justify-content: center;
                    .modal__phone-number{
                        p{font-size: clamp(1.6rem, 1.8vw, 1.8rem);}
                        span{display: block; margin: 0;}
                        a{text-decoration: underline; font-size: clamp(2rem, 2.4vw, 2.4rem); font-weight: 700; font-family: "Magazine Grotesque";}
                    }
                }
                .modal__right{display:grid; justify-items:center; gap: 12px; 
                    button{margin: 0;}
                    label{font-size: 1.3rem; display: block; margin-bottom: 20px;
                        &:last-of-type{margin-bottom: 0;}
                    }
                }
               
            }
           
            @media(max-width:480px){gap:0px; padding-top: 70px; min-height: fit-content;
                .modal__body{flex-direction: column; gap:0; margin-top: 30px; gap:30px;}
            }

            @media(max-width:374px){padding-top: 60px; padding-bottom: 30px;}
        }
       
    }
}
div#nabyk-popup { padding-top: 44px; align-items: start;
    @include mixins.mq(760) { padding-top: 6px; }
}