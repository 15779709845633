@use "_mixins";
@use "_extends";
@use "_colors";
.locations-map{height:64vh; max-height:600px;
    #locations-map{height:100%; width:100%;
        .gm-style{
            .gm-style-mtc button { border-radius: 2px; }
            .gm-style-iw-c{padding:0;
                .gm-style-iw-d{overflow:visible !important;
                    .store-popup{display:flex; box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3); border-radius: 8px; width:315px; background-color:white;width: 310px;
                       
                    }
                }           
                &>button{top: -31px !important; opacity:1 !important; display:none !important;
                    span{width:20px !important; height:20px !important;}
                }           
            } 
            .gm-style-iw-tc{display:none;}
        }   


        @include mixins.mq(850){margin-left: -44px; width: calc(100% + 86px);}
        @include mixins.mq(620){margin-left: -30px; width: calc(100% + 60px);}
        @include mixins.mq(374){margin-left: -20px; width: calc(100% + 40px);}
    }
}