@use "_mixins";
@use "_extends";
@use "_colors";
#menu{overflow: hidden; position: fixed; z-index: 999; top:0; right:0; bottom: 0; left:0; max-height: 100vh; max-height: 100dvh; padding:40px 0; background-color: var(--color-yellow-light); pointer-events: none; opacity: 0; transform: translateX(100%); transition: opacity .3s, transform .5s ease-out; //bottom:137px;
   .mobile-controls{position: fixed; top: 0; left:0; right:0; bottom:auto; z-index: 2; transform: translateY(0px); opacity: 1; pointer-events: all; height: 80px; padding: 0 31px 0 30px; display: flex; align-items: center; justify-content: space-between; background-color: var(--color-yellow-light);
        .close, #back{cursor: pointer;}
        .close{}
        #back{transform: rotate(-180deg); width:14px;        
            @media(min-width: 761px){display: none;}   
        }        
        @media(min-width: 761px){justify-content: flex-end;}
   }
    
    &>div{display: flex; opacity: 0; pointer-events: none; transform: translateY(4px); transition: opacity 0.4s ease-in; padding-inline: 40px; padding-bottom: 40px; z-index: -999; position: absolute; top:80px; left:0; right:0; bottom:0; align-items: center; justify-content: center;
        @include mixins.mq(860){padding-inline: 30px;}
        
        @include mixins.mq(1250){
            &.menu__destinations { padding-bottom: 60px; display: grid; gap:0; }
        }   
        &.menu__destinations,&.menu__holidaytype{flex-direction: column; gap:59px; overflow-y: auto;padding-top: 60px;
            &::-webkit-scrollbar{width: 6px;} 
            &::-webkit-scrollbar-track {background-color: colors.$darkGray; @extend %br-10; margin: 0 0;} 
            &::-webkit-scrollbar-thumb {background-color: rgba(255, 255, 255, 0.531); @extend %br-10;}
            .h3{@include mixins.font(2.4, 2.8, 1.5, 700, 0rem, var(--color-off-black), 'Magazine Grotesque'); text-align: center;}
            .intro { height:auto;       
                p { color: var(--color-off-black); font-weight:400; text-align:center; line-height: 160%;  font-family: "Archivo", sans-serif;}
            }
            &>div{display: flex; justify-content: space-between; width: 100%; height: calc(100% - 100px); max-width: 1340px; 
                .menu__columns{display: flex; gap:28px; justify-content: space-between; align-items: flex-start; flex: 1;
                    .menu__column{display: grid; gap: 45px; flex:1; align-items: flex-start; justify-content: space-between;
                        ul{display: grid; gap:8px; align-items: flex-start; padding-right: 10px;
                            li{
                                a{@include mixins.font(1.4, 1.6, 145%, 400, 0, var(--color-off-black)); display: flex; align-items: center; justify-content: flex-start; flex-wrap: wrap;
                                    @include mixins.mq(500){font-size: 1.8rem;}
                                    img{opacity: 0.7; padding-left: 20px; display: none;}
                                }
                                &:first-child{
                                    a{@include mixins.font(1.6, 1.6, 1.9rem, 700, 0.03rem, var(--color-off-black), "Magazine Grotesque"); text-transform: uppercase; padding-right: 50px; 
                                        @include mixins.mq(1250){padding-right: 0;}
                                    }
                                }
                            }
                            &:last-child{padding-bottom: 40px;}
                            @include mixins.mq(500){gap:20px;}
                        }
                    }
                    @include mixins.mq(800){display: grid; grid-template-columns: repeat(3, 1fr);}
                    @include mixins.mq(620){grid-template-columns: repeat(2, 1fr);}
                    @include mixins.mq(420){grid-template-columns: 1fr;}
                }
                

                .menu__rows{
                    .menu__row{
                        ul{display: flex; gap:28px; justify-content: center; flex-wrap: wrap; align-items: flex-start; flex: 1; flex-direction: row;
                            li{
                                a{@include mixins.font(1.6, 1.6, 145%, 400, 0, var(--color-off-black)); display: flex; align-items: center; justify-content: space-between;
                                    @include mixins.mq(500){font-size: 1.8rem;}
                                    img{opacity: 0.7; padding-left: 20px;
                                        @media(min-width: 421px){display: none;}
                                    }
                                }
                            }
                            @include mixins.mq(500){gap:20px;}
                        }
                    }
                }

                .menu__featured{border-left: 1px solid rgba(var(--color-off-black), 0.2); width: 304px; padding-left: 69px; padding-bottom: 14px; flex: 0 0 304px; padding-top: 6px; display: flex; gap:40px; flex-direction: column; justify-content: flex-start;
                    .menu__feature{
                        p{@include mixins.font(1.2, 1.2, 2rem, 400, 0, var(--color-off-black)); margin: 8px 0 12px;}
                        a.main-link{@include mixins.font(1.6, 1.6, 2.17rem, 400, 0, var(--color-off-black)); display: flex; justify-content: space-between; align-items: center; margin: 5px 0 20px 0;}
                        ul{display: grid; gap: 0;
                            li{
                                a{@include mixins.font(1.4, 1.4, 2.6rem, 400, 0, var(--color-off-black)); display: flex; justify-content: space-between; align-items: center;}
                            }
                        }
                    }                  
                }
                .menu-features{
                    @include mixins.mq(1250){display: flex;}
                    @include mixins.mq(760){flex-direction: column;}
                }
                @include mixins.mq(1250){flex-direction: column; gap:60px; display: grid;
                    .menu__featured{padding-left: 0; border-left: none; border-top:1px solid rgba(var(--color-off-black), 0.2); padding-top: 40px; flex-direction: row; flex:1;
                        .menu__feature{display: flex; gap:12px; align-items: flex-start;
                            p{margin-top: 0;}
                        }
                        &:first-child{padding-right: 40px;}
                    }
                }
                @include mixins.mq(760){
                    .menu__featured{flex-direction: column; width: 100%;
                        &:first-child{padding-right: 0;}
                    }
                }
                @include mixins.mq(350){
                    .menu__featured{
                        .menu__feature{flex-direction: column; gap:10px;}
                    }
                }
            }  
            
            @media(min-width: 580px) and (max-height:710px){gap:24px; padding: 80px 40px 40px; display: grid;
                &>div{
                    .menu__columns{
                        .menu__column{gap:20px;
                            ul{gap:14px;
                                li{
                                    a{font-size: 1.4rem; line-height: 100%;}
                                }
                            }
                        }
                    }
                    .menu__featured{gap:20px;
                        .menu__feature{
                            &>div{
                                p{line-height: 130%; margin: 14px 0 14px}
                                ul{gap:10px;
                                    li{
                                        a{font-size: 1.4rem; line-height: 100%;}
                                    }
                                }
                            }
                        }
                    }
                }
            }
            @include mixins.mq(760){gap:20px;}
        } 
        &.menu__inspiration{padding-left: clamp(3rem, 9vw, 22rem);
            @include mixins.mq(860){padding-left: 30px;}
            &::-webkit-scrollbar{width: 6px;} 
            &::-webkit-scrollbar-track {background-color: colors.$darkGray; @extend %br-10; margin: 0 0;} 
            &::-webkit-scrollbar-thumb {background-color: rgba(255, 255, 255, 0.531); @extend %br-10;}
            &>div{width: 100%; display: flex; gap:80px; //max-width: 1340px; 
                @include mixins.mq(1200){gap: 50px;}
                @include mixins.mq(840){gap: 0;}
               .text-card{position: relative; flex: 0 0 250px; z-index: 2; background-color: var(--color-yellow-light); height: 383px;
                    ul{display: flex; flex-direction: column; gap:32px; border-right: 1px solid var(--color-off-black); padding: 8px 0 58px; background-color: var(--color-yellow-light);
                        li{@include mixins.font(2.2, 2.2, 3.84rem, 700, 0.03rem, var(--color-off-black), "Magazine Grotesque"); opacity: 0.5; transition: opacity 0.2s; cursor: pointer;
                            &.active{opacity: 1;}
                            @include mixins.mq(580){opacity: 1;}
                            img{opacity: 0.7;
                                @media(min-width: 580px){display: none;}
                            }
                        }
                        &:before{content:""; position: absolute; top:0; bottom:-60px; left:-10000px; z-index: 1; background-color: var(--color-yellow-light); width:10000px;
                            @include mixins.mq(840){bottom:0;}
                        }
                      
                    }
                    .selector{position: absolute; right: 0; top:0; width: 4px; background-color: #E66E61; height: 54px; transition: transform 0.2s, width 0.2s ease-out;}  

                    @include mixins.mq(580){position: fixed; top:0; right:0; bottom:0; left:0; z-index: 1000; padding: 30px; display: flex; align-items: center;
                        ul{width: 100%; padding: 0 !important;
                            @media(max-width:580px) and (max-height:500px){padding-top:40px !important; gap:17px !important;}
                        }                        
                    }
                    //@media(min-width:580px) and (max-height:540px){height: auto;}
                }

               .menu__swipers{position: relative; width: calc(100% - 250px);
                    [class*="swiper__"]{position: absolute; top:0; left:0; width: 100%; display: flex; opacity: 0; transform: translateY(4px); pointer-events: none; z-index: -998; transition: opacity 0.2s 0s, transform 0.2s 0s ease-out; flex-direction: column;
                        //padding-left: 80px;
                        &.active{opacity: 1; transform: translateY(0); pointer-events: all; z-index: 1; transition: opacity 0.2s 0.21s, transform 0.2s 0.21s ease-out;
                            @include mixins.mq(580){transition: opacity 0s 0s, transform 0s 0s ease-out;} 
                        }
                        .swiper-cta{@include mixins.font(1.6, 1.6, 1.4rem, 700, 0, var(--color-off-black), "Magazine Grotesque"); display: inline-flex; gap:8px; align-items: center; border: 2px solid var(--color-off-black); border-radius: 8px; padding: 6px 13px; margin-top: 60px; align-self: center; cursor: pointer;
                            &.grid-cta { grid-column: 2 / span 2; justify-self: center; }
                            @media(min-width:580px) and (max-height:640px){margin-top: 40px;}
                        }
                        .swiper{padding-right: 40px; 
                            .swiper-wrapper{
                                @media(max-width:580px){transform: none !important;}
                            }
                            .swiper-button-prev, .swiper-button-next{
                                &.swiper-button-disabled{display: flex; pointer-events: none;
                                    &.swiper-button-lock{display: none !important;}
                                }
                                @include mixins.mq(840){display: none !important;}
                            }
                            @include mixins.mq(1200){padding-right: 25px;}
                            @include mixins.mq(840){padding-right: 0;}
                        }                     
                    }

                    .swiper__dates{display: grid; grid-template: repeat(3, 120px) / repeat(4, 1fr); gap:20px;
                        &>a{display: flex; align-items: center; justify-content: center; position: relative; filter: saturate(75%); border-radius: 8px; overflow: hidden;
                            label{@include mixins.font(2, 2.2, 1.2, 700, 0, white, "Magazine Grotesque"); text-transform: uppercase; z-index: 2; cursor: pointer;}
                            //&:after{content: ''; @extend %pin; z-index: 1; background-color: rgba(black, 0.2);}
                        }   
                        @include mixins.mq(964){grid-template: repeat(4, 100px) / repeat(3, 1fr);
                            .swiper-cta.grid-cta { grid-column: 2; margin-top: 0; }
                        }                     
                        @include mixins.mq(700){grid-template: repeat(4, 120px) / repeat(2, 1fr); gap:12px; grid-auto-rows: 120px;
                            .swiper-cta.grid-cta { grid-column: 1 / span 2; }  
                        }                     
                        @include mixins.mq(420){grid-template: repeat(4, 120px) / repeat(1, 1fr);
                            .swiper-cta.grid-cta { grid-column: 1; }  
                        }   
                        @media(min-width:580px) and (max-height:540px){gap:10px; grid-template: repeat(3, 118px)/repeat(5, 1fr)}                  
                    }
             
                    .swiper{overflow: visible; z-index: 1; 
                        &:after{content:""; position: absolute; top:0; bottom:-60px; background-color: var(--color-yellow-light); width:80px; left: -80px; z-index: 2;
                            @include mixins.mq(1200){width:50px; left: -50px;}
                            @include mixins.mq(840){content: none;}
                        }
                        .swiper-wrapper{
                            .swiper-slide{position: relative;
                                &.simple{width: 255px; filter: saturate(75%); height: 360px; border-radius: 8px; overflow: hidden; display: flex; flex-direction: column; justify-content: flex-end; padding: 0 18px 22px;
                                    h3, p{z-index: 2;}
                                    h3{@include mixins.font(2, 2.2, 2.88rem, 700, 0, white, "Magazine Grotesque"); margin-bottom: 0px; text-transform: uppercase;}
                                    p{@include mixins.font(1.4, 1.6, 1.4, 400, 0, white);}
                                    &:after{content: ''; @extend %pin; top:50%; z-index: 1; background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);}
                                    @include mixins.mq(580){border-radius: 8px; height: 200px;}
                                    @media(min-width:580px) and (max-height:640px){height: 246px;}
                                }
                                &.brochure{width: 255px;
                                    .brochure-image{height: 360px; width: 100%; margin-bottom: 10px; border-radius: 8px}
                                    label{@include mixins.font(1.6, 1.8, 1.9rem, 700, 0, var(--color-off-black)); cursor: pointer;}
                                    @media(min-width:580px) and (max-height:710px){
                                        .brochure-image{height: 221px;}
                                    }
                                }
                                &.collection{width:255px; height: 450px; border-radius: 8px; overflow:hidden; display:flex; flex-direction:column; border-radius: 8px; overflow: hidden;
                                    .four-cards-carousel__image{height:220px;}
                                    &>div{padding: 18px 15px; min-height:220px; position:relative; flex:1; background-color: var(--color-yellow-mid);
                                        label{@include mixins.font(1.4, 1.4, 1, 400, 0.03rem, var(--color-off-black)); text-transform: uppercase; display:flex; align-items:center; gap:7px; cursor: pointer;} 
                                        h3{@include mixins.font(1.8, 2.2, 1, 700, 0, var(--color-off-black), "Magazine Grotesque"); margin: 10px 0 10px; text-transform: uppercase;}
                                        p{@include mixins.font(1.6, 1.6, 1.5, 400, 0, var(--color-off-black)); overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3;}       
                                        small{@include mixins.font(1.2, 1.4, 1.2rem, 400, 0, var(--color-off-black)); text-transform:uppercase; position:absolute; bottom: 19px; left: 15px;}
                                        .curated{margin-top: 2px;
                                            label{display:block; @include mixins.font(0.75, 0.75, 1rem, 600, 0.07rem, var(--color-off-black)); text-transform:uppercase; margin-bottom:4px; margin-top: 5px;}
                                            img{max-width: 65px;}
                                        }
                                    }
                                    @media(min-width:580px) and (max-height:900px){height: auto; min-height: 382px;
                                        .four-cards-carousel__image{height: auto; min-height: 140px;}
                                        &>div{                                           
                                            .curated{margin-top: 15px;}
                                        }
                                    }
                                    @media(min-width:580px) and (max-height:720px){   
                                        .four-cards-carousel__image{min-height: 90px;}                                  
                                        &>div{min-height:200px;                                         
                                            .curated{display: none;}
                                        }
                                    }
                                }
                                &.article{width: 220px; //height: 360px;
                                    .article-image{height: 220px; width: 100%;  border-radius: 8px;}
                                    ul{display: flex; gap:13px; margin: 2px 0 8px;
                                        li{@include mixins.font(1.2, 1.2, 1.2rem, 400, 0.08rem, var(--color-off-black));
                                            &:first-child{text-transform: uppercase;}
                                        }
                                    }
                                    p{@include mixins.font(2, 2, 1.2, 700, 0.05rem, var(--color-off-black), "Magazine Grotesque");}    
                                    @media(min-width:580px) and (max-height:640px){//height: auto;
                                        .article-image{height: 162px;}
                                    }                           
                                }                              
                            }
                            @include mixins.mq(580){flex-direction: column; gap:20px;}
                        }                               
                        .swiper-button-prev{left: -59px;
                            @include mixins.mq(1200){left: -45px;}
                            @include mixins.mq(840){left: -20px;}
                        }          
                        .swiper-button-next{right: -20px;
                            //@include mq(1660){right: -20px;}
                        }       
                        .swiper-button-next, .swiper-button-prev{margin-top: 0; transform: translateY(-82%);
                            //@media(min-width:841px){display: none;}
                            @include mixins.mq(580){display: none;}
                        }    
                    }
                
                    @include mixins.mq(580){position: fixed; top:0; right:0; bottom:0; left:0; z-index: 1001; background-color: var(--color-yellow-light); transform: translateX(100%); transition: transform 0.2s;
                        &>div{padding: 100px 30px 30px; overflow-y: auto; height: 100vh; height: 100dvh; padding-bottom: 70px;
                            &::-webkit-scrollbar {width: 5px; height: 0px;} &::-webkit-scrollbar-track {background-color: #37424a;} &::-webkit-scrollbar-thumb {background-color: #f7f7f7; border-radius: 10px;} scrollbar-width: thin; scrollbar-color: transparent #f7f7f7;
                        }
                        &.active{transform: translateX(0);}
                        .swiper{
                            &.menu-swiper-brochures{
                                .swiper-wrapper{display: grid; grid-template-columns: repeat(2, 1fr); gap: 37px 16px; //grid-auto-rows: 235px;
                                    .swiper-slide{width: 100% !important;
                                        .brochure-image{height: 208px;}
                                    }
                                    @include mixins.mq(360){grid-template-columns: 1fr;}
                                }
                            }
                            &.menu-swiper-collections{
                                .swiper-wrapper{gap:40px;
                                    .swiper-slide{height: 275px; border-radius: 0;
                                      &>div{
                                        &:first-child{margin-right: 64px; border-radius: 8px;}
                                        &:nth-child(2){position: absolute; top: 0; right: 0; left:64px; min-height: 251px; border-radius: 8px;
                                            p{-webkit-line-clamp: 4;}
                                        }
                                      }
                                    }                                   
                                }
                            }                            
                        }

                        .swiper__articles{
                            .swiper{
                                .swiper-wrapper{gap:40px;
                                    .swiper-slide{display: flex; gap:19px; height: auto;
                                      .article-image{height: 148px; flex: 0 0 148px;}
                                      &>div{
                                        ul{margin-top: 0;}
                                      }
                                    }                                  
                                }
                            }
                        }
                    }                  
                }

                @include mixins.mq(840){flex-direction: column; gap:55px;
                    .text-card{height: auto; flex: none;
                        ul{flex-direction: row; border-right: none; gap:0; border-bottom: 1px solid rgba(var(--color-off-black), 0.2); justify-content: space-between; padding-bottom: 24px;
                            li{@include mixins.font(1.8, 2.4, 2.88rem, 400, 0.03rem, var(--color-off-black), "Magazine Grotesque");}
                            &::before{content: none;}
                        }
                        .selector{right:auto; top:auto; bottom:0; width: 126px; height: 4px;}
                    }
                    .menu__swipers{width: 100%; height: 360px;
                        @include mixins.mq(580){height: auto;
                            &>div{height: 100%;}
                        }
                    }
                }               

                @include mixins.mq(580){
                    .text-card{
                        ul{flex-direction: column; border-bottom: none; gap:30px;
                            li{font-size: 1.8rem; line-height: 2.72rem; font-weight: 700; letter-spacing: 0; display: flex; justify-content: space-between; align-items: center; font-family: "Magazine Grotesque", sans-serif;}
                        }
                        .selector{display: none;}
                    }
                }
                //@media(max-height:640px){gap:35px}
            } 
            @media(min-width:580px) and (max-height:710px){align-items: flex-start; margin-top: 80px;
                &>div{
                    .text-card{
                        ul{padding-bottom: 20px; gap:22px;
                            //li{font-size: 2.6rem; line-height: 2.6rem;}
                        }
                    }
                }
            } 
            
            @media(min-width:580px) and (max-width:840px) and (max-height:760px){
                &.active{top:0;
                    .menu__swipers{
                        .swiper__dates{gap:6px; grid-template: repeat(4, 90px)/repeat(3, 1fr);}
                    }
                }
            }

            @media(min-width:580px) and (max-width:840px) and (max-height:580px){
                &.active{
                    &>div{gap:15px;}
                    .menu__swipers{
                        .swiper__dates{gap:6px; grid-template: repeat(4, 70px)/repeat(3, 1fr);}
                        .swiper__collections{
                            .swiper{
                                .swiper-wrapper{
                                    a{
                                        .four-cards-carousel__image{min-height: 0; padding: 0;}
                                    }
                                }
                            }
                        }
                        .swiper__articles{
                            .swiper{
                                .swiper-wrapper{
                                    a{
                                        .article-image{height: 120px;}
                                    }
                                }
                            }
                        }
                    }
                }
            }
            @media(min-width:580px) and (max-width:840px) and (max-height:500px){
                &.active{                   
                    .menu__swipers{
                        &>div{height: calc(100vh - 200px); height: calc(100dvh - 200px); overflow-y: auto;
                            &::-webkit-scrollbar {width: 5px; height: 0px;} &::-webkit-scrollbar-track {background-color: #37424a;} &::-webkit-scrollbar-thumb {background-color: #f7f7f7; border-radius: 10px;} scrollbar-width: thin; scrollbar-color: transparent #f7f7f7;
                        }
                        .swiper-button-next{right: 10px !important;}
                        .swiper-button-prev{left: 10px !important;}
                    }
                }
            }
        } 
        &.active{opacity: 1; pointer-events: all; transform: translateY(0); z-index: 1;}    
        @include mixins.mq(500){padding-inline: 30px;
            &>h3{display: none;}
        } 
    }
    &.active{opacity: 1; pointer-events: all; transform: translateY(0);}
    @include mixins.mq(1250){bottom:0;}   
    @include mixins.mq(760){transform: translateY(0) translateX(100%); opacity: 1; z-index: 1011;
        &.active{transform: translateY(0) translateX(0);}
    }   
    @include mixins.mq(580){
        &>div{padding-inline: 30px;}
    }   
}

nav{
    &#site-nav{align-items: flex-start; padding-top: 0; flex-direction: column; padding-bottom: 25px; overflow-y: auto; 
        #close-header-menu{cursor: pointer;}
        ul{margin-top: 110px;}
        @media (min-width: 761px) {position: fixed; top: 0; right: 0; bottom: 0; width: 1px; background-color: var(--color-yellow-light); transition: transform .2s ease-out, width 3s ease-in; transform: translateX(100%); pointer-events: none; z-index: 997;
            ul{margin-top: 160px; padding: 0 30px; flex-direction: column; gap: 20px; align-items: flex-start; justify-content: center; width: 100%;
                li{color:var(--color-off-black);}
            }
            .menu-bottom{padding: 28px 30px 0 30px; margin-top: 29px;
                .menu-appointment{display: none;}
                .menu-my-kuoni{position: relative; font-family: "Magazine Grotesque", sans-serif; font-size: 23px; font-weight: 400;
                    &::after{content: ""; border-top:1px solid var(--color-off-black); position: absolute; left:0; right:0; top: -30px;}
                }
                
            }
            &.active{transform: translateX(0); pointer-events: all; width: 500px; transition: transform .2s ease-out;}
        }
        @media (max-width: 760px){justify-content: space-between;}

        .menu-bottom{width: 100%;
            .menu-my-kuoni{color:var(--color-off-black); display: flex; align-items: center; gap:7px;
                svg{transform: translateY(-4px);
                    path{fill:var(--color-off-black);}
                }
            }
            @media(max-width:760px){display: grid; grid-template-rows: 54px 54px; gap:10px; padding: 0 30px 10px; align-self: end;
                a{border: 2px solid var(--color-off-black); font-family: "Magazine Grotesque", sans-serif; font-weight: 700; font-size: 16px; border-radius: 8px; justify-content: center; display: flex; align-items: center;
                    &.menu-appointment{color: var(--color-off-black); background-color: var(--color-yellow); border-color: var(--color-yellow);}
                    &.menu-my-kuoni{
                        svg{transform: none;}
                    }
                }                
            }
        }

        @media(min-width:980px){grid-template-rows: 1fr 1fr;}  
        @media(max-width:760px){gap:25px;}
    
        &>ul{
            li{font-size: 24px; line-height: 33px; font-family: "Magazine Grotesque", sans-serif; width: 100%; font-weight: 400; text-transform: none; color:var(--color-off-black);
                a{color:var(--color-off-black);}
                @media (min-width:980px){
                    &.mobile-item{display: none;}
                }
               
            }
        }
       
    }

    &#desktop-nav-left{
        ul{
            li{letter-spacing: 0.004rem; font-weight: 700; white-space: nowrap;}
        }
        @media (max-width:1200px){
            ul{gap:16px; margin-left: 16px;
                li{font-size: 13px;}
            }
        }
        @media (max-width:980px){display: none;}
    }
}

.container{
    ul{
        &.header__cta{
            #nav-tel{display: grid; align-items: start; gap: 1px; padding-top: 5px;            
                .tel-close-info{font-family: "Archivo", sans-serif; font-size: 14px; font-weight: 400; line-height: 1; text-align: right; white-space: nowrap; cursor: default; text-transform: uppercase;}
                .tel-num{font-family: "Magazine Grotesque"; font-size: 22px; font-weight: 700; line-height: 24px; text-align: left; white-space: nowrap; color:black;}
                @media(max-width: 760px){display: none;}
            }
            #nav-appointment{
                a{display: flex; justify-content: center; align-items: center; color: var(--color-off-black); background-color: var(--color-yellow); border-radius: 8px; padding: 12px 16px; font-family: "Magazine Grotesque"; font-size: 16px; font-weight: 700; line-height: 1; text-align: center;
                    @media(max-width: 1200px){font-size: 13px;}
                    @media(max-width: 980px){font-size: 14px;}
                }
                @media(max-width: 760px){display: none;}
            }
            #search-trigger{                         
                @media(max-width: 980px){padding: 0 0 0;}
            }
            #mobile-menu-trigger{
                &>span{
                    @media(max-width: 1200px){display: none;}
                }

                @media(max-width: 760px){padding-left: 0;
                    &:before{content: none;}
                }
            }

            #mobile-services{
                svg{width: 22px;
                    path{fill:none; stroke:#282828;}
                }
                @media(min-width: 761px){display: none;}
            }
            @media(max-width: 980px){gap:20px !important;}
            @media(max-width: 500px){gap:10px !important;}
        }
    }    
}

.lock{
    .sticky-footer-wrapper {display: none;}
}