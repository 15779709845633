@use "_mixins";
@use "_extends";
@use "_colors";
  @font-face {
    font-family: "Magazine Grotesque", sans-serif;
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("../../fonts/Feijoa_Medium.otf") format("opentype");
  }

  @font-face {
    font-family: "Magazine Grotesque", sans-serif;
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url("../../fonts/Feijoa_MediumItalic.otf") format("opentype");
  }

  @font-face {
    font-family: "feijoaDisplay";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../../fonts/Feijoa_Display.otf") format("opentype");
  }
  
  /* work-sans-500 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''), url('../../fonts/work-sans-v17-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/work-sans-v17-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* work-sans-regular - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''), url('../../fonts/work-sans-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/work-sans-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* work-sans-600 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-display:swap;
    src: local(''), url('../../fonts/work-sans-v17-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/work-sans-v17-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* work-sans-800 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local(''), url('../../fonts/work-sans-v17-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/work-sans-v17-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* work-sans-700 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''), url('../../fonts/work-sans-v17-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/work-sans-v17-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* work-sans-900 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local(''), url('../../fonts/work-sans-v17-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/work-sans-v17-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }


//Magazine Grotesque
@font-face {
  font-family: 'Magazine Grotesque';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url("../../fonts/Magazine-Grotesque-Bold.woff2") format("woff2"),
  url("../../fonts/Magazine-Grotesque-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Magazine Grotesque';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''), url("../../fonts/Magazine-Grotesque-Bold.woff2") format("woff2"),
  url("../../fonts/Magazine-Grotesque-Bold.ttf") format("truetype");
}



//Archivo
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url("../../fonts/Archivo-Regular.woff2") format("woff2"),
  url("../../fonts/Archivo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''), url("../../fonts/Archivo-Bold.woff2") format("woff2"),
  url("../../fonts/Archivo-Bold.ttf") format("truetype");
}

