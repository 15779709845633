@use "_mixins";
@use "_extends";
@use "_colors";


.usp-list{position: relative;
    .container{overflow-x: auto; padding: 0; max-width: none; -ms-overflow-style: none; scrollbar-width: none; &::-webkit-scrollbar{display: none;}
        ul{display:flex; justify-content: center; align-items: center; gap:30px; margin: 0 auto; height: 65px;
            li{font-size: clamp(1.2rem, 1.4vw, 1.4rem); font-weight: 700; display: flex; align-items: center; gap:10px; flex-shrink: 0; padding:0 15px;
                @media(max-width:860px){padding: 0 30px;
                    &:last-of-type{padding-right: 42px;}
                }
                @media(max-width:620px){padding: 0 20px;
                    &:last-of-type{padding-right: 30px;}
                }
                @media(max-width:374px){padding: 0 12px;
                    &:last-of-type{padding-right: 20px;}
                }
            }
            @media(max-width:860px){justify-content: flex-start; gap:0px;
                li{gap:5px;
                    img{width: 45px;}
                }
            }           
        }        
    }

    &+.container{
        .collection-page-summary{
            .collection-page-summary__right{margin-top: -20px !important;}
        }
    }

    &:after{content: ""; position: absolute; bottom:0; left:25px; right:25px; height: 1px; background-color: #ccc;
        @media(max-width:860px){left:30px; right:30px;}
        @media(max-width:620px){left:20px; right:20px;}
        @media(max-width:374px){left:12px; right:12px;}
    }

    &.border-top{
        &:before {content: ""; position: absolute; top: 0; left: 25px; right: 25px; height: 1px; background-color: #ccc;
            @media(max-width:860px){left:30px; right:30px;}
            @media(max-width:620px){left:20px; right:20px;}
            @media(max-width:374px){left:12px; right:12px;}
        }        
    }

    &.spacing-top{margin-top: 50px;
        @include mixins.mq(930){margin-top: 36px;}
    }

    &.spacing-bottom{margin-bottom: 50px;
        @include mixins.mq(930){margin-bottom: 36px;}
    }
}
