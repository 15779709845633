@use "_mixins";
@use "_extends";
@use "_colors";
.tabs{position: relative; display:inline-flex; width: auto; 
    li{@include mixins.font(1.4, 1.6); position: relative; color: var(--color-off-black); padding: 0 20px; border-radius: 8px 8px 0 0; flex-shrink: 0; text-align: center; line-height: 1.7rem; font-weight:400; cursor:pointer;
       &.active{font-weight:700; background-color: var(--color-yellow);
            svg{
                path{stroke:var(--color-off-black) !important;}
            }
            &:before, &:after{content: ""; position: absolute; width: 15px; height: 15px; bottom:0; pointer-events: none;}
            &:before{border-bottom-right-radius: 10px; box-shadow: 8px 0px 0 0px var(--color-yellow); left:-15px;}
            &:after{border-bottom-left-radius: 10px; box-shadow: -8px 0px 0 0px var(--color-yellow); right:-15px;}
       }
       a{color: var(--color-off-black);}
        @include mixins.mq(660){font-size: 1.4rem;
            a{font-size: 1.4rem;}
        }
    }
    &.lowercase{
        li{text-transform:none; display:flex; align-items:center; gap:10px; line-height:7.2rem;
           svg{
               path{stroke: #1e1e1e;}
           }
        }
    }
    //&.sliding{left: 0; transform: none; width: 100%;}
    // &::-webkit-scrollbar {height: 0px;}       
    // &::-webkit-scrollbar-track {background-color: #f7f7f7;;}       
    // &::-webkit-scrollbar-thumb {background-color: $darkGray; border-radius: 10px;}
    // scrollbar-width: thin;
    // scrollbar-color: $darkGray white;
    
    @include mixins.mq(800){padding-top: 64px !important; //gap:40px;
    }
    @include mixins.mq(500){padding-top: 10px !important; //gap:30px;
        //&::-webkit-scrollbar {height: 0;}    
    }
    @include mixins.mq(660){padding-left: 42px;}
    @include mixins.mq(620){padding-left: 30px;}
    @include mixins.mq(374){padding-left: 20px;}
}

.tab-content{display:none; gap: 69px;
    &.active{display:block;}

    &__left{display:grid; grid-template: 514px 247px / repeat(2, 1fr); gap:20px; flex: 0.8;
        .tab-content__image{position:relative; border-radius: 8px; overflow: hidden;
            .tab-content__gallery-trigger{position:absolute; bottom: 10px; left:10px; background-color:rgba(0, 0, 0, 0.75); display:flex; align-items:center; gap:8px; padding: 12px 12px 8px 12px; border-radius: 8px; cursor:pointer;
                label{@include mixins.font(1.4, 1.6, 1.96rem, 700, 0rem, white, "Magazine Grotesque"); cursor:pointer;}
                img{ cursor:pointer; transform: translateY(-2px);}
            }
            &:first-child{grid-column: 1/3;}
            @include mixins.mq(650){border-radius:0;
                &:first-child{border-radius: 8px 0 0 8px;}
                &:last-child{border-radius: 0 8px 8px 0;}
            }
        } 
    }

    &__right{flex:1;
        &>p{@include mixins.font(1.6, 2.4, 1.5, null, null, var(--color-off-black)); margin:0 0 25px 0;}
        .tab-content__grid{display:grid; grid-template-columns: 1fr 1fr; gap:0px 30px;
           &>div{
                label{@include mixins.font(1.4, 1.6, 1.5, 700, 0rem, var(--color-off-black));}
                p{@include mixins.font(1.4, 1.6, 1.5, 400, 0rem, var(--color-off-black));}
           }
        }
        &>div{
            &.tab-content__pick{display:flex; align-items:flex-start; gap:14px; border-radius: 8px; padding:24px; background-color: var(--color-yellow-light); margin-top: 38px; margin-bottom: 4px;
                label{@include mixins.font(1.4, 1.4, 1.4rem, 700, 0.1rem, black); text-transform:uppercase;}
                p{@include mixins.font(1.4, 1.4, 1.96rem, 400, 0rem, colors.$darkGray); margin-top: 5px; margin-bottom: 0;}
            }
        }
    }
    &:last-child{max-width: 1064px; margin: 0 auto;
        p{@include mixins.font(1.4, 1.6, 1.5, 400, 0rem, var(--color-off-black)); margin: 0 0 40px;}
        img{margin: 0 auto;}
        &.active{display:block;}
    }

    .difference-intro{text-align:center; max-width: 825px; margin: 0 auto;
        p{@include mixins.font(1.6, 1.8, 3.24rem, 400, 0rem, var(--color-off-black));
            &.intro{@include mixins.font(1.6, 1.6, 4.32rem, 400, 0rem, var(--color-off-black), "Magazine Grotesque"); margin-bottom:40px;}
        }
        .flex{text-align:left; gap:45px;
            p{flex:1;}
            &>div{flex: 0 0 91px; 
                img{margin:0 auto;
                    &:first-child{margin-bottom: 38px; width: 52px;}
                }
            }
        }
    }

    .search-cards-header{@include mixins.font(1.8, 2, 2.72rem, 700, 0rem, black); text-transform: uppercase; display: flex; justify-content: space-between; margin-bottom: 40px;
        a{display: flex; gap:7px; align-items: center; @include mixins.font(1.6, 1.6, 2.24rem, 400, 0rem, #006F84); text-transform: none;
            img{width: 9px;}
        }
    }

    &.overflow{overflow: hidden;}
}

.tabs-swiper, .tabs-swiper-search{text-align: center; position: relative; border-bottom: 4px solid var(--color-yellow);
    .swiper-wrapper{padding: 0 60px;
        .swiper-slide{width: auto; margin-right: 10px; height: 48px; align-items: center; display: flex;
            li{height: 100%; display: flex; align-items: center;}
            &:last-child{margin-right: 0 !important;}
        }
        @include mixins.mq(660){padding-left: 0;
            .swiper-slide{font-size: 1.4rem; height: 36px; padding-top: 4px;
                svg{width: 14px;}
            }
        }
        @include mixins.mq(660){padding-left: 42px;}
        @include mixins.mq(620){padding-left: 30px;}
        @include mixins.mq(374){padding-left: 20px;}
    }
    .swiper-button-prev, .swiper-button-next{margin-top: 0 !important; top: auto; bottom: 8px;
        &.swiper-button-disabled{display: none !important;}
        @include mixins.mq(660){display: none !important;}
        // @include mixins.mqmin(700){display: flex !important;
        //     //&.swiper-button-disabled{display: none !important;}
        // }
    }
    .swiper-button-prev{left:0;}
    .swiper-button-next{right:0;}   
    @include mixins.mq(660){margin-left: -42px !important; width: calc(100% + 84px);}
    @include mixins.mq(620){margin-left: -30px !important; width: calc(100% + 60px);}
    @include mixins.mq(374){margin-left: -20px !important; width: calc(100% + 40px);}
}
.tabs-swiper-search{border-bottom: none;}