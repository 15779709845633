$purple:#1A1A1A!default;
$gray:#fcf9f4 !default;
$grey: #C8CACC !default;
$lightGray: #e3e4e5 !default;
$midGray: #1A1A1A !default;
$darkGray: #1A1A1A !default; 
$indigo: #992525 !default;
$purple: #1A1A1A !default;
$white: #fff !default;
$lightBlue: #1A1A1A !default;
$teal: #1A1A1A !default;
$green: #418E99 !default;
$grey1: #1C2125 !default;
$grey2: #37424A !default;
$grey3: #6C7175 !default;
$grey4: #86898B !default;
$grey5: #ADB0B1 !default;
$grey6: #C8CACC !default;
$grey7: #D3D4D5 !default;
$grey8: #E5E5E5 !default;
$grey9: #1A1A1A !default;
$beige: #FCF9F4 !default;
$gold: #FFD00D !default;

:root {
  --color-grey-02: #37424a;
  --color-grey-07: #d3d4d5;
  --color-grey-09: #f7f7f7;
  --color-grey-10: #FDF8F3;
  --color-black: #000;
  --color-white: #fff;
  --color-accent: #1A1A1A;
  --color-teal: #1A1A1A;
  --color-indigo: #992525;
  --color-yellow: #FFD00D;
  --color-yellow-mid: #FFE186;
  --color-yellow-light: #FDF8F3;
  --color-off-black: #1A1A1A;
}