@use "_mixins";
@use "_extends";
@use "_colors";
.title{text-align: center;
    label{font-size: clamp(1.4rem, 1.6vw, 1.6rem);letter-spacing: 0.16rem; font-weight: 600; display: block;  margin: 0 0 32px;}
    p{max-width: 846px; margin: 24px auto 0; line-height: 150%; }    
    h2, > p:nth-child(1){ font-size: clamp(2rem, 3.2vw, 3.2rem); line-height: 110%; letter-spacing: 0.13rem; @extend %serif; color: var(--color-off-black);
        &.sans-serif{
            &+p{margin-top: 18px; line-height: 165%;}
            &.large{font-size: clamp(2rem, 3.2vw, 3.2rem); letter-spacing: 0.03rem;
                &+p{margin-top: 0;}
            }
            &.large{font-size: clamp(2rem, 3.2vw, 3.2rem); letter-spacing: -0.15rem;}
            &.small{font-size: clamp(2rem, 1.9vw, 1.9rem); letter-spacing: -0.05rem;}
            // p{margin: 23px auto 0; max-width: 740px;
            //     &+p{ margin-top: 12px;}
            // }
            // .title-cta{display: flex; justify-content: center; gap:18px;
            //     @include mq(620){flex-direction: column;}
            // }
            // &.no-margin{margin: 0;}
            // &.large{
            //     p{margin: 28px auto 52px; line-height: 170%;}
            // }
            // @include mq(620){margin: 60px 0 60px; 
            //     p{margin-top: 12px;}
            // }
        }
        &.large{font-size: clamp(3.4rem, 6.4vw, 6.4rem); letter-spacing: 0.03rem; font-weight:700; text-transform: uppercase;
            &+p{margin-top: 29px; line-height:180%;
                @include mixins.mq(600){font-size: 1.4rem; line-height: 160%; margin-top: 20px;}
            }
        }
        &.medium{letter-spacing: -0.087rem; font-weight:700; font-size: clamp(3.2rem, 5.2vw, 5.2rem); text-transform: uppercase;
            &+p{margin-top: 22px; max-width: 1080px; line-height:160%;
                @include mixins.mq(500){margin-top: 10px;}
            }
        }
        &.small{letter-spacing:-0.016em; font-size: clamp(2.4rem, 3.2vw, 3.2rem);
            &+p{letter-spacing:0.016em; font-size: clamp(1.4rem, 2vw, 1.8rem); max-width: 655px; margin-top: 25px; line-height: 190%;}
        }
    }   
}

