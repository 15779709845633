@use "_mixins";
@use "_extends";
@use "_colors";

.container-wrapper{
    @media(max-width:680px){padding-bottom: 22px;}
}

.ml-title { margin-top: 30px; font-family: "Magazine Grotesque", sans-serif; letter-spacing: -0.087rem; font-weight: 700; font-size: clamp(3.2rem, 5.2vw, 5.2rem); text-transform: uppercase;
    @media(max-width:680px){margin: 10px 0;}
}
.ml-text { 
    margin: 15px 0 30px; 
    font-size: clamp(1.4rem, 1.6vw, 1.6rem);
    line-height: 1.5;
    color: var(--color-off-black);
    font-family: "Archivo";
}
.ml-center { text-align: center; width: 100%; }

.mixed-list-swiper-wrapper{position: relative;
    .mixed-list-swiper{margin: 70px 0;        
    
        .ml-cards{position: relative;
            .ml-card{width:294px; overflow:hidden;
                .card-bg{ display: block; height: 186px; width: 100%; position: relative; background-position:50% 50%; background-size:cover; overflow: hidden;
                    &.rounded-corners { border-radius: 8px; }                
                    &:after { content: ""; position: absolute; z-index: 1; bottom: 0; left: 0; right: 0; height: 50%; background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.71) 100%); }  
                    @media(max-width:500px){height: 142px;}
                } 
                
                .card-title { position: absolute; bottom: 18px; left: 20px; right: 10px; color: white; font-family: "Magazine Grotesque", sans-serif; z-index: 3; 
                    &:link { text-decoration: inherit; color: inherit; }
                    &:visited { text-decoration: inherit; color: inherit; }
                    span { font-family: "Archivo"; font-size: clamp(1.4rem, 1.6vw, 1.6rem); line-height: 1.5; margin-bottom: 3px; display: block; }
                    p { font-size: clamp(1.8rem, 2.2vw, 2.2rem); line-height: 1.1; text-transform: uppercase; font-weight: 700; -moz-font-feature-settings: "lnum"; -webkit-font-feature-settings: "lnum"; font-feature-settings: "lnum";}
                  
                }
    
                .offer-box{position: absolute; top:10px; left:20px; margin-right: 20px; background-color: white; padding: 6px 9px; width: 130px;
                    label{font-size: 1.4rem; line-height: 1.1; color: var(--color-off-black); font-weight: 700; margin: 0;}
                    &.purple{background-color: colors.$indigo; top: 10px; left: 20px; width:auto; border-radius: 4px;
                        label{color:white;}
                        @media(max-width:500px){left: 10px;}  
                    }
                    @media(max-width:500px){left: 10px; margin-right: 10px;}  
                }
                .type-box{position: absolute; top:10px; left:20px; margin-right: 20px; background-color: white; padding: 9px 16px;
                    label{font-size: 1.4rem; line-height: 1.1; color: var(--color-off-black); font-weight: 700; margin: 0;}
                    @media(max-width:500px){left: 10px; margin-right: 10px;}  
                }
    
                .description {text-align: center; margin-top: 14px; margin-bottom: 10px; font-size: clamp(1.4rem, 1.6vw, 1.6rem); line-height: 1.5; color: var(--color-off-black); font-family: "Archivo"; text-align: left;
                    p{line-height: inherit; font-size: clamp(1.4rem, 1.6vw, 1.6rem); line-height: 1.5; text-align: left;}
                    span{font-size: clamp(1.4rem, 1.6vw, 1.6rem); line-height: 1.5;}
                } 
                @media(max-width:1200px){width: 303px;}               
                @media(max-width:500px){width: auto;}               
            }           
            .swiper-pagination{@extend %swiper-bullets; bottom: 40px;
                .swiper-pagination-bullet{background-color:rgba(0, 0, 0, 0.2);}
            }  
        }
        @media(max-width:1200px){overflow: visible;}    
        @media(max-width:680px){margin: 20px 0;}
        @media(max-width:374px){padding-left: 10px; }
    }

    .swiper-button-disabled{display:none;}
    .swiper-button-prev, .swiper-button-next{@extend %swiper-button; margin-top: 0 !important; margin-top: 0; top:76px;
        @media(max-width:500px){top:51px;}
    }
    .swiper-button-prev{@extend %swiper-button-prev; left: -20px;
        @media(max-width:1200px){left:-20px;}
        @media(max-width:374px){left:-10px;}
    }
    .swiper-button-next{right: -20px;
        @media(max-width:1200px){right:-20px;}
        @media(max-width:374px){right:-10px;}
    }
}