@use "_mixins";
@use "_extends";
@use "_colors";
.filters {    
    display: flex;
    .kuoni-stores{ color: var(--color-off-black);}
    .partner-stores{ color: var(--color-off-black);}
    .filter-option{
        display: flex;
        align-items: center;
        margin: 10px 20px 0 0;
        font-size: 1.4rem;
        position: relative;
        input[type="checkbox"]{margin-right:8px; z-index:1; opacity:0; cursor:pointer;
            &:checked{
                &+.checkbox{background: url("../../images/icons/tick-white.svg") no-repeat center var(--color-off-black); background-size: 12px; border: 1px solid var(--color-off-black);}
            }                                   
        }                            
        .checkbox{position: absolute; left: 0; height: 20px; width: 20px; border: 1px solid #6C7175; border-radius: 4px;}   
    }    
}
@include mixins.mq(960) { .filters {padding: 0 0 20px 20px;} }

.sidebar{
    &.appointment-booker {height: -webkit-fill-available; background: transparent;
        .filters{ margin: 0 36px;  padding:0 !important;
            @include mixins.mq(500){margin:0;
                .filter-option{flex:1; margin-right:0;
                    label{font-size:1.2rem !important;}
                }
            }
        }
        .sidebar__content{padding: 120px 0 40px; height:100%;     
            .pick-a-store{ height:100%;
                .scrollbar-wrapper{position:relative;
                    &:before{content:""; height:49px; background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%); opacity: 0.03; position:absolute; top:0; left:0; right:0; z-index:2; pointer-events:none;}
                }
            }
            .sidebar__header{
                p{@include mixins.font(1.4, 1.4, 1.9rem, 400, 0, black);
                    a{color:var(--color-off-black); margin-left: 6px;}
                }
            }
            .sidebar__body{padding:0 0;
                .form-field{
                    .form-input{         
                        label{
                            &#destinations{
                                &+div{
                                    &+div{display: none;}
                                }
                            }
                        }                        
                    }
                }
                label{
                    &.not-sure-tag{color:var(--color-off-black) !important; margin-top: 4px; font-size: 1.4rem !important; text-transform: none !important; cursor: pointer;}         
                }
            }
            .store-search__input{display:flex; align-items:center; border: 1px solid colors.$grey; padding: 0 17px; position:relative; margin: 0 36px; z-index:3; border-radius: 8px;
                input{height: 58px; width:100%; border:none; font-size:1.6rem; color: colors.$darkGray; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; padding-right:10px;}
                a{display:flex; align-items:center; gap:8px; margin-right: 17px; cursor:pointer;
                    label{font-size:1.2rem; white-space:nowrap; cursor:pointer; color: colors.$teal; flex-shrink: 0;}
                }
                .auto-complete-options{position:absolute; top:100%; left:-1px; right:-1px; max-height: 40vh; overflow-y: auto; background-color:white; z-index:4; padding: 20px; display:flex; flex-direction:column; gap:14px; box-shadow: 0 12px 18px rgba(0, 0, 0, 0.12); border-radius: 0 0 4px 4px; -ms-overflow-style: none; scrollbar-width: none;
                    &>div{@include mixins.font(1.4, 1.4, 100%, 400, 0.01rem, black); cursor:pointer;}
                    &::-webkit-scrollbar{display: none;}
                }
                @include mixins.mq(500){margin:0; padding: 0 10px;
                    input{font-size:1.4rem;
                        &::-webkit-input-placeholder{font-size:1.4rem;}
                        &:-ms-input-placeholder{font-size:1.4rem;}
                        &::placeholder{font-size:1.4rem;}
                    }
                }
            }

            .store-search__body{background-color:white; height: calc(100% - 220px);
                .scrollbar-wrapper{@extend %scrollbar; padding-right:10px;}
                .scrollbar-wrapper, .map-wrapper{height: 100%;
                    @include mixins.mq(340){height: calc(100% - 40px);}
                }
                #store-select-map{height:100%;
                    .gm-style{
                        .gm-style-mtc button {
                            border-radius: 2px;
                        }
                        .gm-style-iw-a{width: 100%;}
                        .gm-style-iw-c{padding:0;
                            .gm-style-iw-d{overflow:visible !important;
                                .store-popup{display:flex; box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3); border-radius: 8px; max-width:315px; background-color:white;width: 310px;
                                    &__info{padding:16px; background-color: white; border-radius: 8px 0 0 8px; flex: 0 0 220px;
                                        h5{font-size:1.6rem; color: colors.$teal; font-family: "Archivo", serif; font-weight:600; line-height: 2rem;}
                                        p{font-size:1.4rem; color: black; margin: 9px 0 15px; line-height: 1.8rem;}
                                        a{font-size:1.2rem; color: var(--color-off-black); line-height: 1.6rem; max-width: 150px; display: block;}
                                    }
                                    &__image{overflow:hidden; border-radius: 0 8px 8px 0; flex:0 0 90px;
                                        img{@extend %fill; object-position: right;}
                                    }
                                }
                            }
                            &>button{top: -31px !important; opacity:1 !important; display:none !important;
                                span{width:20px !important; height:20px !important;}
                            }
                        }
                        .gm-style-iw-tc{display:none;}
                    }
                }
                .tabs{display:flex; align-items:center; justify-content: center; gap: 40px; padding: 50px 0 0 !important; border-bottom: 4px solid var(--color-yellow);
                    li{@include mixins.font(1.4, 1.4, 1.68rem, 400, 0, colors.$darkGray); padding: 13px 14px 10px; cursor:pointer;
                        &.active{border-color:#2CA6BD; font-weight:700;}
                    }
                }
                .store-search__store{display:flex; gap:17px; padding: 33px 36px 31px; border-bottom: 1px solid colors.$grey; cursor:pointer;
                    .store-search__info{flex:1; padding-right:20px;
                        h4{font-size:1.8rem;  color: colors.$teal; line-height:2.3rem; margin-bottom:7px; font-weight:600; letter-spacing:0; font-family: "Archivo", serif;}
                        label{font-size:1.4rem; line-height:2.2rem; color: black; display: block; max-width: 210px; cursor:pointer;
                            &:first-of-type{color: colors.$darkGray;}
                        }
                        p, a{font-size:1.2rem; line-height:1.6rem;}
                        p{margin-top: 17px; color:#6C7175;}
                        a{color: colors.$teal; display:block; margin-top: 17px; cursor:pointer;}
                        small{@include mixins.font(1.2, 1.2, 100%, 400, 0, colors.$grey4, "Archivo"); display:block; margin-bottom:10px;}
                    }
                    .image{ height: 147px; width:110px; background-color: #f7f7f7; border-radius: 8px;
                        img{height:100%; width:100%; object-fit:cover;}
                    }
                    &:first-child{padding-top:23px;}
                    &:last-child{border-bottom:none;}
                    @include mixins.mq(500){padding: 33px 20px 31px;}
                }
            }
            .pick-an-expert{
                h4{@include mixins.font(1.8, 1.8, 100%, 700, 0, #232020, "Archivo"); text-transform:uppercase; padding: 0 40px; text-align:center; cursor:pointer;}
                .scrollbar-wrapper{padding-bottom:10px; height: calc(100vh - 288px); }
                .any-expert{background-color:var(--color-yellow-light); border: 1px solid colors.$grey8; border-radius: 2px; display:flex; cursor:pointer; justify-content:space-between; margin: 60px 36px 20px; padding: 18px;
                    label{@include mixins.font(1.4, 1.4, 1.9rem, 400, 0, black); display:flex; align-items:center; gap:9px; cursor:pointer;}
                    &.active{background-color:#EBF9FC; border-color:#2CA6BD;}
                }
                .experts{overflow-y:auto;
                    .expert{border-bottom:1px solid colors.$grey8; padding:24px 36px 22px; cursor:pointer;
                        &>div{display:flex; gap:20px; margin-bottom:24px;
                            label{@include mixins.font(1.8, 1.8, 1.8rem, 600, 0, black); padding: 10px 0 0; display:block; cursor:pointer;}
                            span{@include mixins.font(1.4, 1.4, 1.9rem, 400, 0, colors.$grey3); display:block; margin:4px 0px 16px;}
                            p{@include mixins.font(1.4, 1.4, 1.96rem, 400, 0, colors.$darkGray); overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;}
                            small{@include mixins.font(1.2, 1.2, 1.2rem, 400, 0, colors.$grey3);}
                        }
                        .expert-image{height:110px; flex:0 0 110px; background-color:#f7f7f7;}
                        .next-available{@include mixins.font(1.2, 1.2, 1.2rem, 400, 0, colors.$grey3);}
                        &.active{background-color: #EBF9FC;}
                        &:last-child{border:none;}
                    }
                }
            }

            .pick-a-date{padding: 0 36px;
                .sidebar__header{
                    p{margin:20px auto 0;}
                }
                @include mixins.mq(500){padding: 0 20px;}
            }

            .pick-a-time{padding: 0 36px;
                h6{@include mixins.font(1.6, 1.6, 1.6rem, 700, 0.07rem, black); text-transform: uppercase;}
                .scrollbar-wrapper{@extend %scrollbar; padding: 0 0 36px 0; height: calc(100vh - 168px); }
                .times{display: grid; grid-template: 58px / repeat(3, 1fr); grid-auto-rows: 58px; gap:10px; margin-top: 32px; padding: 0px 10px 0 0; 
                    .time{border: 1px solid #E3E4E5; box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05); cursor: pointer; border-radius: 4px; display: flex; align-items: center; justify-content: center;
                        label{display: block; cursor: pointer; @include mixins.font(1.6, 1.6, 2.17rem, 400, 0, black); text-align: center;}
                        &.active{background-color: #82244E; border-color: #82244E;
                            label{color:white;}
                        }
                    }                   
                }
                @include mixins.mq(500){padding: 0 20px;}
            }

            .pick-a-type{padding: 0 36px;
                h6{@include mixins.font(1.6, 1.6, 2.17rem, 700, 0.07rem, black); text-transform: uppercase; max-width: 240px; margin: 0 auto;}
                .scrollbar-wrapper{@extend %scrollbar; padding: 0 0 36px 0; height: calc(100vh - 193px); }
                .types{margin-top: 30px; display: flex; flex-direction: column; gap:20px; padding-right: 10px;
                    .type{display: flex; align-items: center;display: flex; align-items: center; cursor: pointer; border: 1px solid #E3E4E5; padding: 25px 32px; gap: 29px; box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06); border-radius: 8px;
                        img{}
                        label{@include mixins.font(1.8, 1.8, 2.45rem, 700, 0, black); cursor: pointer;}
                        p{@include mixins.font(1.4, 1.4, 1.9rem, 400, 0, black); margin-top: 4px;}
                        &.active{border-color: #82244E;}
                    }
                }
                @include mixins.mq(500){padding: 0 20px;}
            }

            .add-details{padding: 0 36px;
                .scrollbar-wrapper{@extend %scrollbar; padding: 0 0 36px 0; height: calc(100vh - 211px);
                    &>div{padding-right:10px;}
                }
                .add-details-form{
                    .form-input{margin: 0 0 33px;
                        label{@include mixins.font(1.4, 1.4, 1.9rem, 400, 0, black);  display:block; margin-bottom:6px;}
                        p{@include mixins.font(1.4, 1.4, 2.1rem, 400, 0, black); margin-bottom:10px;
                          a{color: colors.$purple}
                          &.extra-info{margin-top:8px;} 
                        }
                        input, select, textarea, .faux-select, .multiselect{border: 1px solid colors.$grey7; border-radius: 8px; padding: 12px; width:100%; min-height:58px;}
                        input[type="date"]{min-width: 200px; height: 58px; background-color: white; width:100vw; max-width: 100%; position: relative;
                            // &:empty{&:after{content: attr(placeholder) !important; color: $darkGray; }}                                                    
                            // &:focus:after {content: '' !important;}                                   
                            &::-webkit-date-and-time-value{text-align: left; padding-left: 8px; color: colors.$darkGray; font-size: 1.4rem;}
                        }

                        .multiselect{border-radius:0;
                            .multiselect-tags-search-wrapper{
                                input{border:none; min-height:auto; cursor:pointer; @include mixins.font(1.4, 1.4, 2.1rem, 400, 0, black); padding-top:5px; padding-left:0;}
                                .multiselect-tags-search{top:-1px;}
                            }
                            .multiselect-dropdown{max-height: 400px;
                                .multiselect-options{
                                    li{padding:12px;
                                        span{@include mixins.font(1.4, 1.4, 1.4rem, 400, 0, black);}
                                    }
                                }
                            }
                            .multiselect-caret{width: 10px; height: 16px;}
                            .multiselect-tags{padding:0; margin:0; gap:5px;
                                .multiselect-tag{@include mixins.font(1.4, 1.4, 2.1rem, 400, 0, black); display: inline-flex; align-items: center; margin:0; background-color: #D3F3FA; padding: 7px 14px; border-radius: 8px; cursor:default;
                                    span{cursor:pointer; height: 1.75rem; margin-left: 4px;}
                                }
                            }
                            .multiselect-clear{display:none;}
                            &.is-active{box-shadow:none;}
                        }
                        .faux-select{position:relative; padding-top:0; padding-bottom: 0; background: white;
                           /* label{margin:0; cursor:pointer; text-transform:none;}*/
                            &__selected{display:flex; flex-wrap: wrap; gap:12px; padding: 0 0 12px 0;
                                li{@include mixins.font(1.4, 1.4, 2.1rem, 400, 0, black); display: inline-flex; align-items: center;  background-color: #D3F3FA; padding: 7px 14px; border-radius: 8px; cursor:default;
                                   img{width:10px; margin-left:8px; cursor:pointer;}
                                }
                            }
                            &__dropdown{position:absolute; top:57px; left:0; right:0; background: white; z-index: 2; padding: 15px 20px; box-shadow: 0px 12px 24px rgb(0 0 0 / 20%); border-radius: 0 0 5px 5px; display:grid; cursor:default; transition: opacity 0.2s, transform 0.2s ease-out; transform:translateY(-4px); opacity:0; pointer-events:none;
                                li{@include mixins.font(1.4, 1.4, 2.1rem, 400, 0, black); cursor:pointer; padding:5px;}
                                &.active{transform:translateY(0); opacity:1; pointer-events:all;}
                            }
                            &__text{padding: 4px 0 0 0; border:none; min-height: 52px;
                                &::placeholder{font-size: 16px;}
                            }
                            .no-destinations-found{padding-bottom: 12px;
                                li{font-size: 14px; color:#797979;}
                            }
                        }
                        textarea{resize:none; min-height:150px;
                            &:focus-visible{outline:none;}
                        }
                        input[type="number"]{ -moz-appearance: textfield;
                            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}                        
                        }
                        select{-moz-appearance: none; -webkit-appearance: none; appearance: none;}
                        .faux-select, select{background: url("../../images/icons/chevron-down.svg") no-repeat center right 18px; cursor:pointer;}
                        .invalid{ border:solid 1px red !important; }
                    }
                    button{border:none; background-color: var(--color-yellow); font-family: "Magazine Grotesque", sans-serif; font-weight: 700; border-radius:8px; padding: 12px 15px; @include mixins.font(1.6, 1.6, 1.8rem, 600, 0, var(--color-off-black)); margin: 20px auto 0; min-height: initial; height: auto;
                        @include mixins.mq(500){margin-bottom:100px;}
                    }
                }
                @include mixins.mq(500){padding: 0 20px;}
            }
            

            .appointment-summary{padding: 0 36px;
                .scrollbar-wrapper{@extend %scrollbar; padding: 0 0 36px 0; height: calc(100vh - 211px); }
                &__details{padding:0 10px 0 0;
                    section{padding:25px 38px 23px; border-bottom: 1px solid colors.$grey8; background-color:var(--color-yellow-light);
                        h5{@include mixins.font(1.4, 1.4, 1.9rem, 400, 0, colors.$grey3); text-transform:uppercase; display:flex; align-items:center; justify-content:space-between; margin-bottom:10px;
                              span{color:var(--color-off-black); text-transform:none; line-height:1.9rem; cursor:pointer;}
                        }
                        p{@include mixins.font(1.6, 1.6, 2.4rem, 400, 0, black); margin-bottom:9px;}
                        span{@include mixins.font(1.4, 1.4, 1.9rem, 400, 0, #6F6F6F);}
                        .flex{align-items:center; gap: 20px;
                             .expert-image{flex:0 0 40px; height:40px; border-radius:100%; overflow:hidden;}
                        }
                        &.details{
                            h5{margin-bottom: 27px;}
                            p{display:flex; align-items:flex-start; gap:30px; @include mixins.font(1.4, 1.4, 1.9rem, 600, 0, black); margin:0 0 21px;
                                i{font-style:normal; flex:0 1 83px}
                                span{@include mixins.font(1.6, 1.6, 2.17rem, 400, 0, black); flex:1;
                                     &.email-string{word-break:break-all;}
                                }
                                @include mixins.mq(440){flex-direction:column;  gap:2px;
                                    i{flex:none;}
                                }
                            }
                        }                       
                        &:last-of-type{border-bottom:0; padding-bottom:5px;}
                    }
                }
                button{border:none;  margin: 55px auto 0; height: auto; min-height: initial; padding: 7px 15px; background-color: var(--color-yellow); font-size: 1.6rem; color: var(--color-off-black); border-radius: 8px;
                    @include mixins.mq(500){margin-bottom:100px;}
                }
                @include mixins.mq(500){padding: 0 20px;}
            }
            
            .confirmation-stage{margin-top:-120px; 
                .scrollbar-wrapper{@extend %scrollbar; padding: 0 0 0 0; height: 100vh; }
                &__header{max-height:360px; height:41vh; background-color: #f7f7f7;} 
                &__body{padding: 0 36px;
                    .confirmation__pte{height:120px; width:120px; border-radius:100%; border: 2px solid white; margin: -60px auto 0; z-index:2;}
                    h4{@include mixins.font(4.5, 4.5, 5.4rem, 400, 0, black, "Magazine Grotesque"); text-align:center; margin: 23px 0 36px;}
                    p{@include mixins.font(1.8, 1.8, 2.8rem, 400, 0, colors.$darkGray); max-width: 292px; margin: 0 auto; text-align:center;}
                    &>span{@include mixins.font(1.6, 1.6, 1.9rem, 400, 0, colors.$darkGray); display: block; margin: 61px auto 65px; max-width: 292px; text-align:center;
                        a{color:var(--color-off-black);}
                    }   
                    .cta{background-color: colors.$darkGray; cursor:default; position:relative; margin-bottom:7px; height:61px; min-height:auto; border: 1px solid colors.$darkGray; @include mixins.font(1.6, 1.6, 100%, 600, 0, white); padding:19px 0 21px; border-radius: 2px; display:flex; justify-content: center; align-items:center; gap:10px;
                         .atcb-light{padding:0; position:absolute; top:0; right:0; bottom:0; left:0; height:100%; width:100%; height:61px;
                             button{background-color:transparent; cursor:pointer; max-width:none; border:none; color: colors.$darkGray; box-shadow:none; margin:0; padding:0; height:100%; width:100%; min-height:auto;
                                    span{@include mixins.font(1.6, 1.6, 100%, 600, 0, white);
                                         &.atcb-icon{margin:0; order:2; width:20px; height:20px;}
                                         .atcb-icon-trigger{width:20px; height:20px;}
                                         svg{width:20px; height:20px;}
                                    }
                                    .atcb-dropdown-anchor{top:0; right:0; bottom:0; left:0;}
                             }
                         }
                         .white-button{background-color:white; color: colors.$darkGray; margin-bottom:0; cursor:pointer;}
                    }
                }
            }
        }
        .back{position:absolute; top:27px; left:26px; transform: rotate(-180deg); width:13px; cursor:pointer;}
    }
}
@keyframes loading {    
    to {transform: rotate(360deg);}
}

.loading-spinner{display:flex; align-items:center; justify-content:center; flex-direction:column;
    img{animation: loading linear 2s both infinite;}
    p{@include mixins.font(1.4, 1.4, 1.9rem, 600, 0, black); text-transform:uppercase; margin-top:15px; text-align:center;}
}
.slide-fade-enter-active, .slide-fade-leave-active{transition: all 0.3s ease-out;
    &>img, &>div, &>h6{transition: all 0.3s ease-out;}
}
.slide-fade-enter-from, .slide-fade-leave-to{
    &>img, &>div, &>h6{transform: translate3d(0, 3px, 0); opacity: 0;}
}

.sidebar-slide-enter-active, .sidebar-slide-leave-active {transition: all 30.3s ease-out;}
.sidebar-slide-enter-from, .sidebar-slide-leave-to {opacity: 0; transform: translate3d(100%, 0, 0); transition: all 30.3s ease-out;}

.vc-container{border:none; width: 100%;
    .vc-pane-layout{
        .vc-pane{
            .vc-header{padding: 0; margin: 0 0 41px;
                .vc-title{@include mixins.font(1.6, 1.6, 1.6rem, 700, 0, black); text-transform: uppercase;}
            }
            .vc-weeks{padding: 0 15px;
                .vc-weekday{padding: 0; @include mixins.font(1.6, 1.6, 2.17rem, 400, 0, #8E8C9A); margin-bottom: 12px;}
                .vc-day{height: 48px;
                    span{@include mixins.font(1.5, 1.5, 0px, 600, 0, black); font-weight: 600 !important; width: 48px; height: 48px; border-radius: 0 !important;
                        @include mixins.mq(500){width: 38px;}
                        &.is-disabled{opacity: 0.3; font-weight: 400 !important;}                                       
                    }
                    .vc-highlights{
                        .vc-day-layer{
                            .vc-highlight{width: 48px; height: 48px; border-radius: 0 !important;}
                        }
                        &+span{cursor: default; color: var(--color-off-black) !important;
                            &:hover{background-color: transparent; }
                        }                                        
                    }  
                    &.is-not-in-month{opacity: 0;}                                  
                }
            }
        }
    }
    .vc-arrows-container{padding: 0; top: -28px; padding: 19px 15px;}
}


// from CRO thirdparty
.sidebar__content .sidebar__header {
	margin-bottom: 40px;
}
.sidebar.appointment-booker  .pick-a-store .sidebar__header > h4{
	font-family: "Magazine Grotesque", sans-serif;
	font-size: 32px;
	font-weight: 400;
	line-height: 38.4px;
	letter-spacing: -0.02em;
	text-align: center;
				
}				
.sidebar.appointment-booker  .pick-a-store .sidebar__header > .subtitle{
	font-size: 18px;
	font-weight: 400;
	line-height: 36px;
	text-align: center;
	max-width:unset;
}				
.sidebar.appointment-booker  .add-details .text__be-in-touch{
	font-size: 1.4rem;
	line-height: 1.4;
	font-weight: 400;
	margin: 0 0 ;
}