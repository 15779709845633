@use "_colors";

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin border-side($side, $px: 1, $color: #000) {
    border-#{$side}: #{$px}px solid $color;
}

@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;
    -moz-box-sizing: $box-model;
    box-sizing: $box-model;
}

@mixin transition($speed) {
    -webkit-transition: $speed;
    -moz-transition: $speed;
    transition: $speed;
}

@mixin placeholder {
    &::-webkit-input-placeholder{@content;}
    &:-moz-placeholder{@content;}
    &::-moz-placeholder{@content;}
    &:-ms-input-placeholder{@content;}
}


@mixin flex($align:flex-start, $justify:flex-start, $direction:row, $wrap:nowrap) {      
    display: -ms-flexbox; display: -webkit-flex; display: flex;
    align-items: $align; -webkit-align-items: $align;    
    justify-content: $justify; -webkit-justify-content: $justify;
    flex-direction: $direction; -webkit-flex-direction: $direction;
    flex-wrap: $wrap;-webkit-flex-wrap: $wrap; 
}
/*
@mixin font($s:1.6, $lh:24, $ls:1, $f:$sanSerif, $w:400, $c:white){
    font-size: $s+rem; line-height: $lh+px; letter-spacing: $ls+px; font-family: $f; font-weight:$w; color: $c
}*/

//merged clamp with font (recommended)
@mixin font($min, $max, $lh:100%, $w:400, $ls:0, $c:colors.$darkGray, $f:"Archivo"){
    font-size: $min+rem; //IE fallback
    font-size: clamp(#{$min}rem, #{$max}vw, #{$max}rem);
    line-height: $lh; font-weight:$w; color: $c; letter-spacing: $ls; font-family: $f; 
}
@mixin fontBasic($size: 1.4, $weight: 400, $color: $black, $height: 20) {
    font-size: $size + rem;
    font-weight: $weight;
    color: $color;
    line-height: $height + px
}

//create mq mixin
@mixin mq($size){ 
    @media(max-width: $size + px){@content};
}

@mixin mqmin($size){ 
    @media(min-width:#{$size+1}px){@content}; //+1 to match media above passed in value
}

@mixin mqbetween($minSize, $maxSize){ 
    @media(min-width:#{$minSize+1}px) and (max-width:#{$maxSize}px){@content}; //minSize+1 to match media above passed in value
}



//background div ratio (scale height with window width)
@mixin bgRatio($min: 100, $max: 100, $h: 100) {
    min-height: $min+px;
    max-height: $max+px;
    height: $h+vw;
}

@mixin siblings($i, $el:div) {
    @if $i == 1 {&:first-child:nth-last-child(1) {@content;}}
    @else {&:first-child:nth-last-child(#{$i}), &:first-child:nth-last-child(#{$i}) ~ #{$el} {@content}};
}

@mixin min-siblings($i, $el:div) {
    @if $i == 1 {&:first-child:nth-last-child(1) {@content;}}
    @else {&:first-child:nth-last-child(n+#{$i}), &:first-child:nth-last-child(n+#{$i}) ~ #{$el} {@content}};
}
