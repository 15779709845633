@use "_mixins";
@use "_extends";
@use "_colors";
.search-inspiration-list{
    .search-inspiration{display: flex; justify-content: space-between; gap:50px; align-items: center;
        &:not(&:last-child){border-bottom: 1px solid colors.$grey8; padding-bottom: 20px; margin-bottom: 20px;}
        &>div{display: flex; gap:30px; align-items: center;
            .search-inspiration__image{flex: 0 0 150px; height: 100px; border-radius: 8px;
                @include mixins.mq(545){display: none;}
            }
            h5{@include mixins.font(1.8, 2.2, 1.1, 700, 0,  var(--color-off-black));}
            p{@include mixins.font(1.4, 1.6, 1.4, 400, 0, var(--color-off-black)); overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; margin: 6px 0;
                @include mixins.mq(700){ -webkit-line-clamp:inherit;}
            }
            ul{display: flex; gap:10px; align-items: center;
                li{@include mixins.font(1.2, 1.2, 1.63rem, 400, 0,  var(--color-off-black)); text-transform: uppercase;}
            }
        }
        &.has-collection{
            h5{font-family: "Magazine Grotesque", serif; font-size: 2rem;}
            .collection{display: flex; align-items: center; gap:7px;
                span{@include mixins.font(1, 1, 1.48rem, 700, 0.04rem, var(--color-off-black)); text-transform: uppercase;}
            }
        }
    }
}
