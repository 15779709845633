@use "_mixins";
@use "_extends";
@use "_colors";
.filter-menu{display: flex; align-content: center; justify-content:space-between; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc;
    .filter-menu__type{display: flex; 
        label{@include mixins.font(1.2, 1.4, 1.68rem, 400, 0.04rem, #666); text-transform: uppercase; margin-right: 25px; display:flex; align-items:center; padding-top: 2px;
            img{margin-left:8px;
                @media(min-width: 691px){display:none;}
            }
            &::after{content:":";
                @media(max-width: 690px){content:none;}
            }
        }
       
        &>ul{display: flex; align-content: center; gap:10px; z-index:2;
            &>li{@include mixins.font(1.2, 1.6, 1.4, 400, null, var(--color-off-black)); align-items: center; position:relative;  cursor:pointer; display: flex; align-content: center; gap: 5px; background-color:transparent; padding:17px 13px; border-radius: 4px; transition: 0.2s;
                i{font-style: normal; display: flex; align-items: center; gap:4px; min-height: 21px;
                    &>span{background-color: var(--color-yellow-mid); border-radius: 4px; width: 21px; height: 21px; font-weight: 700; font-size: 1.4rem; display: flex; align-items: center; justify-content: center;}
                }               
                img{align-self: center;  margin-left: 5px; width: 12px;}
                ul{background-color:white; display:grid; gap:0; scrollbar-width: none; position:absolute; top:53px; left:0; opacity:0; transform:translateY(4px); pointer-events:none; transition: opacity 0.2s, transform 0.3s ease-out; min-width:190px; max-height:242px; overflow:hidden auto; box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2); border-radius: 8px;
                   &::-webkit-scrollbar {display: none; -ms-overflow-style: none; scrollbar-width: none; } 
                   li{font-size:1.4rem; color:var(--color-off-black); display:flex; align-items:center; padding: 15px 10px; font-weight:400; padding-right: 40px; border-bottom:1px solid colors.$grey8; position:relative;
                       span{color: colors.$grey4; margin-left:6px;}
                      /* @media(min-width:690px){
                           input[type="checkbox"], .checkbox{display:none !important;}
                      }*/
                      input[type="checkbox"]{position: absolute; top:0; right:0; bottom:0; left:0; margin:0; z-index:1; opacity:0; cursor:pointer; height: 100%; width:100%;
                          &:checked{
                              &+.checkbox{background: url("../../images/icons/tick-white.svg") no-repeat center var(--color-off-black); background-size: 12px; border: 1px solid var(--color-off-black);}
                          }                                   
                      }                            
                      .checkbox{position: absolute; top: 50%; transform: translateY(-50%); right: 10px; height: 20px; width: 20px; border-radius: 4px; border: 1px solid #6C7175;}   
                     
                      &:hover{background-color:#F0FCFF;}
                   }                  
                   &:last-child{border-bottom:0;}
                }          
                .price-ranges{display:flex; gap:10px;
                   .price-range{border:1px solid colors.$grey7; padding: 7px 10px 4px; width:150px;
                       span{font-size:1.2rem; text-transform:uppercase; color: colors.$grey3;}
                       &>div{display:flex; align-items:center; font-size:1.6rem; color: var(--color-off-black);
                           input{border:none; font-size:1.6rem; text-transform:uppercase; color: var(--color-off-black); width:100%;}
                       }                       
                   }
                }
                &.active{box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); background-color:white;
                    ul{opacity:1; transform:translateY(0); pointer-events:all; scrollbar-width: thin; }
                }
                &:last-child{
                    ul{
                       @include mixins.mq(1050){right:0; left:auto;}
                       @include mixins.mq(1000){right:-2px;}
                    }
                }
                &.filter-title{padding:25px 0 10px !important; position:relative; cursor:default;
                     .filter-close{position:absolute; top:24px; right:-1px; height:18px; cursor:pointer; display:block !important;}
                     label{font-size:1.6rem; color:var(--color-off-black); text-transform:uppercase; justify-content:center; padding:0; margin-right: 0;}
                     @media(min-width:690px){display:none;}
                }
                &.board{
                     &>ul{min-width:214px;}
                }

                @include mixins.mq(500){align-items: flex-start;}
            }
            &.sort-by{
                ins{text-decoration:none;}
                &>li{
                    ul{right:-2px; left:auto; width:100%; //min-width:214px;
                       li{
                           .checkbox{display:none !important;}
                       }
                    }
                }
            }
        }
        &:last-child{
            label{margin-right:10px;}
            &>ul{
                 &>li{letter-spacing:-0.03rem;}
            }
        }
               
        
        @include mixins.mq(1210){flex-direction:column; gap:3px;
            label{padding-left:13px; padding-top: 20px;}
            &>ul{
                &>li{gap: 6px; font-size:1.3rem;}                
                &.tags{
                    &>li{
                        &:first-child{
                             ul{left:-2px;}
                        }
                    }
                }
            }             
        }
        @include mixins.mq(1000){       
            &>ul{
                &.tags{justify-content:space-between;}
                &.sort-by{
                    &> li ul{left:-2px; right:auto;}
                }
            }
        }

        @include mixins.mq(730){
            &>ul{gap: 10px;}
        }

        @include mixins.mq(690){gap:5px; padding: 43px 0 20px;
            &>ul{position: fixed; top: 10px; left: 0; right: 0; background: white; padding: 5px 0; box-shadow: 0 4px 6px -2px rgb(0 0 0 / 13%); opacity:0; pointer-events:none; transition: opacity 0.2s, top 0.2s ease-out;
                &>li{flex-direction:column; font-size: 1.5rem;
                    &>ul{position:relative; top:4px; box-shadow:none; padding:0 0 0 12px; gap:0; left:0!important; width: 100%; height:0; max-height: none; overflow:hidden; background-color:transparent;
                          &>li{justify-content:flex-start; padding: 15px 10px; border-bottom: 1px solid colors.$grey8; 
                      
                                                
                          }
                    }
                }
                &.tags{justify-content:flex-start;}
                &.sort-by{gap:0;
                    &>li{padding:0;
                        &>ul{padding-left:0;}
                    }                  
                }
                &.tags, &.sort-by{padding-top: 80px; padding-bottom:130px; transform:translateY(103vh);
                    &.active{transform:translateY(0);}
                }
                &.active{opacity:1; pointer-events:all; top:0;}                
            }
            &.active{
                &>label{
                    img{transform:rotate(-180deg);}
                }
            }

            &>ul{flex-direction:column; gap: 10px; padding: 10px 20px; border-bottom:1px solid colors.$grey8; height: calc(100vh + 2px); overflow: auto; justify-content: flex-start; 
                &.tags{right: 0;}
                &.sort-by{left: 0;
                    &>li{
                        ins, &>img{display:none;}
                        ul{opacity:1; transform:translateY(0); pointer-events: all; height:auto;}
                    }
                }
                &>li{gap:0; position:relative; padding:10px 0; width: 100%;
                    img{position:absolute; top:14px; right:0;}   
                    &:last-child{
                        ul{
                            li{
                                &:last-child{border-bottom:none;}
                            }
                        }
                    }
                    &.active{box-shadow:none;
                        img{transform:rotate(-180deg);}
                        ul{height:auto; transform:translateY(10px); padding:0;}
                    }
                }                
            }
            &.active{z-index:6; cursor:default;}              
        }
        @media(min-width: 690px){
            &:first-child{z-index:4;}
        }
    }
    &.centered{justify-content:center;}

    @include mixins.mq(1000){flex-direction:column; gap:15px;}

    @include mixins.mq(690){position:sticky; top: 54px; z-index:12; gap:8px; padding: 0 20px !important; background-color: var(--color-yellow-light); justify-content:center; align-items:center; margin: 0 0 0 -42px; width: calc(100% + 84px); flex-direction:row; border-top:1px solid #e5e5e5;
        .filter-menu__type{flex:1;  align-items: center; padding: 25px 0; cursor:pointer; 
            &>label{font-size:1.4rem; margin:0; justify-content: center; cursor:pointer; padding:11px 10px; border-radius: 8px; border: 1px solid #ccc; background-color: white; width: 100%; color:var(--color-off-black);
                img{display: none;}
            }   
              &:last-child{
                  label{margin:0;}
              }
             
        }
        @include mixins.mq(690){margin:0px 0 0px -30px; width: calc(100% + 60px);}
        @include mixins.mq(374){margin: 0px 0 0px -20px; width: calc(100% + 40px);}
    }
    .price-range-slider{width: 100%;
       .sliders{position: relative; height: 35px; margin-top: -16px;
            input{width: 100%; position: absolute; top:0; left:0; margin: 0;}
            @include mixins.mq(690){width: 100%;}
       }
        .price-bars{display: flex; align-items: flex-end; gap:3px; height: 30px;
            .price-bar{flex:1; background-color: colors.$gold; //in range color
                &.inactive{background-color: colors.$grey8;} //out of range color
            } 
        }
    }
    .min-max-slider {position: relative; width: 100%;
        .prices{display: flex; gap:12px; margin-top: 12px;
            .price{position: relative; border: 1px solid colors.$grey7; flex:1;
                label{font-weight: 400; padding: 27px 10px 8px;}
                &:after{position:absolute; top:9px; left:10px; font-size:1.2rem; color: colors.$grey3;}
            }
            @include mixins.mq(690){width: 100%;}
        }
    }
    .price.min:after {content:"MIN";}
    .price.max:after {content:"MAX";}
    #price-dropdown{width:362px; max-height: none;
        &>li{border-bottom: none; padding:20px 20px 28px;}
        @include mixins.mq(690){padding:0 0 14px; width: 100%;
            &>li{padding: 8px 0;}
            span{margin-left:0 !important;}           
        }
        @include mixins.mq(374){
            span{width:134px;}          
        }
    }

    /* webkit specific styling */
    .min-max-slider input {
      -webkit-appearance: none;
      outline: none!important;
      background: transparent;
      background-image: linear-gradient(to bottom, transparent 0%, transparent 45%, silver 45%, silver 52%, transparent 52%, transparent 100%);
    }
    .min-max-slider input::-webkit-slider-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 35px; 
      height: 35px; 
      background: url("../../images/icons/filter-handle.svg") no-repeat center white;
      cursor: pointer; 
      border: 1px solid colors.$grey8;
      border-radius: 100%;
        z-index: 2; position: relative;
    }
    .min-max-slider input::-webkit-slider-runnable-track {cursor: pointer;}
}


.filter-menu-tags{display:flex; align-items:center; gap:12px; flex-wrap:wrap; margin-bottom: 20px; //min-height:35px; 
    li{font-size:1.6rem; color:var(--color-off-black); display:flex; align-items:center; gap:10px; border-radius: 8px; background-color:var(--color-yellow-mid); padding: 11px 14px;
        img{width:12px; cursor:pointer;}
        &.clear{color: var(--color-off-black); padding:0; border:0; background-color:transparent; cursor:pointer; order:9999; text-decoration: underline;
            &.active{display:block;}
        }
        &.show-results-mobile{display: none; border: none; padding: 0;
            button{background-color: var(--color-off-black);; border-radius: 3px; font-size: 1.4rem; color:white; border: none; font-weight: 600; height: 43px;}
        }
    }
    .button{
        @media(min-width:690px){display:none;}
    }
    @include mixins.mq(690){position:fixed; bottom:0; left:0; right:0; background-color:white; box-shadow:0px 0px 30px rgba(0, 0, 0, 0.06); padding-top:0; z-index:105; padding:0 24px !important; height:66px; justify-content:space-between; border-top: 1px solid colors.$grey7; margin-bottom: 0; padding: 0 28px;
        li{display:none;
            &.clear{order:inherit;}
            &.button{display:block; padding: 0; border: 0;
                button{height:44px !important; padding: 0 20px !important; margin-left:auto;}
            }            
            &.clear, &.show-results-mobile{display: block;}
        }
        
        &.active{display:flex;}
    }
}
 @media(max-width:690px){
    .container-filters{
         .filter-menu{z-index:101;}
    }
 }

 .filter-menu-tags.active{padding-bottom:35px;}
