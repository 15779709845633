@use "_mixins";
@use "_extends";
@use "_colors";
header{height: auto; background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%); position:sticky; top:0; left:0; right:0; z-index:100; transition: height 0.2s, background-color 0.2s ease;
    .container{display: flex; align-items: center; justify-content:space-between; padding: 0px 25px 0 !important; height:90px; gap:20px;transition: height 0.2s, background-color 0.2s ease;
        nav{display: flex; align-items: center;
            ul{gap:40px; 
                li{display: flex; align-items: center; justify-content: space-between;
                    img{opacity: 0.7; width: 12px;}                  
                    a{color:inherit;}                    
                                
                }  
                @include mixins.mq(1280){gap:30px;}
                @include mixins.mq(930){gap:23px;} 
                @include mixins.mq(880){gap:14px;} 
                @media(max-width: 760px) and (max-height: 520px){gap:25px !important; padding-top: 60px !important;
                    li{font-size: 2.8rem !important; line-height: 100% !important;}
                }                
            }
            @include mixins.mq(760){position: fixed; top:0; right:0; bottom:0; left:0; padding-bottom: 85px; background-color: var(--color-yellow-light); transform: translateX(-100%); pointer-events: none; z-index: 997; transition:transform 0.2s ease-out;
               ul{padding: 0 30px; flex-direction: column; gap:40px; align-items: flex-start; justify-content: center; width: 100%;
  
                    @media(max-height: 880px){gap:24px;}
                    @media(max-height: 660px){gap:18px;}
               }
               &.active{transform: translateX(0); pointer-events:all;}               
            }

            #close-header-menu{position: absolute; top:31px; right:31px;}
        
            .my-kuoni-mobile{position: absolute; bottom:30px; left:30px; right:30px; color: white; border: 1px solid #6C7175; border-radius: 8px; display: flex; align-items: center; justify-content: center; gap:8px; height: 54px; font-size: 1.6rem;
                @media(min-width: 761px){display: none;} 
            }           
       }
       
       ul{display:flex; align-items:center;
            li{@include mixins.font(1.4, 1.6, null, null, null, white); cursor: pointer; font-family: "Magazine Grotesque", sans-serif;
                @include mixins.mq(1000){font-size: 1.2rem;}    
            }
           &.header__cta{gap:24px;position:relative;
                li{display:flex; align-items:center; gap:6px;
                    #search-trigger{display: flex; align-items: center; gap:6px;}
                   &.phone-number{ 
                       position: absolute; top: -37px; right: 0px; 
                       .light-icon{ display:block; }
                       .dark-icon{ display:none; }                       
                   }
                   a{display:flex; align-items:center; gap:6px; @include mixins.font(1.4, 1.4, null, null, null, white);}
                   &#mobile-menu-trigger{
                       svg{
                           line{stroke: white; transition: 0.2s ease;}
                       }
                   }
                }        
                @include mixins.mq(1280){gap:20px;}        
                @include mixins.mq(1200){margin-top:0;
                    li{
                        &.phone-number{ display:none; }
                        
                        a{
                            &>span{display: none;}
                        }
                        @include mixins.mq(760){
                            &:nth-child(2){display: none;
                                a{display: none;}
                            }    
                            &#search-trigger{display: flex !important;}                       
                        }                   
                    }
                }  
                li{
                    @media(min-width: 761px){ 
                        &:last-child{//display: none;
                            a{display: none;}
                        }
                    }  
                }             
           }
       }
       @include mixins.mq(1000){gap: 5px;}
       @include mixins.mq(860){padding: 0px 42px 0 !important;}      
       @include mixins.mq(620){padding: 0px 30px 0 !important;}      
       @include mixins.mq(374){padding: 0px 20px 0 !important;}      
    }    

    @include mixins.mq(930){          
        .container{ height: 107px;}
    }

    @include mixins.mq(1200){       
        .container{
            &>a{
                img{width: 150px;}
            }
        } 
    }

    @include mixins.mq(980){       
        .container{
            &>a{
                img{width: 140px;}
            }
        } 
    }

    @include mixins.mq(340){       
        .container{
            &>a{
                img{width: 110px;}
            }
        } 
    }

    @include mixins.mq(760){z-index: 1010;          
        .container{height: 80px;}
    }

    @include mixins.mq(374){       
        .container{padding: 0 20px 0 !important;}    
    }

    &.scrolled{background:white; box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.05);
        .container{ 
            svg{
                path{fill: black; transition: 0.2s ease;}
            }
            nav{
                ul{ 
                    li{
                        @media(min-width: 761px){color:black;
                            a{color:black;}
                        }
                    }                
                }
            }
            ul{
                &.header__cta{
                    li{color:black;
                        //&.phone-number { display:none; }
                        a{color:black;}
                        &#mobile-menu-trigger{
                            svg{
                                line{stroke:black; transition: 0.2s ease;}
                            }
                        }
                    }
                }
            }
        }    
        @include mixins.mq(760){
            .container{height: 56px;}
        }
    }
}

#find-store-trigger {display: flex; align-items: center; cursor: pointer; color: black; gap: 3px; justify-content: end;
    &>img{width: 26px;}
    label {text-transform: uppercase; font-size: 1.4rem; font-family: "Magazine Grotesque", sans-serif; font-weight: 700; letter-spacing: 0; cursor: pointer;}
    @media(max-width: 960px){label {font-size: 1.2rem;}}
    @media(max-width: 500px){label {max-width: none; line-height: 1.32rem;}}
}

.find-store-cta{padding: 30px 44px; border-bottom: none;
    @media(max-width: 1280px){padding: 30px 20px;}
    @media(max-width:374px){padding: 20px 20px;}
}
 