@use "_mixins";
@use "_extends";
@use "_colors";
@use "../components/_tabs";
@use "../components/_searchDestinationCard";
@use "../components/_ourPicks";
@use "../components/_searchInspirationList";
@use "../components/_filterMenu";

#search{position:fixed; top:0; right:0; left:0; height: 100vh; height: 100dvh; overflow-x: hidden; background-color:white; overflow-y: auto; z-index: 10100000000;  padding-bottom: 60px; pointer-events: none; transform: translateY(-100vh); transform: translateY(-100dvh); transition: transform 0.3s ease-out; scroll-behavior: smooth;
    &::-webkit-scrollbar {width: 5px;}       
    &::-webkit-scrollbar-track {background-color: #f7f7f7;;}       
    &::-webkit-scrollbar-thumb {background-color: var(--color-off-black); border-radius: 10px;}
    scrollbar-width: thin; //ff
    scrollbar-color: var(--color-off-black) white; //ff
    #close-search{position: absolute; top:46px; right:46px; z-index: 4; cursor: pointer;
        &.lower-z{z-index: -1;}
        @include mixins.mq(860){top:30px; right:30px;}
    }
    &.option-active{
        #close-search{z-index: 2;}
    }
    &.hide-popup{
        .dates{
            .more-detail-popup{opacity: 0!important;}
        }
    }
 
    .container{
        &.bump-down{padding-top: 201px; z-index: 3;
            &.unavailable{ padding-top: 98px; }
        }
        &:nth-child(3), &:nth-child(4){z-index: 2;
            &.higher-z{z-index: 4;}
        }        
        .search-bar{display:flex; height:102px; position: relative; opacity: 0; transform: translateY(4px); pointer-events: none; transition: opacity 0.3s 0.6s, transform 0.3s 0.6s ease-out; 
            .column{border-top:1px solid #ccc; border-bottom:1px solid #ccc;  padding: 0 19px; cursor: pointer; display:flex; flex: 1; justify-content:center; flex-direction:column; position:relative; outline: 0px solid transparent; background-color:var(--color-yellow-light); transition: flex 0.3s, background-color 0.3s, padding 0.3s, opacity 0.3s, max-width 0.3s, outline 0.1s ease-out;
                label{@include mixins.font(1.2, 1.4, 1.63rem, 400, 0.05rem, #666); text-transform:uppercase; display:block; margin-bottom:3px; transition: color 0.3s; cursor: pointer;}
                p{@include mixins.font(1.4, 1.6, 1.9rem, 400, 0, #999); white-space:nowrap;}              
                &.where{flex: 0 1 350px; justify-content:space-between; border-left:1px solid #ccc; padding:0; flex-direction:row; align-items:center; border-radius: 8px 0 0 8px;
                    &>div{position:relative; height:100%;
                        &:first-child{width:100%;
                            label{position:absolute; top:30px; left:21px; margin-bottom:0; z-index:1; pointer-events:none; white-space: nowrap;
                                @include mixins.mq(700){top:22px;}
                                @media(max-height:650px) and (max-width:700px){top:13px;}                              
                            }                       
                            input{border:none; width:100%; @extend %pin; z-index:2; height: 100%; padding:0; padding-left:19px; padding-top:21px; background-color:transparent; @include mixins.font(1.6, 1.6, 2.17rem, 400, 0, black); cursor:pointer;
                                @include mixins.placeholder(){@include mixins.font(1.2, 1.6, 2.17rem, 400, 0.05rem, colors.$grey6);}                              
                                @include mixins.mq(700){padding-inline: 45px;}
                                @include mixins.mq(1010){padding-inline: 19px;}     
                                @include mixins.mq(350){
                                    @include mixins.placeholder(){font-size: 1rem;}
                                }  
                            }
                        }   
                        &#mag-pos-where{display:flex; align-items:center; padding-right: 39px; opacity: 0; transform: translateY(4px); pointer-events: none; transition: transform 0.3s, opacity 0.3s;
                            img{width:24px;}
                            @include mixins.mq(1300){padding-right: 19px;}
                        }
                    }
                   
                    &.active{flex: 0 1 560px; border-left:1px solid white;
                        &>div{
                            &#mag-pos-where{opacity: 1; transform: translateY(0); pointer-events: all;}
                        }
                        @include mixins.mq(1300){flex: 0 1 600px;}
                        @include mixins.mq(900){flex: 0 1 500px;}
                        @include mixins.mq(700){flex: 0 0 80px;}     
                        @media(max-height:650px) and (max-width:700px){flex: 0 0 65px;}    
                    }  
                    @include mixins.mq(1300){flex: 0 1 460px;}
                    @include mixins.mq(900){flex: 0 1 360px;}
                    @include mixins.mq(700){flex: 0 0 80px; position: relative; border-radius: 8px 8px 0 0; border-top:1px solid #ccc;
                        &:after{content: ""; position: absolute;  top: auto;  bottom: 0; left: 0; right: 0; background-color: #E5E5E5; z-index: 1; width: auto; height: 1px;}
                    }  
                                   
                }
                &.dates{position: relative;}
                &.active{background-color:white; outline: 7px solid white; border-color: transparent; box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.1); z-index: 2; border-left-color:white; border-right-color: white;
                    label{color:var(--color-off-black);}
                    p{color:var(--color-off-black);}
                    &:before, &:after{content: none !important;}
                    &:not(&.where){
                        @include mixins.mq(700){outline-width: 0; border-left-color: #D3D4D5; border-right-color: #D3D4D5;}
                    }
                }  
                &.from{border-right:1px solid #ccc; border-radius: 0 8px 8px 0;
                    &.active{border-right:1px solid white;}
                    @include mixins.mq(700){border-radius: 0 0 8px 8px;}
                }   
                &:not(&:first-of-type){
                    &:before{content: ""; position:absolute; top:30px; bottom:30px; left: -1px; width:1px; background-color: colors.$grey8; z-index:1;
                        @include mixins.mq(700){width: auto; top: auto !important; bottom: 0!important; height: 1px; left: 0; right: 0;}
                    }                   
                }
                &.has-value{background-color: white; 
                    p{color:var(--color-off-black);}                
                    &:before{top: 0 !important; bottom: 0 !important;
                        @include mixins.mq(700){top: auto !important;} 
                    }  
                }
                .more-detail-popup{position: absolute !important; bottom:100px; left:0; z-index: 7; background-color: white; width: 241px; height: auto !important; padding: 15px 18px; box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.2); border-radius: 8px; opacity: 0; transform: translateY(4px); pointer-events: none; transition: 0.3s; cursor: default;
                    label{@include mixins.font(1.2, 1.4, 1.96rem, 700, 0, black); text-transform: none;}
                    p{@include mixins.font(1.2, 1.4, 1.68rem, 400, 0, var(--color-off-black));}
                    &:after{content:""; position: absolute; height: 16px; width: 16px; bottom:-8px; left:28px; transform: rotate(45deg); background-color: white; transition: 0.2s;}
                    &.active{opacity: 1; transform: translateY(0); pointer-events: all;}
                    @include mixins.mq(700){bottom: 75px; left: 26px;}
                    @include mixins.mq(800){left: 10px;}
                    @include mixins.mq(800){left: 0;}
                    @media(max-height:650px){bottom: 69px;}
                }  
                
                &.has-value{ 
                    &+.column{
                        &:before{top:0 !important; bottom:0 !important;}
                    }
                }
               
                @include mixins.mq(1300){
                    &:nth-child(5){
                        &:after{content: ""; position: absolute; top: 30px; bottom: 30px; right: 0px; width: 1px; background-color: #E5E5E5; z-index: 1;}
                    }
                    &:last-of-type{max-width: 80px; overflow: hidden;
                        &+#mag-pos-searching{max-width: 0; overflow: hidden;}
                    }
                   
                }
                @include mixins.mq(1180){
                    &:nth-child(4){
                        &:after{content: ""; position: absolute; top: 30px; bottom: 30px; right: 0px; width: 1px; background-color: #E5E5E5; z-index: 1;}
                    }                    
                    &:nth-child(5){max-width: 80px; overflow: hidden;
                        &:after{content: "";}
                    }
                    &:last-of-type{padding: 0; max-width: 0;}
                }

                @include mixins.mq(1045){
                    &:nth-child(3){
                        &:after{content: ""; position: absolute; top: 30px; bottom: 30px; right: 0px; width: 1px; background-color: #E5E5E5; z-index: 1;}
                    }                    
                    // &:nth-child(4){max-width:0; overflow: hidden; padding: 0;
                    //     &:after{content: "";}
                    // }
                    &:nth-child(5){padding: 0; max-width: 0;}
                }
              
                @include mixins.mq(700){border-top: none; border-bottom: none;
                    &:last-of-type{max-width: none; height: 0; flex:none; padding: 0 19px;                
                        &:before{height: 0 !important;} 
                        &+#mag-pos-searching{max-width: none; overflow: hidden;}                    
                    }
                    &:nth-child(4), &:nth-child(5){width: auto; height: 0; height: 0; flex:none; overflow: hidden;
                        &:before{height: 0 !important;} 
                        &:after{content: none;}                      
                    }
                    &:nth-child(5){ padding: 0 19px; max-width: none;
                        &:after{content: none;}  
                    }
                    &:nth-child(3){
                        &:after{content: none;}
                    }
                }  
                @media(min-width:700px) and (max-width:1300px){max-width: 500px;} 
                @media(min-width:700px) and (max-width:920px){padding: 0 12px;}                
            }
            #mag-pos-searching{overflow: hidden; display:flex; align-items:center; justify-content:flex-end; flex: 0 0 0px; opacity: 0; transform: translateY(4px); transition: transform 0.3s, flex 0.3s, opacity 0.3s, background-color 0.3s; background-color: white; cursor: default; 
                .mag-img{height: 40px; width: 40px; background-color: transparent; transition: 0.2s; border-radius: 100%; display: grid; place-items: center;
                    img{width:17px;}
                    @include mixins.mq(700){height: 20px; width: 20px;}
                }
                img, small{opacity: 0.4;}
                &.active{flex: 0 0 60px; opacity: 1; transform: translateY(0);
                    &.valid{cursor: pointer;
                        .mag-img{background-color: var(--color-yellow);}
                        img, small{opacity: 1;}
                    }
                }
                small{margin-left: 7px; font-size: 1.6rem; font-weight: 700; color:var(--color-off-black);
                    @include mixins.mqmin(700){display: none;}
                }
                @include mixins.mq(700){height: 0; justify-content: center;
                    &.active{flex: 0 0 0 !important; border-radius: 8px; transform: translateY(20px); position: relative;
                        &.valid{background-color: var(--color-yellow);}
                        &:after{content: ""; position: absolute; top: -22px; left: -1px; right: -1px; height: 20px; border-top: 1px solid #E5E5E5;}
                    }  
                }
            }

            .plus-icon{width: 155px; transition: 0.3s; pointer-events: none; position: absolute; top:0; right:0; bottom:0; z-index: 6; background: linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, #FAFAFA 81%);
                .icon{position: relative; pointer-events: all; z-index: 2; width: 36px; height: 36px; cursor: pointer; border-radius: 100%; border: 1px solid #D3D4D5; background: url("../../images/icons/plus.svg") center no-repeat white; background-size: 12px; position: absolute; right: -18px; top: 50%; transform: translateY(-50%);}
                @include mixins.mqmin(1300){display: none;}
                @include mixins.mq(700){width: auto; right: 1px; left:1px; top:auto; bottom:1px; height: 93px; background: linear-gradient(180deg, rgb(216 23 23 / 0%) 0%, #FAFAFA 81%);
                    .icon{height: 44px; width: 44px; background-size: 15px; right: auto; left:50%; transform: translateX(-50%); top:auto; bottom:-22px;}
                }
                @media(min-width:700px) and (max-width:1010px){
                    //width: 25px;
                }              
            }

            &.active{           
                .column{                   
                    @include mixins.mq(700){height: auto !important; flex: 0 0 80px !important;
                        &::before{height: 1px !important;}
                    }                    
                    @media(max-height:650px) and (max-width:700px) {flex: 0 0 65px !important;}             
                }
                &:after{height: 0;} 
                #mag-pos-searching{//outline: 1px solid #D3D4D5;
                    &.active{flex: 0 0 40px !important;
                        @include mixins.mq(700){flex: 0 0 40px !important;}
                        @media(min-width:700px) and (max-width:800px){flex: 0 0 40px !important;}
                    }
                }
                
                .plus-icon{left:0; right:auto; background: linear-gradient(270deg, rgba(250, 250, 250, 0) 0%, #FAFAFA 81%); 
                    .icon{right:auto; left:-18px;}           
                    @include mixins.mq(700){display: none;}
                }

                &:has(.where.active){
                    .plus-icon{top:-7px; bottom:-7px; left:-7px;}
                }                
                
                @media(min-width:700px) and (max-width:1300px){ 
                    .column{
                        &:nth-child(1){max-width: 255px;
                            //&>div{opacity: 0;}
                        }
                        &:last-of-type{max-width: 500px;}
                    }
                    #mag-pos-searching{max-width: 100px !important;}
                }
                @media(min-width:700px) and (max-width:1180px){ 
                    .column{
                        //&:nth-child(3){max-width: 0; padding:0;}
                        &:nth-child(1){max-width:0; padding: 0;
                            &>div{opacity: 0;}
                        }
                        &:nth-child(5){max-width: 500px; padding: 0 19px;}
                        &:last-of-type{padding: 19px;}
                    }
                }
                @media(min-width:700px) and (max-width:920px){ 
                    .column{
                        &:nth-child(2){max-width: 0; padding:0;}

                        &.dates{
                            .more-detail-popup{opacity: 0;}
                        }
                    }
                }

                @media(min-width:700px) and (max-width:800px){ 
                    .column{
                        &:nth-child(3){max-width: 110px;}                        
                        &:nth-child(5){max-width: 135px;}    
                    }
                    #mag-pos-searching{max-width: 160px !important;}     
                }
                
            }   

            &.search-unavailable{
                .column{
                    &.where{
                        &.active{
                            @media (min-width: 700px) and (max-width: 990px) {flex: 0 1 380px;}
                        }
                    }
                }
                
                #search-unavailable{top:-1px; bottom:-1px;
                    #search-unavailable-message{background-color: #E5E5E5;}
                    #spacer{flex:0 1 349px; transition: 0.3s ease-out;
                        @media (max-width: 1300px) {flex: 0 1 459px;}
                        @media (max-width: 900px) {flex: 0 1 359px;}
                        @media (max-width: 700px) {flex:none;}
                    }  
                    @media (max-width: 700px) {top:79px; bottom:0;}
                }                               

                @media (min-width: 700px) and (max-width: 1050px) {
                    #mag-pos-searching{display: none;}
                }

                @media (min-width: 700px) and (max-width: 830px) {
                    .column{
                        &:nth-child(4){display: none;} 
                    }
                }                            
            }           

            @include mixins.mq(700){flex-direction: column; height: auto; position: relative;
                .column{flex: 0 0 80px;}
                &.active{padding-bottom: 20px;}
            }

            @include mixins.mq(700){border: none; 
                .column{border-left: 1px solid #D3D4D5; border-right: 1px solid #D3D4D5;}
            }

            @media(max-height:650px) and (max-width:700px){
                .column{flex: 0 0 65px;}
            }           
        }
        #search-popup{position: absolute; top:303px; border-radius: 12px; margin-top: 16px; opacity: 0; pointer-events: none; transform: translateY(4px); transition: transform 0.3s, height 0.3s, width 0.3s, background-color 0.3s;
            // &:has(ul li, .dates, .pax){box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.17); background-color: white;
            //     @include mq(700){background-color: transparent; box-shadow: none;}
            // }
           
            @include mixins.mq(700){background-color: transparent; box-shadow: none;}
            &>div{border-radius: 12px; box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.17); background-color: white;
                ul{
                    li{cursor: pointer;
                        label{cursor: pointer;}
                    }
                }
                &.active{pointer-events: all;}

                @include mixins.mq(700){position: absolute; box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.17);
                    //&:has(ul li), &.dates, &.pax{background-color: white;}
                    //&:has(ul li){box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.17);}
                }
            }
            h4, .h4{@include mixins.font(1.4, 1.4, 1.9rem, 700, 0.04rem, var(--color-off-black)); text-transform: uppercase; text-align: center;}
            .done{@include mixins.font(1.4, 1.4, 1.4rem, 600, 0, var(--color-off-black)); background-color: var(--color-yellow); border-radius: 8px; width: 100%; height: 42px; border:none; 
                &.a-button{display: inline-block; text-align: center; line-height: 42px;}
            }
            .where{padding:20px; max-height: 400px; overflow-y: auto; background-color: transparent; padding:0; max-width: 486px; min-width: 240px;
                #locations{gap:0;
                    li{background-color: white; padding: 20px 20px 0;
                        &:last-child{padding-bottom: 20px;}
                        @include mixins.mq(700){background-color: white;}
                    }
                }
                ul{display: grid; gap:20px;
                    li{display: flex; align-items: center; gap:22px;
                        &>a{display: flex; align-items: center; gap:22px;
                            &>div{&:first-child{flex: 0 0 40px; height: 40px; border-radius: 2px; display: flex; align-items: center; justify-content: center; background-color: #f7f7f7;
                                &.location{background-color: #E6F6FA;}
                            }
                            label{display: block; @include mixins.font(1.6, 1.6, 120%, 400, 0, var(--color-off-black)); margin:2px 0 3px;}
                            span{@include mixins.font(1.2, 1.2, 1.63rem, 400, 0.04rem, #6C7175); text-transform: uppercase;}}
                        }
                        &>div{
                            &:first-child{flex: 0 0 40px; height: 40px; border-radius: 2px; display: flex; align-items: center; justify-content: center; background-color: #f7f7f7;
                                &.location{background-color: #E6F6FA;}
                            }
                            label{display: block; @include mixins.font(1.6, 1.6, 1.6rem, 400, 0, var(--color-off-black)); margin:2px 0 3px;}
                            span{@include mixins.font(1.2, 1.2, 1.63rem, 400, 0.04rem, #6C7175); text-transform: uppercase;}
                        }
                    }
                }
                &::-webkit-scrollbar {width: 5px;}       
                &::-webkit-scrollbar-track {background-color: transparent;}       
                &::-webkit-scrollbar-thumb {background-color: var(--color-off-black); border-radius: 10px;}
                scrollbar-width: thin; //ff
                scrollbar-color: var(--color-off-black) transparent; //ff
                @include mixins.mq(700){max-width: none; border-top: 1px solid #f3f3f3;}
            }
            .dates{padding:20px; width:290px;
                .duration{display: flex; text-align: center;  justify-content: center; gap:21px; margin: 20px 0; border-bottom: 1px solid colors.$grey8; padding-bottom: 20px;
                    button{padding: 0; flex: 0 0 36px; height: 36px; border-color: colors.$grey8; margin-top: 7px; touch-action: manipulation;
                        &:disabled{opacity: 0.4; pointer-events: none;}
                    }
                    label{@include mixins.font(1.4, 1.4, 1.4rem, 400, 0, colors.$grey4);}
                    p{@include mixins.font(1.6, 1.6, 1.6rem, 400, 0, var(--color-off-black)); margin: 8px 0 0;}
                    @media(max-height:700px){padding-bottom: 30px; margin: 30px 0;}
                    @media(max-height:630px){padding-bottom: 20px; margin: 20px 0;}
                }
                @include mixins.mq(374){padding: 30px 20px 20px;}       
            }
           
            .pax{padding: 20px; width:355px;
                &>div{height: 100%;}
                h4{margin-bottom: 20px;}
                .more-rooms{
                    .close-icon {position: absolute; top: 20px; right: 30px; font-size:1.6rem; cursor:pointer;}
                    p{@include mixins.font(1.6, 1.6, 1.6rem, 400, 0, var(--color-off-black)); line-height: 140%;}
                }
               .pax-people{display: grid; gap:10px; border-bottom: 1px solid colors.$grey8; padding-bottom: 20px; height: 100%; 

                    .child-ages-message{@include mixins.font(1.4, 1.4, 1.4rem, 700, 0, var(--color-off-black)); margin-top: 3px;}

                    .pax-children{display: flex; flex-direction: column; gap:10px; max-height: 22.2dvh; max-height: 22.2vh;  overflow-y: auto; padding-right: 5px;
                        &::-webkit-scrollbar {width: 5px;}       
                        &::-webkit-scrollbar-track {background-color: white;}       
                        &::-webkit-scrollbar-thumb {background-color: var(--color-off-black); border-radius: 10px;}
                        scrollbar-width: thin; //ff
                        scrollbar-color: var(--color-off-black) white; //ff
                    }
                    
                    .pax-row{display: flex; justify-content: space-between; align-items: center;                        
                        .pax-person{
                            label{@include mixins.font(1.6, 1.6, 1.6rem, 400, 0, var(--color-off-black));}
                            span{@include mixins.font(1.1, 1.1, 1.1rem, 400, 0, colors.$grey4); display: block; margin-top: 2px;}
                        }
                        .pax-controls{display: flex; gap:16px; align-items: center;
                            select{height: 36px; border-radius: 2px; border: 1px solid colors.$grey8; padding: 0 15px; width: 112px; text-align: left; font-size: 1.6rem; cursor: pointer; background: url("../../images/icons/chevron-down-black.svg") center right 15px no-repeat white; background-size: 12px;
                                option{color: var(--color-off-black);
                                    &:first-child{color:#8E8C9A;}
                                }
                            }
                            span{@include mixins.font(1.6, 1.6, 1.6rem, 400, 0, var(--color-off-black));}
                            button{min-width: 36px; height: 36px; border-color: colors.$grey8; padding: 0;
                                &:disabled{opacity: 0.4; pointer-events: none;}
                            }
                        }
                    }
               }
               .add-more{text-align: center; margin: 12px 0;
                    label{@include mixins.font(1.3, 1.3, 1.3rem, 400, 0, #006F84); cursor: pointer;}
               }
            }

            .options{max-height: 325px; overflow-y: auto; width:230px;
                &::-webkit-scrollbar {width: 5px;}       
                &::-webkit-scrollbar-track {background-color: transparent; border-radius: 10px;}       
                &::-webkit-scrollbar-thumb {background-color: var(--color-off-black); border-radius: 10px;}
                scrollbar-width: thin; //ff
                scrollbar-color: var(--color-off-black) white; //ff
                ul{
                    li{@include mixins.font(1.6, 1.6, 1.6rem, 400, 0, var(--color-off-black)); padding: 24px 20px;
                        &:not(&:last-child){border-bottom: 1px solid colors.$grey8;}
                    }
                }
                @include mixins.mq(700){max-height: 85dvh; max-height: 85vh;}
            }
            &.active{opacity: 1; transform: translateY(0); pointer-events: all;}
            @include mixins.mq(700){max-width: 100%; width: calc(100% - 142px) !important; right: 77px; top:192px; left:77px; transform: translate(-6px, 0px) !important;}
            @include mixins.mq(860){right: 42px; left:42px; //width: calc(100% - 72px) !important;
            
            }
            @include mixins.mq(700){position: fixed; background-color: transparent; border-radius: 0; right: 0; left:0; bottom:0; top:0; width: 100vw !important; height:100% !important; margin-top: 0px; transform: translate(0px, 0px) !important; box-shadow: none; // position: fixed; top:auto; 
                .where{top:197px; left:35px; right: 35px; border-radius: 0 0 12px 12px; max-height: calc(100% - 210px);
                    @media(max-height:700px){top:152px;}
                }
                &>div{
                    &:not(.where){width: 100% !important; top: auto; bottom: 0; border-radius: 12px 12px 0 0;}
                }
                &.option-active{background-color: rgba(black, 0.4);} 
            }
            @include mixins.mq(620){
                .where{left:23px; right: 23px;}
            }  
            @include mixins.mq(374){
                .where{left:13px; right: 13px;}
            }           
            @media (max-width: 700px) and (max-height: 700px){
                .where{max-height: calc(100% - 180px);}
            }
        }  
        .loading-spinner{display: flex; align-items: center; justify-content: center;
            img{opacity: 0; pointer-events:none; z-index: -999; height: 0; overflow: hidden; transition: opacity 0.3s 0s, height 0.3s 0s ease-out;}
            h2, p{display: none;}
            &.active{margin-top: 76px;
                img{opacity: 1; pointer-events:all; z-index: 0; height: auto; transition: opacity 0.3s 0.15s, 0.15s, height 0.3s 0.15s ease-out; animation: loading 2s linear infinite;}
            }
            @include mixins.mq(700){
                &.detailed-results{position: fixed; top:0; right:0; bottom:0; left:0; background-color: white; z-index: 6; padding: 0 30px; flex-direction: column;
                    h2, p{display:block;}
                    h2{@include mixins.font(2.8, 2.8, 130%, 400, 0, black, "Magazine Grotesque"); text-align: center; margin-bottom: 24px; max-width: 240px;}
                    p{@include mixins.font(1.6, 1.6, 130%, 400, 0, black); text-align: center; margin-top: 24px;}
                    margin-top: 0;
                        img{opacity: 1; height: auto;}
                    
                }
            }
        }  
        @keyframes loading {           
            100%{transform: translateY(0) rotate(360deg);}
        }  
        .tabs{
            &.search{left: 0; transform: translateX(0); width: 100%; padding-top: 13px !important;
                li{font-size: 1.6rem; gap:3px;}
                @include mixins.mqmin(1420){justify-content: center;}
            }
        }
        .tab-contents{position: relative; margin-top: 40px;
            .tab-content{display: block; height: 0; overflow: hidden; opacity: 0; transform: translateY(4px); pointer-events:none; z-index: -999; transition: opacity 0.3s 0s, transform 0.3s 0s, height 0s 0s ease-out;
                section{
                    &:not(&:last-child){margin-bottom: 70px;}
                    .search-grid{display: grid; grid-template-columns: repeat(auto-fill, minmax(230px, 1fr)); gap:40px 27px;               
                        &.three{grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); gap:18px;} 
                        @include mixins.mq(900){gap: 30px 20px;}
                        @include mixins.mq(545){grid-template-columns: 1fr;
                            a{display: flex; gap:20px; box-shadow: none; padding-bottom: 25px;
                                .our-pick-card__image{order:2;
                                    .tag{display: none;}
                                }
                                .our-pick-card__content{flex:1; padding: 0;
                                    &>label{font-size: 1.6rem; margin: 10px 0 5px;}
                                }
                            }
                        }                      
                        @include mixins.mq(374){gap: 20px;
                            a{padding-bottom: 8px;}
                        }
                        @include mixins.mq(500){
                            a{gap:10px; flex-direction: column;
                                .our-pick-card__image{order:inherit;}
                            }
                        }
                    }
                    @include mixins.mq(700){
                        &:not(&:last-child){margin-bottom: 50px; margin-top: 0; padding-top: 0;}
                    }

                    .filter-menu{margin: 0 0 30px !important; padding-top: 0; top: 0 !important;
                        @include mixins.mq(690){margin-left: - 42px !important; width: calc(100% + 84px);
                            .filter-menu__type{border-right: none;}
                        }
                        @include mixins.mq(374){margin-left: - 20px !important; width: calc(100% + 40px);}
                    }
                    .search-inspiration-list{
                        a{
                            &>img{margin-right: 0;}
                        }
                    }
                }

                .filter-menu{margin-bottom: 30px;}
                &.active{opacity: 1; transform: none; padding-bottom: 40px; pointer-events:all; z-index: 0; height: auto; transition: opacity 0.3s 0.15s, transform 0.3s 0.15s, height 0s 0s ease-out;}
                .search-cards-header{ font-size: clamp(2.4rem, 2.8vw, 2.8rem); text-transform: none; font-family: "Magazine Grotesque"; margin-bottom: 27px;
                    a{justify-content: flex-end; font-size: clamp(1.4rem, 1.6vw, 1.6rem); color: var(--color-off-black); text-decoration: underline;
                        i{font-style: normal;}
                        img{width: 7px; transition: translateY(-1px);}
                        @include mixins.mq(545){
                            i{display: none;}
                        }                       
                    }
                    @include mixins.mq(545){margin-bottom: 20px;}
                    &+.our-picks{padding-top: 0; margin-top: 0;

                    }
                }
                .search-destinations-cards{
                    .swiper{ 
                        .swiper-wrapper{
                            .swiper-slide{                                
                                @include mixins.mq(545){padding: 20px 25px;
                                    label{font-size: clamp(1.8rem, 2.2vw, 2.2rem); line-height: 1.1;}
                                }

                                @include mixins.mq(374){width: 100%;}                                
                            }
                        }
                    }
                    &.has-carousel{padding: 0;}
                } 
                @include mixins.mq(960){overflow: visible;}               
            }           
        }
        .quick-search{margin-top: 23px;
            .tabs-swiper-search{border-bottom: 4px solid var(--color-yellow); padding-bottom: 0;
                &:after{content: none;}
            }
            @include mixins.mq(700){margin-top: 40px;}      
        }
        .detailed-search    {opacity: 0; height: 0; overflow: hidden; transform: translateY(4px); pointer-events:none; z-index: -999; transition: opacity 0.3s 0s, transform 0.3s 0s, height 0s 0s ease-out;
            &.active{opacity: 1; transform: none; overflow: visible; pointer-events:all; z-index: 0; height: auto; transition: opacity 0.3s 0.15s, transform 0.3s 0.15s, height 0s 0s ease-out;}
        }
        .detailed-search, .quick-search{
            .detail-result-heading{@include mixins.font(2.4, 2.8, 130%, 400, 0, var(--color-off-black)); text-align: center; padding:35px 0 20px; font-family:"Magazine Grotesque";
                @include mixins.mq(500){text-transform: uppercase; font-weight: 700; padding: 35px 0 20px;}
            }
            .results-grid{display:grid; grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)); gap: 43px 36px; align-items: flex-start; margin-top:30px;
                @include mixins.mq(765){grid-template-columns: 1fr 1fr; gap: 22px;}
                @include mixins.mq(500){grid-template-columns: 1fr;}
            }
            .filter-menu{top:-1px;}
            .no-results{margin-top: 40px;
                p{margin-bottom: 25px; line-height: 130%;}
                .search-grid{display: grid; grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); gap:18px;}
            }
        }

        .our-pick-card{width: auto; height: fit-content;
            .our-pick-card__image{height: 233px;}
            .our-pick-card__content{padding: 14px 0px 15px;
                label{font-size: clamp(1.8rem, 2.2vw, 2.2rem); text-transform: none; margin: 6px 0 3px; line-height: 1.2; }
                span{font-size: clamp(1.4rem, 1.6vw, 1.6rem);}
                p{font-size: clamp(1.4rem, 1.6vw, 1.6rem); line-height: 1.4;
                    &.based-on{font-size: clamp(1.2rem, 1.2vw, 1.2rem); color: #666;}
                }
                small{font-size: clamp(1.6rem, 1.8vw, 1.8rem);}
                ul{
                    li{font-size: clamp(1.2rem, 1.4vw, 1.4rem);}
                }
                .offer{
                    label{font-size: clamp(1.2rem, 1.4vw, 1.4rem); font-weight: 700; font-family: "Magazine Grotesque", sans-serif; margin: 3px 0 11px;}
                    small{font-family: "Magazine Grotesque", sans-serif;  margin: 3px 0 0;}
                }
                .occasion-info{display: flex; gap:30px;
                    @include mixins.mq(700){flex-direction: column; align-items: flex-start; gap: 10px;}
                }
                @include mixins.mq(545){
                    label{margin: 14px 0 14px;}
                    span{border-bottom: 0; padding-bottom: 0;}
                }
            }
        } 

        @include mixins.mq(1300){padding-inline: 30px;}
        
        @include mixins.mq(860){padding-inline: 42px;}

        @include mixins.mq(620){padding-inline: 30px;}
        @include mixins.mq(374){padding-inline: 20px;}
    } 
    
    &.active{pointer-events: all; transform:none;
        .container{
            .search-bar{opacity: 1; transform: translateY(0); pointer-events: all; transition: opacity 0.3s 0.3s, transform 0.3s 0.3s ease-out;}
            @include mixins.mq(700){
                &.visible{overflow: hidden !important;
                    &.bump-down{padding-bottom: 22px;}
                }
            }           
            
        }
    }


    &.hide-popup{
        .container{
            .search-bar{
                &.search-unavailable{
                    #search-unavailable{
                        #spacer{flex: 0 1 567px;
                            @include mixins.mq(1300){flex: 0 1 506px;}
                            @media (max-width: 990px) {flex: 0 1 387px;}
                            @include mixins.mq(700){flex: none;}
                        }  
                        @include mixins.mq(700){top:87px;}
                    }                
                }
            }
        }
    }


    @include mixins.mq(700){padding-bottom: 0;}
}

.vc-nav-item.is-active {
    background: var(--accent-800);
}

#calendar{margin-bottom: 5px;
    .vc-container{border:none; width: 100%;
        .vc-pane-layout{
            .vc-pane{
                .vc-header{padding: 0; margin: 0 0 20px;
                    .vc-title{@include mixins.font(1.4, 1.4, 1.4rem, 700, 0, black); text-transform: uppercase;}
                    @media(max-height:630px){margin: 0 0 20px;}
                }
                .vc-weeks{padding: 0;
                    .vc-weekday{padding: 0; @include mixins.font(1.4, 1.4, 2.17rem, 400, 0, #8E8C9A); margin-bottom: 4px;}
                    .vc-day{height: 36px; width: 36px;
                        span{@include mixins.font(1.5, 1.5, 0px, 600, 0, black); font-weight: 600 !important; height: 36px; width: 100%; border-radius: 0 !important; //width: 48px; 
                            @include mixins.mq(700){width: 100%;}
                            &.is-disabled{opacity: 0.3; font-weight: 400 !important;}                                       
                        }
                        .vc-highlights{
                            .vc-day-layer{
                                .vc-highlight{width: 36px; height: 36px; border-radius: 0 !important;                                    
                                    @include mixins.mq(700){width: 100%;}
                                }
                            }
                            &+span{cursor: default;
                                &:hover{background-color: transparent; }
                            }                                        
                        }  
                        &.is-not-in-month{opacity: 0;}
                        @media(max-height:700px){height: 36px;}  
                        @include mixins.mq(700){width: 100%;}                                
                    }
                }
            }
        }
        .vc-arrows-container{padding: 0; top: -28px; padding: 19px 6px 19px 2px;}
    }
}

.vc-container{
    --blue-200:  var(--color-yellow-mid); //within selected range
    --blue-600: var(--color-yellow); //first/last date
    --blue-700: var(--color-yellow); //border when selecting
    --blue-900: var(--color-off-black); //text
}
.vc-day-layer .vc-highlight{--white: var(--color-yellow);}

.column-enter-active, .column-leave-active {transition: 0.2s ease-in;}
.column-enter-from, .column-leave-to{opacity: 0; transform: translateX(10%);
    @include mixins.mq(700){transform: translateY(100%); opacity: 1;} 
} 