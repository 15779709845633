@use "../components/_mixins";
@use "../components/_extends";
@use "../components/_colors";

.get-in-touch{padding-bottom: 80px;

    &>.container{
        &:nth-of-type(1){padding: 0 25px;
            @media (max-width: 860px) {padding: 0 42px;}
            @media (max-width: 620px) {padding: 0 30px;}
            @media (max-width: 374px) {padding: 0 20px;}
        }
    }
   
    .get-in-touch-tabs{display: flex; gap:30px; justify-content: center; align-items: center; min-height: 104px; max-height:192px; height: 41vw; margin-bottom: 100px; overflow-x: auto; background-color: var(--color-grey-10); border-radius: 8px; padding: 0 20px;
        li{font-size: clamp(2.4rem, 2.6vw, 2.6rem); flex-shrink: 0; line-height: 0.8; font-family: "Magazine Grotesque", serif; text-transform: uppercase; display: inline-flex; align-items: center; cursor: pointer; color:var(--color-off-black); opacity: 0.6;
            &.active{border-bottom: 1px solid var(--color-off-black); opacity: 1; cursor: default;}
        }
        a { color: unset; }
        @media(max-width:1000px){margin-bottom: 60px;}
        @media(max-width:620px){margin-bottom: 40px; justify-content: flex-start; gap:18px; margin-bottom: 40px; margin-left: -30px; width: calc(100% + 60px); padding-left: 30px; padding-right: 30px;}
        @media(max-width:374px){margin-left: -20px; width: calc(100% + 40px); padding-left: 20px; padding-right: 20px; margin-bottom: 30px;}
    }
    
    .content-column{flex:1; text-align: center;
        h2{display: flex; gap: 11px; align-items: center; justify-content: center; flex-direction: column; text-align: center; letter-spacing: -0.03rem; font-size: clamp(1.8rem, 2.2vw, 2.2rem); margin-bottom: 12px; color: var(--color-off-black);
            img{width: 96px;}
        }
        ul.tick-list{display: grid; gap: 12px 0;
            li{font-size:clamp(1.4rem, 1.6vw, 1.6rem); color:var(--color-off-black); line-height: 1.4;}        
        }
        .content-footer{
            a{display: flex; align-items: center; gap:15px; justify-content: center;}
            a, span.fake-link{text-decoration: underline; color:var(--color-off-black);
                &.tel{font-size: clamp(2.4rem, 2.8vw, 2.8rem); margin-top: 16px; margin-bottom: 18px; text-align: center; font-weight: 700; font-family: "Magazine Grotesque", sans-serif;}
            }
            .opening-hours{
                .toggleOpeningHours{font-size:clamp(1.4rem, 1.6vw, 1.6rem);
                    span{display:flex; justify-content: center;}                  
                }
                .hours-reveal{
                    p{
                        &:first-of-type{padding-top: 3px !important;}
                    }
                }
            }  
            p.boldbit{font-size:clamp(1.4rem, 1.4vw, 1.4rem); line-height: 1.4; font-weight: 400; color:var(--color-off-black); margin-top: 14px;
                span.fake-link{font-weight: 400; text-decoration: underline; cursor: pointer; 
                    img{width: 11px;}                   
                }
                &:not(.toggleOpeningHours){
                    span{
                        &.fake-link{
                            display: inline-block;
                            height: auto;
                            line-height: normal;
                            padding: 9px 20px;
                            background-color: var(--color-yellow);
                            cursor: pointer;
                            border-radius: 8px;
                            color: var(--color-off-black);
                            font-size: 16px;
                            margin-top: 19px;
                            border: 1px solid transparent;
                            font-family: "Magazine Grotesque", sans-serif;
                            text-decoration: none;
                            @media(max-width:460px){width: 100%;}
                        }
                    }
                }
            }
            @media(max-width:620px){
                p{font-size: 1.4rem;}
            }
        }
    }

    .content-enquiries-tab{
        &>div{display: flex; gap:80px;
            @media(max-width:1080px){display: grid; gap:42px;}
        }
        .appointment-methods{padding: 40px 40px; margin:30px 0 43px;
            @media(max-width:1080px){padding: 40px 24px;}
            @media(max-width:1000px){padding: 40px 24px;}
            @media(max-width:620px){padding: 35px 24px;}
            @media(max-width:374px){padding: 35px 24px;}
        }
    }

    .content-help-tab{display: grid; gap:40px; padding-bottom: 80px;
        &>div{display: flex; gap:30px;
            .content-column{flex:1;
                &:first-of-type{
                    h2{font-size: clamp(2.4rem, 2.8vw, 2.8rem); line-height: 1.1; letter-spacing: -0.1rem;}
                }
                p{font-size: clamp(1.6rem, 1.6vw, 1.6rem); line-height: 1.4;}
                .content-footer{padding-left: 0;
                    p{padding-left: 38px;}
                    a{
                        &.tel{margin-top: 23px; margin-bottom: 10px;}
                    }
                }                
            }
            &.booking-help, &.return-help, &.away-help{background-color: var(--color-grey-10); border-radius: 8px; padding: 40px 40px;
                .content-column{text-align: left;
                    h2{text-align: left; justify-content: flex-start; align-items: flex-start;}
                    a{
                        &.tel{justify-content: flex-start;}
                    }
                    .opening-hours{
                        p{
                            &.toggleOpeningHours{padding-left: 0;
                                span{justify-content: flex-start;}
                            }                            
                        }
                        .hours-reveal{padding-left: 0;
                            p{padding-left: 0 !important;}
                        }
                    }
                    .content-footer{
                        .btns{justify-content: flex-start;}
                    }
                }
                @media(max-width:600px){padding: 30px 24px;}
            }

            &.booking-help, &.return-help{
                .content-column{
                    &:first-of-type{flex:1.47;}  
                }
            }
            &.away-help{
                .content-column{
                    &:first-of-type{flex:0.7;}               
                    &:nth-of-type(2){
                        &>div{max-width: 320px;}
                    }               
                } 
            }
            &.booking-help, &.away-help{ 
                .opening-hours{
                    .hours-reveal{padding-left: 39px; margin-bottom: 20px;}
                }
            }
            @media(max-width:1080px){display: grid; gap:42px;}
        }
        @media(max-width:1080px){padding-bottom: 40px; margin-bottom: 5px; }
    }

    .opening-hours{
        &>p{cursor: pointer;
            i{font-style: normal;}
            span{display: inline-flex; align-items: center; gap:4px;}
        }
        .hours-reveal{display: none; gap:5px 0; grid-template-rows: 1fr; transition: grid-template-rows 0.3s ease-out; max-width: 425px; margin: 5px 0 20px;
            padding { font-weight:unset; }
            ul{display: grid; gap:8px; padding-top: 10px; overflow: hidden;
                li{display: flex; justify-content: space-between; gap:20px; font-size: 1.6rem;
                    span{color: inherit !important; text-decoration: none !important;}
                    &.today{font-weight: 600;}
                }
            }   
            &.active{display: grid;
                ul{padding-bottom: 10px;}
            }  
        }   
        &+p{margin-top: 0 !important;}

    }

    .btns{display: flex; justify-content: center; gap:13px;
        .btn{height: auto; line-height: normal; padding: 9px 20px; background-color: var(--color-yellow); cursor: pointer; border-radius: 8px; color: var(--color-off-black); font-size: 16px; margin-top:19px; border:1px solid transparent; font-family: "Magazine Grotesque", sans-serif;
            a{color:white; text-decoration: none;}
            &.white{background-color: white; border-color: var(--color-off-black); color: var(--color-off-black);
                a{color: var(--color-off-black);}
            }
            @media(max-width:460px){width: 100%;}
        }
        @media(max-width:430px){display: grid;
            .btn{
                &.white{margin-top: 0;}
            }
        }
    }
}