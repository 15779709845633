@use "sass:color";
@use "_mixins";
@use "_extends";
@use "_colors";
button{border-radius: 35px; cursor: pointer; font-weight:700; letter-spacing: -0.01rem; transition:0.3s; border:1px solid var(--color-off-black); display: inline-flex; align-items: center; justify-content: center; gap:10px; padding:12px 15px; font-size: clamp(1.6rem, 1.6vw, 1.6rem); line-height: 160%;background-color: transparent; 
    &.highlight{background-color: var(--color-yellow); color:var(--color-off-black); border-color: var(--color-yellow);
        &:hover{background-color: var(--color-yellow);}
        &.outline{border-color: var(--color-yellow);  background-color: var(--color-yellow); border-radius: 8px;
            &:hover{background-color: var(--color-yellow);}
        }        
    }
    &.shadow{box-shadow: 0 7px 20px rgba(var(--color-off-black), 0.24);}   
    &.uppercase{text-transform: uppercase;}
    //&:hover{background-color: white;}
    &.purple{border:1px solid rgba(var(--color-yellow), 0.4); color: var(--color-yellow);}
    &.square{border-radius: 8px; height:auto; padding: 12px 15px; color:var(--color-off-black); font-size: 1.6rem; border:none;
        &.grey{background-color: var(--color-yellow);
            a{color:var(--color-off-black);}
            &:hover{background-color: var(--color-yellow)}
        }
        &.purple{background-color: var(--color-yellow);
            a{color:var(--color-off-black);}
            &:hover{background-color: var(--color-yellow);}
        }
        &.blue{background-color: var(--color-yellow);
            a{color:var(--color-off-black);}
            &:hover{background-color: var(--color-yellow);}
        }
        //&.small{padding: 4px 23px 0;}
    }    
}

