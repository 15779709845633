@use "sass:color";
@use "_mixins";
@use "_extends";
@use "_colors";
.floating-cta{position: -webkit-sticky; position:sticky; bottom:-20px; transform:translateY(0); z-index:98; height: 0;              

    button{background-color: var(--color-yellow-light); border-radius:8px; width:auto; border-radius: 8px; padding:16px 24px; height:auto; justify-content: center; border:none; box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15); gap:24px; animation: toast .24s ease-out 3s forwards; opacity:0; display:flex; margin:0 auto; cursor: default;
        .floating-cta__image{background-color:white; border-radius:100%; flex:0 1 52px; min-width: 52px; height:52px; display:flex; align-items:center; justify-content:center;}
        .floating-cta__text{text-align: left;
            h5{@include mixins.font(1.4, 1.6, 1.6rem, 700, 0.01rem, var(--color-off-black)); margin-bottom:6px;}
            p{@include mixins.font(1.4, 1.4, 1.4rem, 400, 0.01rem, var(--color-off-black)); white-space:nowrap;}
        }
        label{@include mixins.font(1.6, 1.6, 1.6rem, 700, 0.01rem, var(--color-off-black), "Magazine Grotesque"); cursor: pointer; border-radius:8px; padding:12px 16px; background-color: var(--color-yellow);} 
        //&:hover{background-color: var(--color-yellow)}

        @keyframes toast {
           0% {transform:translateY(0); opacity:0;}
           1% {transform:translateY(0); opacity:1;}
           100% {transform:translateY(-110px); opacity:1;}
        }   
       
        @include mixins.mq(340){width:90% !important;}
    }    
    @include mixins.mq(500){bottom:-38px;
        button{width: 100%; justify-content: space-between; border-radius: 0px; padding:16px 30px;}
    }
    @include mixins.mq(374){
        button{padding:16px 20px;}
    }
}

