@use "_mixins";
@use "_extends";
@use "_colors";
@mixin talk {
    text-align: center; padding: 0 40px; background-color:white;
    label { display: inline-block; cursor: pointer;
        &.online-status {position: relative;
            &:after { content: ""; position: absolute; right: -8px; top: -8px; width: 8px; height: 8px; background-color: #f93d3d; border-radius: 100%; }
            &.online {
                &:after { background-color: #4CA771; }
            }
        }
    }
    h2, .h2{@include mixins.font(2.6, 3.2, 120%, 400, 0, black); font-family: "Magazine Grotesque", sans-serif; margin-bottom:13px; text-align: center; max-width: 260px; margin: 0 auto;}
    p{@include mixins.font(1.5, 1.5, 150%, 400, 0, var(--color-off-black)); margin:0 auto; text-align: center; margin: 6px 0 25px;}
    a{@include mixins.font(1.8, 2.4, 110%, 400, 0, var(--color-off-black));
        b{@include mixins.font(1.8, 2.8, 110%, 700, 0, var(--color-off-black));}
    }

    ul{display: flex; justify-content: center; margin-top: 55px;
        li{@include mixins.font(1.5, 1.5, 110%, 400, 0, var(--color-off-black)); cursor: pointer;
            img{margin: 0 auto 7px;}
            a{@include mixins.font(1.5, 1.5, 110%, 400, 0, var(--color-off-black));}
            &:nth-child(2){margin: 0 15px; padding: 0 15px; border-right:1px solid #dedede; border-left:1px solid #dedede;}
        }
    }

    .opening-times{position: relative;
        small{@include mixins.font(1.4, 1.4, 110%, 400, 0, #37424A); display: inline-flex; gap:6px; align-content: center; justify-content: center; margin-top: 44px; cursor: pointer;
            img{cursor: pointer;}
            &:hover{
                &+.opening-times-popup{opacity:1; transform: translate(-50%, 0); pointer-events: all;}
            }
        }
        .opening-times-popup{position: absolute; width:320px; background-color: white; box-shadow: 0px 0px 11px rgb(0 0 0 / 9%); border-radius: 8px; padding: 20px; bottom: 30px; left: 50%; opacity: 0; transform: translate(-50%, 5px); pointer-events: none; transition: opacity 0.3s 0s, transform 0.3s 0s ease-out;
            ul{display: flex; flex-direction: column; gap:8px; margin-top: 0;
                li{@include mixins.font(1.4, 1.4, 110%, 400, 0, var(--color-off-black));
                    &:nth-child(2){padding: 0; margin: 0; border:none;}
                }
            }
            p{margin: 0;}
            &:after{content:""; position: absolute; left:50%; bottom:-7px; width:8px; height: 8px; background-color: white; transform: rotate(45deg) translateX(-50%);}
        }                
    }
    @include mixins.mq(860){padding: 0 30px;}         
    @include mixins.mq(520){margin-bottom:25px;}
    @include mixins.mq(500){padding: 0 20px;}  
}

@mixin visit {
    background-color: var(--color-grey-10); text-align: center; margin: 34px 0 0 0; padding: 36px 40px 80px; border-radius: 8px;
    .h2{@include mixins.font(2.6, 3.2, 120%, 400, 0, var(--color-off-black)); font-family: "Magazine Grotesque", sans-serif; margin-bottom:13px; text-align: center; max-width: 260px; margin: 0 auto;}
    p{@include mixins.font(1.5, 1.5, 150%, 400, 0, var(--color-off-black)); margin:0 auto; text-align: center; margin: 6px 0 15px;}
    button{font-weight: 700; font-size: 1.6rem;  padding: 12px 15px; padding: 8px 15px; height: auto; font-size: 1.6rem; color: var(--color-off-black); border-radius: 8px; min-height: initial;
    }
    .h3{@include mixins.font(1.6, 1.6, 110%, 400, 0, var(--color-off-black)); margin: 18px 0 10px;}
    small{@include mixins.font(1.4, 1.4, 110%, 400, 0, var(--color-off-black)); border-radius:8px; display: flex; gap:6px; align-content: center; justify-content: center; margin-top: 24px; cursor: pointer;
        img{cursor: pointer;}
    }
    @include mixins.mq(860){padding: 40px 30px 60px;}          
    @include mixins.mq(500){padding: 40px 20px 40px;}       
}


.sidebar{position:fixed; top:0; right:0; bottom:0; left:0; background: rgba(var(--color-off-black), 0.3); z-index: 9999999999; opacity:0; transition: opacity 0.3s ease-out; transition-delay:0.14s; cursor:pointer; pointer-events:none;
    .scrollbar-wrapper{@extend %scrollbar; padding:0;}
    &__content{width:500px; background:white; box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.2); padding: 120px 0 0; cursor:default; transition: transform 0.24s ease-out; transform: translateX(100%); transition-delay:0s; margin-left: auto; height:100vh;
        .close-sidebar{position:fixed; top:26px; right:26px; cursor:pointer; z-index: 9999; background-color: #fff; border-radius: 26px; padding: 8px;
            @include mixins.mq(600){top:10px; right:15px;}
        }
        button{font-size:1.4rem; font-weight:600; padding: 0 20px; height:51px; margin: 19px auto 14px; min-height:51px;}
        .sidebar__header{text-align:center; margin-bottom:57px; padding: 0 40px;
             h4, .h4{@include mixins.font(2.6, 3.2, 3.36rem, 400, 0, var(--color-off-black)); font-family: "Magazine Grotesque", sans-serif; margin-bottom:13px;}
             p{@include mixins.font(1.6, 1.6, 150%, 400, 0, var(--color-off-black)); max-width:300px; margin:0 auto;}
             @include mixins.mq(520){margin-bottom:25px;}
        }
        .sidebar__body{ height:100%; padding: 0 40px;
            .form-field{margin-bottom:55px;
                h5, .h5{@include mixins.font(1.6, 1.6, 2.79rem, 700, 0, var(--color-off-black)); text-transform:uppercase; margin-bottom:16px;}
                .form-input{margin: 8px 0 25px;
                    .vc-popover-content{ padding: 20px 5px; border: solid 1px #d3d4d5; }                    
                    label{@include mixins.font(1.4, 1.4, 1.9rem, 400, 0, var(--color-off-black)); text-transform:uppercase; display:block; margin-bottom:6px;
                        &#destinations{
                            &+div{
                                &+div{display: none;}
                            }
                        }                       
                        &.not-sure-tag{color:var(--color-off-black) !important; margin-top: 4px; font-size: 1.4rem; text-transform: none; cursor: pointer;}                        
                    }
                    p{@include mixins.font(1.4, 1.4, 2.1rem, 400, 0, var(--color-off-black)); margin-bottom:10px;
                        a{color: colors.$purple}
                        &.extra-info{margin-top:8px;}
                    }
                    input, select, textarea, .faux-select, .multiselect{border: 1px solid colors.$grey7; padding: 12px; width:100%; min-height:58px; font-size:16px; }
                        .multiselect{border-radius:0;
                            .multiselect-tags-search-wrapper{
                                input{border:none; min-height:auto; cursor:pointer; @include mixins.font(1.4, 1.4, 2.1rem, 400, 0, var(--color-off-black)); padding-top:5px; padding-left:0;}
                            }
                            .multiselect-dropdown{
                                .multiselect-options{
                                    li{padding:12px;
                                        span{@include mixins.font(1.4, 1.4, 1.4rem, 400, 0, var(--color-off-black));}
                                    }
                                }
                            }
                            .multiselect-caret{width: 10px; height: 16px;}
                            .multiselect-tags{padding:0; margin:0; gap:5px;
                                .multiselect-tag{@include mixins.font(1.4, 1.4, 2.1rem, 400, 0, var(--color-off-black)); display: inline-flex; align-items: center; margin:0; background-color: #D3F3FA; padding: 7px 14px; border-radius: 8px; cursor:default;
                                    span{cursor:pointer; height: 1.75rem; margin-left: 4px;}
                                }
                            }
                            .multiselect-clear{display:none;}
                            &.is-active{box-shadow:none;}
                        }
                    .faux-select{position:relative; padding-top: 0; padding-bottom: 0; background: white;
                        /* label{margin:0; cursor:pointer; text-transform:none;}*/
                        &__selected{display:flex; flex-wrap: wrap; gap:12px; padding: 0 0 12px 0;
                            li{@include mixins.font(1.4, 1.4, 2.1rem, 400, 0, var(--color-off-black)); display: inline-flex; align-items: center;  background-color: #D3F3FA; padding: 7px 14px; border-radius: 8px; cursor:default;
                                img{width:10px; margin-left:8px; cursor:pointer;}
                            }
                        }
                        &__dropdown{position:absolute; top:57px; left:0; right:0; background: white; z-index: 2; padding: 15px 20px; box-shadow: 0px 12px 24px rgb(0 0 0 / 20%); border-radius: 0 0 5px 5px; display:grid; cursor:default; transition: opacity 0.2s, transform 0.2s ease-out; transform:translateY(-4px); opacity:0; pointer-events:none; max-height: 50vh; overflow: auto;
                            li{@include mixins.font(1.4, 1.4, 2.1rem, 400, 0, var(--color-off-black)); cursor:pointer; padding:5px;}
                            &.active{transform:translateY(0); opacity:1; pointer-events:all;}
                        }
                        &__text{padding: 4px 0 0 0; border:none; min-height: 52px;
                            &::placeholder{font-size: 16px;}
                        }
                        .no-destinations-found{padding-bottom: 12px;
                            li{font-size: 14px; color:#797979;}
                        }
                    }
                    textarea{resize:none;}
                    input[type="number"]{ -moz-appearance: textfield;
                        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}                        
                    }
                    select{-moz-appearance: none; -webkit-appearance: none; appearance: none;}
                    .faux-select, select{background: url("../../images/icons/chevron-down.svg") no-repeat center right 18px; cursor:pointer;}
                    small.error {color: red; display: block; font-size: 1.4rem;}
                    .not-sure{ font-size: 1.4rem; display: block;}
                }
                &:last-of-type{margin-bottom:0;}
            }
            button{width:100%;} 
            @include mixins.mq(860){padding: 0 30px;}          
            @include mixins.mq(500){padding: 0 20px;}  
        }   

        .sidebar-start-header{
            @include talk
        }

        .sidebar-start-body{
            @include visit
        }
        
        @include mixins.mq(520){padding:70px 20px 20px !important; width:100%; height:100%;}

        .form-fail, .form-success{
            h6{@include mixins.font(3.6, 3.6, 4.2rem, 400, 0, var(--color-off-black), "Magazine Grotesque"); margin-bottom:22px;}
            p{@include mixins.font(1.5, 1.5, 2.4rem, 400, 0, #5F5F5F); text-align:center; padding: 0 20px; }
        }
    }
    &.active{pointer-events:all; opacity:1; transition-delay:0s;
        .sidebar__content{transform: none; pointer-events:all; transition-delay:0.22s;-webkit-overflow-scrolling: none; overscroll-behavior: none;}
    }    
}

.riq-vbox-overlay { background:none !important; }

// different location, mostly a row
.in-page-cta-2 { display: flex; flex-direction: row; justify-content: center; gap: 60px; margin-bottom:0;
    @include mixins.mq(860){ flex-direction: column; }   
    button{font-size:1.4rem; font-weight:600; padding: 0 20px; height:51px; margin: 19px auto 14px; min-height:51px;}
    .cta-leftbit { @include talk; margin-bottom: 60px;}
    .cta-rightbit { @include visit; margin-top: 0; padding: 36px 40px 10px; margin-bottom: 60px;}
}
// different location, always a col
.sidebar-tabs{
    .in-page-cta-2 { flex-direction: column; width:100%; gap: 0;
        .cta-leftbit { padding: 40px 0; }        
        .cta-rightbit { margin-top: 0; flex-basis: auto; padding: 36px 40px 10px; }
    }
}