@use "_mixins";
@use "_colors";
@use "_extends";
@use "_fonts";
@use "_header";
@use "_whiteHeader";
@use "_footer";
@use "_menu";
@use "_search";
@use "_buttons";
@use "_newCTAs";
@use "_sidebar";
@use "_wysiwyg";
@use "_wysiwygProject";
@use "_AppointmentBooker";
@use "_mixed-list"; 
@use "_usp-list"; 
@use "_content-blocks";
@use "_content-block-feefo";
@use "_content-block-awards";
@use "_content-block-banner";
@use "_content-block-destinations";
@use "_content-block-experts";
@use "_content-block-inspiration";
@use "_content-block-offers";
@use "_content-block-suggested";
@use "_content-block-usp-panel"; 
@use "_content-block-grid"; 
@use "_content-block-uspthree"; 
@use "_content-block-multi"; 

body{margin: 0; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; overflow-y:scroll;background-color:#fff;
  &.lock{position: fixed; width: 100%;
    header{
      .header__cta{opacity: 0;}
    }
  } 
  #chat-widget-container{transform: translate(8px, 16px); transition: bottom 0.3s, transform 0.3s, height 0.3s ease-out !important; bottom:30px !important; }

  //riq open
  div#responseiq_app_frame_container iframe {
    left: 7px !important; // request from nick to make 30px from the left, inside the iframe the button is 23px left
  }
  #responseiq-app-container{z-index: 99 !important;
    .riq-vbox-overlay{
      &.riq-fade-in, &.riq-fade-out{
        &+#responseiq_app_frame_container{
          iframe{max-width: 100% !important;} //show popup when open
        }
      }
    }
  }
  
  &.offset-chat{ // when responseIQ is on the page
    #responseiq-app-container{z-index: 99999999 !important;}
  }
  
  &.footer-bump{
    @include mixins.mq(600){
        #chat-widget-container{transform: translateY(-49px); height: calc(100vh - 120px) !important; height: calc(100dvh - 120px) !important; max-height: 652px !important; overflow: visible !important;}
        #responseiq_app_frame_container>iframe{bottom:86px !important;}
    }
  }
  &.basket-footer-bump{
    #chat-widget-container{transform: translateY(-57px); height: calc(100vh - 120px) !important; height: calc(100dvh - 120px) !important; max-height: 652px !important; overflow: visible !important;}
    #responseiq_app_frame_container>iframe{bottom:94px !important;}
  }
}

html, button, input{font-family: "Archivo", sans-serif;}

[v-cloak]{opacity: 0;}

.google-policy { font-size: 1.4rem; padding-bottom:20px; margin-top: 20px; }

*{box-sizing: border-box;}
html{font-size: 62.5%;}
h1{@extend %h1;}
h2{@extend %h2;}
h3{@extend %h3;}
h4{@extend %h4;}
h5{@extend %h5;}
h6{@extend %h6;}
p{@extend %text;
  &.h2 { font-size: clamp(2rem, 3.2vw, 3.2rem); line-height: 110%; letter-spacing: 0.13rem; @extend %serif; color: colors.$darkGray; }
  &.h3 { font-size: 6rem; font-size: clamp(2rem, 6vw, 6rem);  line-height:140%; font-weight: 400; letter-spacing: -0.14rem; @extend %serif; color: colors.$darkGray; }
}
p, h1, h2, h3, h4, h5, h6{margin: 0;} 
a{text-decoration: none;}
ul{margin: 0; padding: 0;
  li{list-style: none;}
}
a{color: var(--color-off-black);}
b{font-weight: 700;}
#app {-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
select{-moz-appearance: none; -webkit-appearance: none; appearance: none; cursor: pointer;
    &:focus-visible{outline:none;}
}
.scroll-margin { scroll-margin:90px; }
main{
  .container-wrapper{overflow: hidden;}
  .container{width: 100%; max-width: 1440px; margin: 0 auto; padding: 0 77px; position:relative; overflow:hidden;
    &.large{max-width:1516px;}
    &.mid{max-width:1330px;}
    &.medium{max-width:1218px;}
    &.small{max-width:1110px;}
    &.x-small{max-width:1000px;}
    &.xx-small{max-width:845px;}
    &.offset{padding-right:0;}
    &.offset-left{padding-left:0;}
    &.visible{overflow: visible;}
    &.no-max{max-width: initial;}
    @media (max-width: 860px){padding: 0 42px;}
    @media (max-width: 620px){padding: 0 30px;}
    @media (max-width: 374px){padding: 0 20px;}
    .container{padding-left: 0 !important; padding-right: 0 !important;}
  }
  img{display: block; max-width: 100%;}
  input{
        &:focus{outline:none;}
        &::-webkit-input-placeholder {font-size:1.8rem; color: #ADB0B1;}
        &:-ms-input-placeholder {font-size:1.8rem;  color: #ADB0B1;}
        &::placeholder {font-size:1.8rem;  color: #ADB0B1;}                                  
  }
  input, textarea {-webkit-user-select: text !important;}
  .checkbox{position:relative; display:flex; align-items:center; gap:9px;
        input[type="checkbox"]{margin:0; opacity:0; cursor:pointer; @extend %pin; height: 100%; width: 100%; 
            &:checked{
                &+.checkbox-box{background: url("../../images/icons/tick-white.svg") no-repeat center var(--color-off-black); border-radius:4px; background-size: 12px; border: 1px solid var(--color-off-black);}
            } 
            &.error{
              &+.checkbox-box{border-color: red; background-color: #fc9f9f;}
            }                                  
        } 
        label{@include mixins.font(1.8, 1.8, 2rem, 700, -0.07rem, var(--color-off-black));}
       .checkbox-box{height: 24px; flex: 0 0 24px; border: 1px solid var(--color-off-black); border-radius:4px; } 
  }

  .radios{
    .radio{position: relative; display: flex; align-items: center; gap:14px;
        input[type="radio"]{position: absolute; top:0; right:0; bottom:0; left:0; opacity: 0; cursor: pointer; margin:0;
            &:checked{
                &+.radio-box{
                    &:after{background-color: var(--color-off-black);}
                }
            }
        }
        .radio-box{height: 24px; width: 24px; border-radius: 100%; border: 1px solid colors.$grey3; background-color: white; display: flex; align-items: center; justify-content: center;
            &:after{content: ""; height: 12px; width: 12px; background-color: white; border-radius: 100%;}
        }
        label{@include mixins.font(1.6, 1.6, 2.17rem, 400, 0.08rem, var(--color-off-black)); margin-bottom: 0 !important;}
    }
  }

  .form-inputs{
      .form-input{
          small{
              &.error{@include mixins.font(1.4, 1.4, 1.8rem, 400, 0, #f01111); display:none; margin: 5px 0 0;}
          }
      }
  }

  .is-sticky{
    position: sticky;
    position: -webkit-sticky;
    top: 90px;
    background-color: white;
    z-index: 11;
    margin: 0;
    max-width: none;
    //padding: 0 !important;
    &> .swiper{
      margin: 0 auto;
      max-width: 1286px;
      // @media (max-width: 860px) {padding: 0 42px;}
      // @media (max-width: 620px) {padding: 0 30px;}
      // @media (max-width: 374px) {padding: 0 20px;}
    }
    @media (max-width: 760px){ top: 40px;}
  }
         
  section{
    &.gray{background-color: #fcf9f4;
      @media (max-width: 620px){ padding:60px 0 90px;}
    }
  }

  .s-t-170{margin: 170px 0 0;
    @media (max-width: 620px){margin: 90px 0 0;}
  }
  .s-t-100{margin: 100px 0 0;}

  .uppercase{@extend %uppercase;}
  .serif{@extend %serif;}
  .sans-serif{@extend %sans-serif;}
  .black{color:black !important;}
  .left{text-align: left;}
  .no-padding{padding:0;}
  .pad-0{padding:0;}
  .pad-8{padding:8px 0 16px;
    @media(max-width:800px){padding-top: 4px; padding-bottom: 6px;}  
    @media(max-width:500px){padding-bottom: 0px;}
  }
  .pad-16{padding:16px 0 32px;
    @media(max-width:800px){padding-top: 8px; padding-bottom: 12px;}  
    @media(max-width:500px){padding-bottom: 0px;}        
  }
  .pad-32{padding:32px 0 60px;
    @media(max-width:800px){padding-top: 10px; padding-bottom: 20px;}  
    @media(max-width:500px){padding-bottom: 0px;}  
  }
  .pad-40{padding:40px 0 80px;
    @media(max-width:800px){padding-top: 16px; padding-bottom: 26px;}  
    @media(max-width:500px){padding-bottom: 0px;}  
  }
  .gray-bg{background-color: var(--color-grey-10);}
  .yellow-bg{background-color: var(--color-yellow-mid);} 
  .beige-bg{background-color:colors.$beige;}
  .dark-bg{background-color:var(--color-grey-10); position: relative;}
  .center{margin: 0 auto;}
  .flex{display:flex;}
  .swiper{margin-left:0; margin-right:0;}
  .swiper-button-next, .swiper-button-prev{@extend %swiper-button;}
  .swiper-button-prev{@extend %swiper-button-prev;}
  .carousel-overflow{
      &>div{&:first-child{z-index:2;}}
      .swiper{overflow:visible; //padding-right: 77px;
          &:before{content: ""; position:absolute; top:-1px; bottom:0; left:-5000px; width:5000px; background-color:white; z-index: 2;} 
          @include mixins.mq(630){width:100%;
              &:before{content:none;}
          }
      }    
      &.dark{.swiper{&:before{background-color: var(--color-grey-10);}}}
  }

  &.chat-offset{
    .scroll-to-top{bottom:100px;
      @include mixins.mq(600){bottom:90px;}
    }    
  }
}

.fade-enter-active,.fade-leave-active {transition: opacity 0.3s ease;}
.fade-enter-from,.fade-leave-to {opacity: 0;}

//TODO: use "darken" class to "spot fix" and give *divs* with background images a darker overlay
.darken, 
.stagger-image-text__left .image,
.offset-carousel__card, 
.room-details .room-details-card__image, 
.bookflow-rooms__room-card .room-card__image, 
.bookflow-rooms__swiper .swiper-slide, 
.three-column-grid>a{
    position:relative; 
    -webkit-filter: saturate(75%);
    filter: saturate(75%);    
    &:before{
        content:"" !important; position:absolute; top:0; right:0; bottom:0; left:0; background-color: rgba(black, 0.2); pointer-events: none;
    } //Hue: 0, Saturation: -25, Brightness: -5, Contrast: -50
}
//targeting img tags:
.image-banner-grid img,
.stagger-image-texts-small .stagger-image-text__left .image img,
.stagger-image-texts-simple .stagger-image-text__left .image img,
.our-pick-card__image img, .card-image img,
.stagger-image-texts .stagger-image-text__left .image img {
    -webkit-filter: saturate(75%) brightness(0.85);
    filter: saturate(75%) brightness(0.85);
}
div[swiper-modal-trigger]{position: relative; z-index: 2;}

//prevent mobile highlights on click
div{-webkit-tap-highlight-color: rgba(0, 0, 0, 0); -webkit-tap-highlight-color: transparent;}

//chat is minimised (with agent online)
#chat-widget-container[style*="width: 84px;"]{height: auto !important;}

//ensure cookie messaging is overlaying everything
#usercentrics-root{z-index: 999999999999; position: relative;}

//calendar popup
.atcb-list-wrapper.atcb-dropdown{top:60px !important; left:0px !important;}
.atcb-button.atcb-active:not(.atcb-modal-style, .atcb-dropoverlay) .atcb-checkmark, .atcb-button.atcb-single:focus .atcb-checkmark, .atcb-button.atcb-single:hover .atcb-checkmark{top:20px; right:20px; padding: 0 !important;}
.atcb-saved .atcb-checkmark{background: none !important; top:13px; right:10px;}
.atcb-list{box-shadow: none !important;}
#atcb-pos-wrapper{display: none !important; height: 370px !important;}
.cta.atcb-initialized{ 
  &.active{
    #atcb-pos-wrapper{display: block !important;}
  }
}
#atcb-bgoverlay{background-color: transparent !important; backdrop-filter: blur(0px);}

.appointment-booker{                         
  small.error { font-size: 1.6rem; color: red; }
  .confirmation-stage__body{
    h4{margin-bottom: 15px !important;}
  }

  .confirmation-stage{
    .scrollbar-wrapper{
      &>div{overflow-y: scroll !important;}
    }
  }
}

.emergency-communication { width:100%; padding:4px 0; text-align:center; }

#search-unavailable{display: none; }
.search-unavailable{position: relative;
  #search-unavailable{display: flex; position: absolute; top:0; right:0; bottom:0; left:0; z-index: 10; pointer-events: none;
    #search-unavailable-message{display: grid; place-items: center; padding: 0 20px; text-align: center; flex:1; pointer-events: all;
      label{font-size: clamp(1.4rem, 2.2vw, 2.2rem); font-weight: 600; color:#464646;}
    }
  }
}

#reviews{
  .title{text-align: center;
    h2{margin-bottom: 30px;}
  }
}

.button-cover-right,.button-cover-left { position:absolute; width:40px; height:40px; bottom:8px; z-index: 5;
    .button-cover-inner{ position:relative; height: 100%;
        .swiper-button-prev, .swiper-button-next {bottom:0;}
        .swiper-button-next { left:0; }
        .swiper-button-prev { left:0; }
    }    
    @include mixins.mq(660){display: none !important;}
}
.button-cover-right { right:0; }
.button-cover-left { left: 0; }