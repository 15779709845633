@use "_mixins";
@use "_extends";
@use "_colors";

/* Content Block Destinations */
.content-block-destinations { 
    @include mixins.mq(1100) { padding:80px 0 40px; }
    @include mixins.mq(500) { padding:40px 0 0; }
    .container-wrapper { padding-top:0; }
    .container {
        &.carousel-overflow { 
            @include mixins.mq(1100) { padding:0; }
            .swiper { 
                &:before { bottom:-80px; top:-80px; }
            }
        }
        section { display:grid; grid-template-columns:600px 1fr; gap:80px;
            @include mixins.mq(1100) { grid-template-columns:1fr; gap:0; }
            .content { display: flex; flex-direction: column; align-items: flex-start; justify-content: center; z-index: 2;
                @include mixins.mq(1100) { align-items:center; margin-bottom:24px; padding: 0 30px; }
                h2 { @include mixins.font(1.2, 1.4, 18px, 400, 0,  #1A1A1A); text-align:left; text-transform:uppercase; margin:0 0 8px;
                    @include mixins.mq(1100) { margin:0 0 4px; }   
                }
                h3 { @include mixins.font(3.2, 5.2, 56px, 700, 0,  #1A1A1A); text-align:left; text-transform:uppercase; margin:0 0 24px;
                    @include mixins.mq(1100) { margin:0 0 8px; @include mixins.font(3.2, 3.2, 36px, 700, 0,  #1A1A1A); text-align: center; }
                }
                .wysiwyg { margin:0 0 24px; 
                    @include mixins.mq(1100) { margin:0; text-align:center; }
                }
                .cta { display:flex; justify-content:center;
                    @include mixins.mq(1100) { display:none; }
                    a { background-color:#FFD00D; padding:8px 16px; min-height:40px; display:flex; align-items:center; justify-content: center; border-radius:8px; @include mixins.font(1.6, 1.6, 22px, 700, 0, #1A1A1A, "Magazine Grotesque"); 
                        @include mixins.mq(520) { width:100%; }
                    }
                }
            }
            .carousel { 
                @include mixins.mq(1100) { overflow:hidden; }
            }
            .mixed-list-swiper-wrapper { width:calc(100% - 38%);
                @include mixins.mq(1100) { padding-bottom:30px; }
                @include mixins.mq(940) { padding-bottom:60px; }
                .mixed-list-swiper { width:calc(100% - 38%); overflow:visible; margin-top: 0;
                    @include mixins.mq(1100) { padding:0 20px; }
                    .swiper-slide { width:228px !important; overflow: visible;
                        .card-bg { width:228px !important; height:370px; overflow: visible; border-radius: 8px;
                            &:before, &:after { border-radius: 8px; }
                            .card-title { bottom: -60px; left: 0; color:#1A1A1A;
                                span { text-transform: uppercase; margin-bottom: 0; @include mixins.font(1.4, 1.4, 18px, 400, 0, #1A1A1A); }
                                p { line-height: 26px; font-size: 22px; text-transform: uppercase; font-weight: 700; }
                            }                        
                        }
                        .description { display:none; }
                    }
                }
                .swiper-button-prev, .swiper-button-next { top:calc(50% - 20px) !important;
                    @include mixins.mq(1100) { display:none; } 
                }
            }
            .cta { display:flex; justify-content:center; display:none;
                @include mixins.mq(1100) { display:flex; }
                @include mixins.mq(520) { width:100%; padding: 0 20px; }
                a { background-color:#FFD00D; padding:8px 16px; min-height:40px; display:flex; align-items:center; justify-content: center; border-radius:8px; @include mixins.font(1.6, 1.6, 22px, 700, 0, #1A1A1A, "Magazine Grotesque"); 
                    @include mixins.mq(520) { width:100%; }
                }
            }
        }
    }
}

.gray-bg { 
    .carousel-overflow .swiper::before { background-color:#FDF8F3 !important; }
}