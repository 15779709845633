@use "_mixins";
@use "_extends";
@use "_colors";
.tooltip {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: help;

    .tooltiptext {
        font-size: 1.2rem;
        visibility: hidden;
        width: 210px;
        border: 1px solid #555;
        background-color:#fff;
        color: #000;
        text-align: center;
        border-radius: 8px;
        padding: 10px 8px;
        position: absolute;
        z-index: 999;
        top: 125%;
        left: 0;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;

        ::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
          }
      }
      
      &:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
      }
  }