@use "_mixins";
@use "_extends";
@use "_colors";
.image-grid{background-color: #E66E61; padding: 24px 0; display:grid; grid-template: 1fr 1fr/0.65fr 0.65fr 0.75fr 0.85fr 0.5fr 0.5fr; gap:23px; height: 67vh; max-height: 620px; min-height:300px;
    .image{overflow:hidden; overflow: hidden;
        &:nth-child(1){grid-column:1 / 3; border-radius: 0 8px 8px 0;}
        &:nth-child(2){grid-column:3 / 5; grid-row:1 / 3; border-radius: 8px;}
        &:nth-child(3){grid-column:5 / 7; grid-row:1 / 3; border-radius: 8px 0 0 8px;}
        &:nth-child(4){grid-column:1 / 2;  border-radius: 0 8px 8px 0;}
        &:nth-child(5){grid-column:2 / 3;  border-radius: 8px;}  
        img{@extend %fill;}
    }

    @include mixins.mq(860){gap:10px; max-height:465px;}

    @include mixins.mq(780){grid-template: 2.15fr 1fr 1fr/1fr 1fr 3.5fr;
        .image{
            &:nth-child(1){grid-column:1 / 3;}
            &:nth-child(2){grid-column: 1/4; grid-row: 1/2; border-radius: 0;}
            &:nth-child(3){grid-column: 3/4; grid-row: 2/4;}
           
        }
    }
}