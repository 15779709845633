@use "_mixins";
@use "_extends";
@use "_colors";

.hero-store{margin-bottom: 24px; border-radius: 8px; background-color: var(--color-grey-10); overflow: hidden; position: relative; display:flex; flex-direction:column; justify-content:center; @include mixins.bgRatio(500, 600, 41);
    .hero-content{max-width:1100px; gap:30px; margin: 0 auto; padding:20px 30px 20px; text-align:center; align-items: center; position: relative; z-index: 2; display: flex; flex-direction: column; justify-content: center; height: 100%;
        h1{@include mixins.font(3.8, 8, 100%, 700, null,  var(--color-yellow), "Magazine Grotesque"); text-transform: uppercase; letter-spacing:0.1rem;
            @include mixins.mq(600){margin-bottom: 10px;}
        }
        &>label{color:white; font-size: clamp(1.4rem, 1.8vw, 1.8rem); text-transform: uppercase; font-weight: 700;}
        p{@include mixins.font(1.6, 1.8, 1.4, null, 400, var(--color-off-black)); padding: 0 50px; margin-bottom: 30px;
            a{text-decoration: underline;}
        }
        #find-store-trigger{justify-content: center; color: var(--color-off-black); background-color: var(--color-yellow); transform: none; border-radius: 8px; padding: 10px 20px;
            label{text-transform: none; font-size: 1.6rem; font-family: "Magazine Grotesque";}
            img{width: 19px; margin-left: 5px;}
        }
        @include mixins.mq(500) { padding:  20px; }
    }
    &:after{
        content: "" !important; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.2); pointer-events: none; 
    }
    @include mixins.mq(440){height: auto;
        .hero-content{ padding:20px 20px 20px;
            p{padding: 0 0px; }
        }
    }
}

.container{
    &.wide-hero{padding: 0 25px;
        @media (max-width: 860px) {padding: 0 42px;}
        @media (max-width: 700px) {padding: 0 20px;}
        @media (max-width: 620px) {padding: 0 20px;}
        @media (max-width: 374px) {padding: 0 20px;}
    }
}

