@use "_mixins";
@use "_extends";
@use "_colors";

/* Content Block Banner */
.content-block-banner { 
    &.banner-large { padding:0; 
        .container { max-width:100%; padding:0;
            .banner-content { display:grid; grid-template-columns:1fr 873px; border-radius:0;
                @include mixins.mq(1260) { grid-template-columns:1fr 500px; }
                @include mixins.mq(940) { grid-template-columns:1fr; }
                .content { justify-content:flex-end; 
                    @include mixins.mq(940) { padding: 32px 24px 24px; }
                    h4 { @include mixins.font(2.4, 3.8, 42px, 700, 0,  #1A1A1A, "Magazine Grotesque"); max-width: 487px;
                        @include mixins.mq(940) { @include mixins.font(2.4, 2.4, 28px, 700, 0,  #1A1A1A); text-align: left;}
                    }
                }
                .image { mask-image: url("/content/images/mask-large.svg"); mask-repeat: no-repeat;
                    @include mixins.mq(940) { mask-image: url("/content/images/mask-large-mobile.svg"); mask-size: cover; }
                    img { height: 100%; max-height: 650px; 
                        @include mixins.mq(940) { height:auto; width:100%; max-height:initial; min-height: 500px; }
                    }
                    video {width:100%; object-fit:cover; max-height: 650px;
                        @include mixins.mq(940) { height:auto; width:100%; max-height:initial; min-height: 500px; }
                    }
                    .video-background { width:100%; position: relative; overflow: hidden; min-height: 500px; max-height: 650px; pointer-events:none;
                        @include mixins.mq(940) { height:auto; width:100%; max-height:initial; }
                        iframe{ position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); min-width: 914px; }
                    }
                }
            }
        }
    }
    &.banner-small { padding:80px 0;
        @include mixins.mq(500) { padding:40px 0; }
        .banner-content { display:grid; grid-template-columns:1fr 680px;
            @include mixins.mq(1260) { grid-template-columns:1fr 500px; }
            @include mixins.mq(940) { grid-template-columns:1fr; }
            .content { 
                @include mixins.mq(940) { padding: 32px 24px 24px; align-items: center; }
                .wysiwyg { 
                    @include mixins.mq(940) { text-align:center; }
                }
            }
            .image { mask-image: url("/content/images/mask-small.svg"); mask-repeat: no-repeat;
                @include mixins.mq(940) { mask-image: url("/content/images/mask-small-mobile.svg"); mask-size: cover; }
                img { height: 100%; max-height: 514px; border-radius: 8px;
                    @include mixins.mq(940) { height:auto; width:100%; min-height: 430px; max-height: 430px; }
                }
                video { width:100%; object-fit:cover; display: block; height: 100%;
                    @include mixins.mq(940) { height:auto; width:100%; min-height: 430px; max-height: 430px;}
                }
                .video-background { width:100%; position: relative; overflow: hidden; min-height: 430px; height: 100%; pointer-events:none;
                    @include mixins.mq(940) { height:auto; width:100%; max-height:initial; }
                    iframe{ position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); min-width: 914px; }
                }

            }
        }
    }
    .yellow { background-color:#FFD00D; }
    .terracotta { background-color:#E66E61; }
    .banner-content { min-height: 500px; border-radius:8px; overflow: hidden;
        .content { display: flex; justify-content: center; flex-direction: column; padding: 80px;
            @include mixins.mq(1260) { padding:40px; }
            h4 { @include mixins.font(2.4, 2.8, 32px, 700, 0, #1A1A1A, "Magazine Grotesque"); margin:0 0 24px; 
                    @include mixins.mq(940) { margin:0 0 16px; text-align:center; }
            }
            .cta { display:flex;
                a { background-color:#000; padding:8px 16px; min-height:40px; display:flex; align-items:center; justify-content: center; text-align: center; border-radius:8px; @include mixins.font(1.6, 1.6, 22px, 700, 0, #FFF, "Magazine Grotesque"); }
            }
        }
    }
}

