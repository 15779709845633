@use "_mixins";
@use "_extends";
@use "_colors";

/* Conten Block Multi */
.content-block-multi { overflow:hidden;
    section { 
        .additional-card-hider { overflow: hidden;
            @include mixins.mq(940) { overflow:visible; }
        }
        h2 { width:100%; @include mixins.font(1.2, 1.4, 18px, 400, 0,  #1A1A1A); text-align:center; text-transform:uppercase; margin:0 0 8px;
            @include mixins.mq(940) { margin:0 0 4px; }   
        }
        h3 { width:100%; @include mixins.font(3.2, 5.2, 56px, 700, 0,  #1A1A1A); text-align:center; text-transform:uppercase; margin:0 0 32px;
            @include mixins.mq(940) { margin:0 0 8px; @include mixins.font(3.2, 3.2, 36px, 700, 0,  #1A1A1A); }
        }
        .wysiwyg { max-width:846px; margin:0 auto 40px; text-align:center; }
        .multi-swiper { margin:0 0 40px; overflow: visible;
            @include mixins.mq(940) { margin:0 0 24px; } 
            .card { 
                @include mixins.mq(1260) { width: 232px; }
                .card-image { height:300px; border-radius:8px; background-size: cover;
                    @include mixins.mq(1260) { height:177px; }
                    span { background-color:#E66E61; border-radius:4px; padding:6px 8px; @include mixins.font(1.4, 1.4, 14px, 700, 0,  #FFF); position: absolute; left: 8px; top: 8px; }
                }
                .card-title { margin:16px 0; 
                    p { @include mixins.font(2.2, 2.2, 26px, 700, 0,  #1A1A1A); text-transform:uppercase; }
                    ul { display: flex; gap: 1px 10px; font-size: 1.2rem; font-size: clamp(1.2rem, 1.4vw, 1.4rem); line-height: 1.4rem; font-weight: 400; color: var(--color-off-black); font-family: "Archivo"; margin-bottom: 9px; flex-wrap: wrap;
                        li { position:relative; margin-right: 16px; @include mixins.font(1.4, 1.4, 18px, 400, 0, #1A1A1A);
                            &:after { content: ""; position: absolute; top: 50%; right: -18px; transform: translate(-50%, -50%); height: 4px; width: 4px; background:#1A1A1A; border-radius: 100%; }
                            &:last-child { 
                                &:after { content:none; }
                            }
                        }
                    }
                }
                .price { margin:16px 0 ; @include mixins.font(2.2, 2.2, 26px, 700, 0, #1A1A1A); }
            }
            .swiper-button-next, .swiper-button-prev { top: 125px; }
        }
        button { display:flex !important; justify-content:center; background-color:transparent !important; margin: 0 auto !important; padding:0 !important; height:auto !important;
            @include mixins.mq(520) { width:100%; }
            div { 
                @include mixins.mq(520) { width:100%; }
            }
            a { min-height:40px; display:flex; align-items:center; background-color:#FFD00D; justify-content:center; padding:8px 16px; border-radius:8px; border:0; @include mixins.font(1.6, 1.6, 22px, 700, 0,  #1A1A1A, "Magazine Grotesque"); 
                span { @include mixins.font(1.6, 1.6, 22px, 700, 0,  #1A1A1A); font-size:16px !important; }
            }
        }
    }
}

