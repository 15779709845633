@use "sass:color";
@use "_mixins";
@use "_extends";
@use "_colors";

:root {
    --kuoni-dark-gray: #1A1A1A;
    --kuoni-light-gray: #F7F7F7;
    --kuoni-white: #ffffff;
    --kuoni-text-gray: #c8cacc;
    --kuoni-dark-blue: #1A1A1A;
}

/* Base rules:  ( defualt = light theme ) */

.cta-component p.desktop {
    display: none;
}

@media (min-width:64em) {
    .cta-component p.mobile {
        display: none;
    }

    .cta-component p.desktop {
        display: block;
    }
}

.cta-component__inner {
    margin:0;
    padding: 40px 16px 40px;
    background-color: white;
}

.cta-component__section {
    margin: 33px 0 0px;
}

.cta-component__inner {
    color: var(--color-off-black);
    font-family: "Archivo";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    text-align: center;
}

.cta-component__title {
    font-family: "Magazine Grotesque",serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 5px;
}

.cta-component__title > em {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-style: italic;
}

.cta-component__section-title {
    font-family: "Magazine Grotesque",serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 12px;
    display:flex; 
    align-items:center;
    justify-content:center;
    //height:96px;
    flex-direction: column;
   
}

.cta-component__text-default > svg {
    position: absolute;
    top: 4px;
    left: 11px;
}

.cta-component__section-title > svg {
    position: relative;
    top: 7px;
    width: 38px;
}

.cta-component__text-default {
    position: relative;
    font-size: clamp(1.6rem, 1.6vw, 1.6rem);
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    margin-bottom: 22px;
    max-width: 570px;
    text-align: center;
    
    @media (max-width: 63.99em) {margin: 0 auto 10px;}
}

.cta-component .tel-num {
    position: relative;
}

.cta-component a.tel-num > span,
.cta-component a.cta-component__text-default {
    color: var(--color-off-black);
    font-family: "Magazine Grotesque", sans-serif;
}

.staff-status {
    cursor: pointer;
}

/* Not currently needed - 24/7 agents */
/*.staff-status:after {
    content: "";
    position: absolute;
    right: -22px;
    top: 3px;
    width: 12px;
    height: 12px;
    background-color: #f93d3d;
    border-radius: 100%;
}

.staff-status.online:after {
    background-color: #4CA771;
}*/

.cta-component .feaux-link {
    color: var(--color-off-black);
    text-decoration: underline;
    padding-left: 0;
}

.cta-component .cta-component__text-note {
    font-size: 16px;
    font-weight: 400;
    @media(max-width:500px){font-size: 12px;}
}

.cta-component__cta-button {
    display: inline-block;
    height: unset;
    text-align: center;
    padding: 12px 20px;
    border-radius: 8px;
    border: none;
    background-color: var(--color-yellow);
    color: var(--color-off-black);
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 5px;
    cursor: pointer;

    &+.cta-component__text-note{font-size: 14px; color:#666;
        @media(max-width:500px){font-size: 12px;}
    }
}

.cta-component__cta-button:hover {
    background-color: var(--color-yellow);
}


.cta-component .tel-hours-expander summary {
    list-style: none;
    margin-top: 20px;
}

.cta-component .tel-hours-expander summary::-webkit-details-marker {
    display: none;
}

.cta-component .tel-hours-expander[open] summary svg {
    transform: rotate(180deg);
}

.cta-component .tel-hours-expander .cta-component__show-all {
    white-space: nowrap;
    padding: 0;
}

.cta-component .tel-hours-expander .cta-component__hours-open {
   display: block; padding: 0;
}

.cta-component .tel-hours-expander .feaux-link {
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: var(--color-off-black);
    padding-left: 0;    
    cursor: pointer;
    text-decoration: none;
    font-family: "Archivo" ,sans-serif;
}

.cta-component .tel-hours-expander .feaux-link.hide {
    display: none;
}

.cta-component .tel-hours-expander[open] .feaux-link.show {
    display: none;
}

.cta-component .tel-hours-expander[open] .feaux-link.hide {
    display: inline;
}

.cta-component .tel-hours-expander ul {
    max-width: 300px;
}

.cta-component .tel-hours-expander lil {
    display: flex;
    justify-content: space-between;
}

.cta-component .tel-hours-expander h5,
.cta-component .tel-hours-expander li * {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
}

.cta-component .note { font-size: 14px; font-style: normal; font-weight: 400; line-height: 20px; color:#666; }
.cta-component .content { position:relative; margin:0 0 24px; }

.cta-component .tel-hours-expander h5 {
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 2px;
}

.cta-component{
    .cta-component__inner{border: 4px solid var(--color-yellow-mid); border-radius: 8px; }
}

/* Base rules: Dark theme overides */
// .cta-component.theme-dark .cta-component__inner {
//     background-color: var(--kuoni-dark-gray);
// }

// .cta-component.theme-dark .cta-component__inner * {
//     color: var(--kuoni-white);
// }

// .cta-component.theme-dark .cta-component__cta-button {
//     background-color: var(--kuoni-white);
//     color: var(--kuoni-dark-gray);
// }

// .cta-component.theme-dark .cta-component__cta-button:hover {
//     background-color: #E3E4E5;
// }

// .cta-component.theme-dark svg:not(.svg-appointment) * {
//     stroke: var(--kuoni-white);
//     fill: var(--kuoni-dark-gray);
// }

// .cta-component.theme-dark svg.svg-appointment * {
//     fill: var(--kuoni-white);
// }

// .cta-component.theme-dark a.tel-num > span,
// .cta-component.theme-dark .feaux-link,
// .cta-component.theme-dark a.cta-component__text-default {
//     color: var(--kuoni-white);
// }

/* Component - Large */

.cta-component__large.container {margin: 80px auto 80px;
    @include mixins.mq(800){margin: 60px auto 60px;}
    @include mixins.mq(620){margin: 40px auto 40px;}
    @include mixins.mq(500){margin: 20px auto 20px;}
}

.cta-component__large .cta-component__inner {
    padding: 48px 16px 40px;
    margin: 40px auto 40px;
    @media (max-width: 63.99em) { padding: 20px 16px 20px;}
}

.cta-component__large .cta-component__title {
    margin-bottom: 32px;
    max-width: 762px;
    text-align: center;
    margin-bottom: 5px;
}

.cta-component__large .cta-component__section {
    flex: 0 1 378px;
    margin: 20px 0 40px;
    @media (max-width: 63.99em) {margin: 0 0 25px;}
}

.cta-component__large .tel-num > span {
    font-size: 28px;
    font-weight: 700;
    line-height: 43.2px;
    text-decoration: underline;
    margin-top: -4px;
    display: block;
}

.cta-component__large .tel-num > svg {
    position: absolute;
    top: 0;
    left: 0;
}

@media (max-width:63.99em) {
    .cta-component__large .cta-component__section.section-tel-num > p {
        margin-bottom: 8px;
        margin: 0 auto;
    }
}

@media (min-width:64em) {
    .cta-component__large .cta-component__inner {
        padding: 40px 21px;
        margin: 0 auto 0;
    }

    .cta-component__large .cta-component__title {
        font-size: 28px;
        line-height: 1.1;
        max-width: 770px;
        margin:0 auto;
        margin-bottom: 15px;
        letter-spacing: -0.01rem;
    }

    .cta-component__large .cta-component__section-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 40px
    }

    .cta-component__large .cta-component__section {
        flex: 0 1 378px;
        margin: 25px 0 0px;
    }

    .cta-component__large .cta-component__section .cta-component__text-default {
        font-size: 16px;
        line-height: 25.6px;
    }

    .cta-component__large .cta-component__section-title {
        font-size: 22px;
        line-height: 1.2;
        margin-bottom: 6px;
        padding-left: 0;
        gap:25px;
        position: relative;
        text-align: center;
    }

    .cta-component__large .cta-component__section-title > svg {
        position: absolute;
        top: 0;
        margin-right: 0;
        transform: translateX(-50%);
        left: 50%;
    }
}

/* Component - Thin */
.cta-component__thin .cta-component__inner {
    padding: 40px;
    @media (max-width: 63.99em) { padding: 20px 16px 20px;}
}

.cta-component__thin .cta-component__title {
    font-size: 24px;
    line-height: 28px;
    font-weight:700;
}



.cta-component__thin .cta-component__section-title {
    font-family: "Archivo";
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    margin-bottom: 4px;
}

.cta-component__thin .cta-component__section-title > svg {
    top: 9px;
}

.cta-component__thin .cta-component__section-title > svg.svg-speech {

}

.cta-component__thin .tel-num > span {
    font-size:24px;
    line-height: 1.1; text-decoration: underline;
}

@media (min-width:45em) {
    .cta-component__thin .cta-component__section-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;        
        gap: 8px
    }

    .cta-component__thin .cta-component__section {
        flex: 1 1 calc(50% - 20px);
    }
}

@media (min-width:64em) {
    .cta-component__thin .cta-component__inner {
        padding: 40px 21px;
    }

    .cta-component__thin .cta-component__title {
        font-size: 28px;
        font-weight: 400;
        line-height: 33.6px;
        text-align: center;
        letter-spacing:0;
        margin-bottom: 5px;
    }

    .cta-component__thin .cta-component__section-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: nowrap;
        gap: 40px
    }

    .cta-component__thin .cta-component__section {
        flex: 1 1 calc(25% - 20px);
    }

    .cta-component__thin .cta-component__section-title {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 27px;
        padding-left: 0;      
        position: relative;
        text-align: center;
        display:flex;
        align-items:center;
        justify-content:center;
        
    }

    .cta-component__thin .cta-component__section-title > svg {
        position: absolute;
        top: 0;
        margin-right: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .cta-component__thin a.tel-num,
    .cta-component__thin .cta-component__or,
    .cta-component__thin .cta-component__text-note,
    .cta-component__thin a.cta-component__text-default {
        padding-left: 0;
    }

   
    .cta-component__thin a.cta-component__text-default {
        font-size: 22px;
        line-height: 1.2;
        display: block;
        margin-bottom: 22px;
    }   

    .cta-component__thin a.tel-num > span{
        font-size: 28px; text-decoration: underline; display: block; margin-bottom: 20px;
    }

    .cta-component__thin .tel-hours-expander summary {
        margin-top: 4px;
        padding-left: 0;
        padding-top: 6px;
    }

    .cta-component__thin .cta-component__hours-open {       
        display: block;
    }

    .cta-component__thin .tel-hours-expander .tel-info {
        padding-left: 0;
    }

    .cta-component__thin a.tel-num > span {
        padding-left: 0;       
    }

    .cta-component__thin .cta-component__section-title > svg.svg-speech {
        transform: scale(0.96);
    }
}

@media (max-width:1024px) {
    .cta-component__thin .cta-component__section-wrapper {flex-direction: column; gap: 4px;
        .cta-component__section{ display: grid; justify-content: center;  width: 100%;
            .cta-component__section-title{
                svg{top:0;}
            }
        }
    }
}

/* Component - differnce */
.cta-component__difference .cta-component__inner {
    padding: 24px 16px 24px;
    margin: 0 auto 0px;
    background-color: white;
}

.cta-component__difference .cta-component__img {
    height: 240px;
    position: relative;
    background: #b0e6ff;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 16px;
}

.cta-component__difference .cta-component__img-location {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 999px;
    padding: 8px 12px;
    font-family: "Archivo";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.01em;
    text-align: left;
    display: flex;
    align-items: center;
}

.cta-component__difference .cta-component__img-location > svg {
    margin-right: 6px;
}

.cta-component__difference .cta-component__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 44.8px;
    letter-spacing: 0em;
}

.cta-component__difference .cta-component__list {
    list-style: none;
    padding: 0 0 0 14px;
    margin: 40px 0 0 0;
}

.cta-component__difference .cta-component__list li {
    background-image: linear-gradient(black 33%, rgba(255,255,255,0) 0%);
    background-position: left;
    background-size: 1px 12px;
    background-repeat: repeat-y;
    padding: 0 0 40px 32px;
    position: relative;
}

.cta-component__difference .cta-component__list li:last-child {
    background-image: none;
}

.cta-component__difference .cta-component__list li:before {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -5px;
    left: -14px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: var(--kuoni-dark-gray);
    color: red;
    font-family: "Magazine Grotesque", serif;
    font-size: 18px;
    font-style: italic;
    font-weight: 450;
    line-height: 1;
    text-align: left;
}

.cta-component__difference .cta-component__list li:nth-child(1):before {
    content: '1';
}

.cta-component__difference .cta-component__list li:nth-child(2):before {
    content: '2';
}

.cta-component__difference .cta-component__list li:nth-child(3):before {
    content: '3';
    font-size: 16px;
}

@media (min-width:64em) {
    .cta-component__difference .cta-component__inner {
        display: flex;
        justify-content: space-between;
        gap: 72px;
        padding: 24px 0 24px;
        margin: 0 auto 40px;
    }

    .cta-component__difference .cta-component__inner > * {
        flex: 1 1 calc(50% - 36px);
    }

    .cta-component__difference .cta-component__img {
        height: 510px;
        margin-bottom: 0;
    }

    .cta-component__difference .cta-component__title {
        font-size: 45px;
        font-weight: 400;
        line-height: 63px;
    }

    .cta-component__difference .cta-component__content {
        flex-direction: column;
        display: flex;
        justify-content: center;
    }
}

/* ad hoc margin ajdustments */
/* large -dark positioning */
.cta-component__large.theme-dark {
    margin:0 auto 40px;
    overflow: visible;
}

// @media (min-width:50em) {
//     .cta-component__large.theme-dark {
//         margin: 0 auto 40px;
//     }
// }

/* home page  positioning */
.KUON002-CTAS__home-page .accreditations {
    padding: 100px 0 40px;
}

.KUON002-CTAS__home-page .cta-component__thin .cta-component__inner {
    margin: 0 auto 40px;
    overflow: visible;
}

/* Holiday type */
.cta-component__difference.container .cta-component__inner {
    margin: 80px auto 40px;
}

.cta-component + .accom-list-server-rendered-cards .gray-bg {
    margin-top: 20px;
}

.cta-component.holiday-type-placement {
    margin-top: 52px;
}


.cta-component__section{
    button.btn{
        display: inline-block;
        height: unset;
        text-align: center;
        padding: 12px 20px;
        border-radius: 8px;
        border: none;
        background-color: var(--color-yellow);
        color: var(--color-off-black);
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 5px;
        cursor: pointer;
    
        &+.cta-component__text-note{font-size: 14px; color:#666;
            @media(max-width:500px){font-size: 12px;}
        }
        @media(max-width:500px){width: 100%;}
    }
}