@use "_mixins";
@use "_extends";
@use "_colors";
.newsletter-signup-large{display:flex; background-color:var(--color-yellow); margin: 80px 0; overflow: hidden; border-radius: 8px; max-height: 514px; height: 41vw;

    .newsletter-signup-cta{flex: 1; display: grid; align-content: center; gap:20px; padding: 20px 20px 20px 80px;
        h2{font-size: clamp(2.4rem, 2.8vw, 2.8rem); letter-spacing: 0.04rem; line-height: 1.1; color: var(--color-off-black);}
        p{font-size: clamp(1.4rem, 1.6vw, 1.6rem); line-height: 1.4;}
        .btns{justify-content: flex-start;
            .btn{padding: 8px 17px; margin-top: 7px; background-color: var(--color-off-black); border-radius: 8px; color: white; font-size: 1.6rem; font-weight: 700; width: auto;}
        }
    }
    .newsletter-image{flex:1;
        .img{height: 100%; position: relative; overflow: hidden;
            &:after{content: ""; top:0; right:0; bottom:0; left:0; position: absolute; background-image: url("../../images/icons/oval-mask-yellow.svg"); background-size: 258%; background-position: 48% center; background-repeat: no-repeat;}
        }
    }
    @media(max-width:950px){margin: 60px 0;
        .newsletter-signup-cta{padding: 40px;}
    }
    @media(max-width:800px){margin: 40px 0;
        .newsletter-signup-cta{padding: 20px;}
    }
    @media(max-width:570px){flex-direction: column; max-height:none; height: auto;  margin: 30px 0;
        .newsletter-signup-cta{text-align: center; padding: 32px 20px 24px; gap:12px;
            .btns{justify-content: center;}
        }
        .newsletter-image{flex:none; height: 95vw; //aspect-ratio: 1/1;
            .img{
                &:after{background-position: 49% 47%; background-size: 285%;}
            }
        }
    }
}

