@use "_mixins";
@use "_extends";
@use "_colors";

/* Content Blocks */
.content-block { padding:80px 0; 
    @include mixins.mq(500) { padding:40px 0; }
    .container { overflow: visible; }
}

/* Content Block Admin */
.wtp-edit { 
    .feefo-review-carousel-widget-service { background-image:url(/content/images/wtp-feefo.jpg); background-repeat: no-repeat; width: 100%; background-position: center; aspect-ratio: 16/9; max-height: 269px; }
    .content-block-awards { 
        .awards-grid { position: relative; text-align:left; }
        .cta { text-align:left; }
    }
    .content-block-uspthree { 
        section { 
            .usp-grid { display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 40px; 
                .item { border: 1px solid #CCC; border-radius: 4px; padding: 10px; }
            }
        }
    }
    .usp-panel { 
        .swiper-wrapper { padding-top: 35px; }
        .usp-panel-item { padding-top: 80px !important; overflow:visible !important; }
        .select { display:inline-block !important; width:auto !important; 
            .selector { display:inline-block !important; 
                .select { display:inline-block !important; }
            }
        }
    }
    .grid-panel { 
        .relly {
            &.image { position: absolute !important; left: 0; top: 0; right: 0; bottom: 0; }
            &.link { position: absolute !important; left: 75px; top: 10px; }
            a { display:none; }
        }
    }
    .content-block-suggested { 
        section { 
            button { padding:0 !important; height:auto !important;
                .linkeditor { left:0 !important; }
            }
        }
    }
    .collection-btn { text-align:left !important; }
    .content-block-destinations { 
        .container-wrapper { margin:0; padding:0; }
        .container { 
            section { grid-template-columns:1fr; gap:10px; }
        }
        .card { width: 412px !important; }
        .-list { display:none; }
    }
    .content-block-experts { 
        .cta-component__section-wrapper { position:relative;
            > .relly { width: 25%; border: 1px solid #CCC; padding: 10px; border-radius: 4px; margin-top: 20px; }
            .relly { 
                &.order-1 { order:1; }
                &.order-2 { order:2; }
                &.order-3 { order:3; }
                &.order-4 { order:4; }
            }
            .cta-component__section { 
                wtp-edit-props { right:0; }
            }
        }
    }
    
    .content-block-offers { 
        .swiper{
            .swiper-wrapper{display: grid !important; grid-template-columns: repeat(4, 1fr);}
        }
    }
    .content-block-multi { 
        .swiper{
            .swiper-wrapper{display: grid !important; grid-template-columns: repeat(3, 1fr);}
        }
    }
}

/* Admin styles */
.wtp-edit {
    /* disable header */
    header { position: static; pointer-events:none; }
    .content-block {
        section { 
            > wtpoption-control {
                .option { left:-15px !important; }
            }
            h1,h2,h3,h4,h5,h6 { display: inline-block; max-width:initial; padding:5px 10px; }
        }
        /* Sometimes we need to position relative the damn controls */
        .relly { position:relative; display: grid; }

        /* Default/Standard/Fallback/Baseline styles for entity picker type controls */    
        &.wtp-entities, .wtp-entities { position:relative; padding-top:32px; min-height:130px; background-color:#dce0e9; }
        .wtp-entity { width:300px; height:200px; border-radius: 0 0 5px 5px; margin:0 8px 8px 0; float:left; border:1px solid #ccc; position:relative; background-color:#e9e4f0;
            .badge { display:inline-block; position:absolute; top:32px; left:5px; background-color:#404040; min-width:10px; padding:3px 7px; font-size:12px; font-weight:bold; color:#fff; line-height:1; vertical-align:middle; white-space:nowrap; text-align:center; border-radius:10px; font-family:"Metropolis_Regular", Arial, Helvetica, sans-serif; }
            .image { object-position:50% 50%; object-fit:cover; width:100% !important; height:145px; padding:0; margin:0;
                &:not([src]), &[src=''] { visibility:hidden; }
            }
            .name { width:100%; height:40px; font-family:"Metropolis_Regular", Arial, Helvetica, sans-serif; margin:0; padding:8px 8px 38px 8px; text-align:center; background:#fff; font-size:18px; color:#2b4361; text-wrap:nowrap; overflow:hidden; }
        }

        /* Divs styles to look like a text input */
        .text-input-label { margin-bottom:2px; }
        .text-input { -moz-appearance:textfield; -webkit-appearance:textfield; background-color:white; background-color:-moz-field; border:1px solid darkgray; box-shadow:1px 1px 1px 0 lightgray inset; font:-moz-field; font:-webkit-small-control; margin-top:0; padding:6px 9px; height:30px; line-height:18px; overflow:hidden;
            &:before { background:none !important; border: 0 !important; }
        }
        .textarea-input { -moz-appearance:textfield-multiline; -webkit-appearance:textarea; resize:both; min-height:150px; box-shadow:inset 0px 1px 2px #666;
            .fr-view { min-height:150px; }
        }

        /* If second button, move below first */
        wtpoption-control:nth-of-type(2) .option { top: 40px !important; }

        .clear { display:block; clear:both; font-size:1px; height:0; line-height:1px; margin:0; padding:0; border:0; }

        /* Action buttons */
        .option { display: flex; align-items: center; justify-content: flex-start; cursor:pointer; left:-35px !important; top:0 !important;
            &.hide { 
                i { 
                    &.fa-question-circle { 
                        &:before { content: "\F070" !important; font-size: 18px !important; top: -1px; left: -1px; position: relative; }
                    }
                }
            }
            &.show { 
                i { 
                    &.fa-question-circle {
                        &:before { content: "\F06E" !important; font-size: 18px !important; top: -1px; left: -1px; position: relative; }
                    }
                }
            }
            i { margin: 0 0 0 7px !important; font-size: 20px !important; pointer-events: none; }
        }
        .repeater { cursor:pointer; top: 0 !important; left: -35px !important; text-align: left;
            i { margin: 9px 9px !important; font-size: 14px !important; pointer-events: none; }
        }
        .imagepicker { cursor:pointer; background-color:#5d4976 !important; text-align: left;
            &:hover { background-color:#5d4976 !important; }
            i { margin: 8px 7px !important; font-size: 14px !important; pointer-events: none; color: #CCC !important; }
        }
        .linkeditor { cursor:pointer; left:-67px !important; top:0 !important; text-align:left;
            i { margin: 7px 7px !important; font-size: 18px !important; pointer-events: none; }
        }
        .picker { cursor:pointer; text-align: left;
            i { margin: 6px 6px !important; font-size: 18px !important; pointer-events:none; }
        }
        .pickerentity { display:inline-block !important; }
        .repeatercontrol {
            .minus, .plus { background-color: transparent !important; outline: 0px solid #4d336c !important; color: #4d336c !important; 
                &:before { border:0 !important; background:transparent !important; }
            }
            .loop { outline: 1px solid #4d336c !important; border-radius:0 !important; }
        }
        .wtpEdit[field="text"] { cursor: text; }
        .wtp-card { background-color: #bbf; }
    }
    /* If has controls, add a padding to the top of the panels to accomdate */
    .templatecontent:has(wtp-select),
    .templatecontent:has(wtp-entity-picker) { padding-top: 30px; }
    /* Reposition tools */
    wtp-edit-props { display:flex; gap:1px; top:0;
        .select, .pickerentity { cursor:pointer; width:auto !important;
            i { display:none !important; }
            .selector { display: inline-block !important; padding: 7px 5px 5px 7px !important; 
                select { font-size: 12px !important; }
            }
        }
        .picker { cursor:pointer;
            i { display:none !important; }
            .pickerentity { display: inline-block !important; padding: 7px 5px 5px 7px !important; }
        }
    }
}

wtp-entity-picker { text-align: left; }

/* Show editables */
.wtpEditor_showEditables {
    .addpanel { position: sticky !important; bottom: 20px !important; z-index: 99999; width: 200px !important; left: calc(50% - 100px); }
    section {
        .wtpEdit.fr-box { padding:5px 10px; }
        .fr-counter { bottom: -25px !important; border: 0 !important; background: transparent !important; }
        h1,h2,h3,h4,h5,h6, div, p {
            &::before { background: #c3b5d55e !important; border: 1px dashed #000 !important; margin: 0 !important; opacity:1 !important; }
        }
    }
}

.content-blocks-main-content { padding-top:30px; }