@use "_mixins";
@use "_extends";
@use "_colors";

/* Conten Block USP three */
.content-block-uspthree { overflow:hidden; padding:64px;
    @include mixins.mq(500) { padding:40px 0; }
    .container { max-width: 1130px; }
    section { 
        h1 { @include mixins.font(2.2, 2.2, 26px, 700, 0,  #1A1A1A); text-align:center; margin:0 0 16px; 
            @include mixins.mq(860) { @include mixins.font(1.8, 1.8, 22px, 700, 0,  #1A1A1A);  }
        }
        h3 { @include mixins.font(4.4, 4.4, 48px, 700, 0,  #1A1A1A); text-align:center; margin:0 0 48px;
            @include mixins.mq(860) { @include mixins.font(2.8, 2.8, 32px, 700, 0,  #1A1A1A);  }
        }
        .usp-grid { overflow:visible;
            .item { display: flex; flex-direction: column; align-items: center; gap:12px; @include mixins.font(1.4, 1.4, 20px, 400, 0,  #1A1A1A); 
                @include mixins.mq(860) { width:250px; }
                .content { text-align: center; }
                .image { text-align:left; }
            }
        }
    }
}