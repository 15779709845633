@use "_mixins";
@use "_extends";
@use "_colors";
.newsletter-signup{display:grid; background-color:var(--color-yellow); margin: 80px 0; border-radius: 8px; max-height: 328px; min-height: 280px; height: 41vw;
    .newsletter-signup__box{display:grid; align-items:center; align-content: center; padding:20px; justify-content: center;
        .newsletter-signup__content{text-align: center;
            h5{@include mixins.font(2.4, 2.8, 1, 400, 0, var(--color-off-black), "Magazine Grotesque"); margin: 2px 0 20px;}
            p{@include mixins.font(1.4, 1.6, 1.4, 400, 0, var(--color-off-black)); margin-bottom: 24px;}
        }
        .newsletter-signup__controls{flex:1; display: flex; flex-direction: column; justify-content: center;
            .newsletter-signup__input{height:40px; text-align: center;
                input{height:100%; flex:1; border: 1px solid colors.$grey6; text-indent: 15px; padding-top:5px;}
                button{@include mixins.font(1.4, 1.6, 2rem, 700, 0, white); width: auto !important; background-color: var(--color-off-black); height:100%; padding:0 18px 0; border-radius:8px;}
            }            
            p{@include mixins.font(1.4, 1.4, 1.68rem, 400, 0, #666); text-align: center; margin-top: 8px;}
        }
    }

    @media(max-width:1000px){gap:20px; margin: 60px 0;
        .newsletter-signup__box{padding: 30px 20px;}
    }
    @media(max-width:800px){display: grid; gap:30px; justify-content: start; margin: 40px 0;}
    @media(max-width:570px){height: auto; max-height: none; min-height: auto; margin: 30px 0;}
}

