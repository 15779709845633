@use "_mixins";
@use "_extends";
@use "_colors";

.usp-panel{margin: 0 auto !important; padding: 25px !important;
    @media(min-width:767px) { padding:16px !important; }
    &.swiper{
        .swiper-wrapper{
            .usp-panel-item{display: grid; align-content: center; justify-items: center; gap: 10px; height: auto; border-radius: 8px; min-height: 184px; position: relative; overflow: hidden; padding: 20px;
                label, h2, a{z-index: 2; text-align: center;}
                label, h2{max-width: 250px;}
                label{font-size: clamp(1.2rem, 1.4vw, 1.4rem); color:white; text-transform: uppercase;}
                h2{font-size: clamp(1.8rem, 2.2vw, 2.2rem); color:white; text-transform: uppercase;}
                a.btn{font-size: clamp(1.6rem, 1.6vw, 1.6rem); border-radius: 8px; padding: 12px 16px; margin-top: 1px; font-weight: 700; color:var(--color-off-black); background-color: var(--color-yellow);
                    &.btn-color-white{ color: var(--color-off-black); background-color: #FFF;}
                    &.btn-color-black{ color: #FFF; background-color: var(--color-off-black);}
                    &.btn-color-sale{ color: var(--color-indigo); background-color: #FFF;}
                }
                .txt-color-white{color: #fff;}
                .txt-color-black{color: var(--color-off-black);}
                .txt-color-sale{color: var(--color-indigo);}
                &:before{content: ""; position: absolute; inset:0; background-color: rgba(black, 0.2); z-index:1;}
            }
        }
        @media(min-width:991px){ 
            .swiper-wrapper{display: grid; grid-template:214px / repeat(12, 1fr); grid-auto-rows: 214px; gap: 24px; max-width: 1410px; margin:0 auto;
                .usp-panel-item{flex-shrink: 1; gap: 12px;}

                &.grid-1{
                    .usp-panel-item{grid-column: 1 / -1;}
                }

                &.grid-2{
                    .usp-panel-item{grid-column: span 6;}
                }

                &.grid-3, &.grid-6{
                    .usp-panel-item{grid-column: span 4;}
                }

                &.grid-4{
                    .usp-panel-item{grid-column: span 3;}
                }

                &.grid-5{
                    .usp-panel-item{grid-column: span 4;
                        &:nth-of-type(1){grid-column: 1 / 7;}
                        &:nth-of-type(2){grid-column: 7 / 13;}
                    }
                }

                .swiper-slide{
                    .deadcenter.imagepicker{top: 15px; right: -14px; left:auto; z-index: 3;}
                }
            }
        }
    }
}




