@use "_mixins";
@use "_extends";
@use "_colors";
.cards{display: grid;
    &.two-col{grid-template-columns: repeat(2, 1fr); gap: 55px 37px;}
    &.three-col{grid-template-columns: repeat(3, 1fr); gap:55px 37px;}
    &.four-col{grid-template-columns: repeat(4, 1fr); gap:50px 36px;
        .card{
            .card-title{
                p{font-family: "Magazine Grotesque", sans-serif; font-weight:400;}
            }            
        }
    }
    .card{transition:0.3s ease-in-out;
        a{color:inherit;}        
        .card-image{position: relative; min-height: 200px; border-radius: 8px; background-position: center; background-size: cover;
            &>img{object-fit: cover; @include mixins.bgRatio(200, 242, 40);}
            .tag{background:#E66E61; z-index: 2; display:flex; position:absolute; top:10px; left:10px; align-items: center; margin:0; border-radius: 8px; overflow: hidden;
                label{padding:7px 8px 6px; @include mixins.font(1.4, 1.4, 1.4rem, 700, 0.01rem, white); margin:0; font-family: "Magazine Grotesque", sans-serif; text-transform: capitalize;}               
            }
            img{width: 100%;}
            .offer-box{position: absolute; top:25px; left:25px; background-color: white; border-radius: 8px; padding: 5px 8px; width: 130px; 
                label{font-size: 1.4rem; line-height: 1.6rem;; color: black; font-weight: 700; margin: 0; font-family: "Magazine Grotesque", sans-serif;}
                &.purple{background-color: var(--color-indigo);; top: 10px; left: 9px; width:auto; max-width: calc(100% - 20px);
                    label{color:white;}                    
                }
            }
            .type-box{position: absolute; top:21px; left:21px; background-color: white; padding: 9px 16px;
                label{font-size: 1.4rem; line-height: 1.6rem; color: black; font-weight: 700; margin: 0; }
            }
            .offer-circle{position: absolute; top:14px; left:22px; height: 80px; width: 80px; background-color: var(--color-off-black); border-radius: 100%; display: flex; align-items: center; justify-content: center; text-align: center; padding: 0 17px;
                label{font-size: 1.3rem; line-height: 1.4rem; color: white; font-weight: 400; margin: 0;}
            }
        }       
        .card-title{display: flex; justify-content: space-between; gap: 8px; margin: 15px 0 0;
            ul{display: flex; gap:1px 10px; @include mixins.font(1.2, 1.4, 1.4rem, 400, 0, var(--color-off-black)); margin-bottom: 9px; flex-wrap:wrap;
                li{text-transform: uppercase;
                    span{font-size: clamp(18px, 2.6vw, 2.6rem); line-height: 0; display: inline-flex; align-items: center; position: relative; top: 3px;}
                }
            }
            p{font-size: clamp(2.4rem, 2.8vw, 2.8rem); font-weight: 700; color: var(--color-off-black); letter-spacing: -0.01rem; display: flex; line-height: 1.1;}
            img{width: 58px; align-self: flex-start; margin-top: 3px;}
        }   
        .event-date{order:2; display:flex; align-items:center; margin: 8px 0 4px;; gap:17px;
             li{@include mixins.font(1.4, 1.4, 2rem, 400, 0, colors.$grey3);
                &:first-child{position:relative;
                    &:after{content:""; position:absolute; top:50%; right:-12px; transform:translate(-50%, -70%); height:3px; width:3px; background: colors.$grey3; @extend %br-100;} 
                }
             }
        }
       .description{font-weight: 400; font-size:clamp(1.4rem, 1.6vw, 1.6rem);; margin-top: 12px; line-height: 1.4;}
        label{@include mixins.font(1.8, 2, 2rem, 700, -0.01rem, var(--color-off-black)); display: flex; align-items: center; gap:13px; margin-top: 14px; cursor:pointer;
            s{color:var(--color-off-black); font-weight: 400;  font-size: 1.4rem; line-height: 18px; padding-top: 2px;}
        }
        small{font-size:clamp(1.4rem, 1.6vw, 1.6rem); line-height: 1.4; color:var(--color-off-black); letter-spacing: -0.01rem; display: block; margin-top: 10px;}
        a{
            &.card-cta{display: flex; gap:10px; font-size: 1.4rem; letter-spacing: -0.01rem; color: colors.$lightBlue; align-items: center;  margin-top: 20px; line-height: 1.3rem; text-transform:uppercase;
                img{width: 10px; padding-top: 3px; position:relative; top:-1px;}
            }
        }
        &.variable-content{
            a{display:flex; flex-direction:column;
                .card-image{order:0; background-size: cover; border-radius: 8px; overflow: hidden;}
                .card-title{order:1; margin: 15px 0 0 !important;
                    ul{
                        &.rating{margin-bottom: 10px; gap:5px 33px; overflow: hidden;
                            li{text-transform:none; letter-spacing:0.04rem; font-weight:500;
                               &:not(:first-child) {text-transform:uppercase; position:relative;
                                    &:after{content:""; position:absolute; top:50%; left:-17px; transform:translate(-50%, -50%); height:5px; width:5px; background:var(--color-off-black); @extend %br-100;} 
                               }
                            }
                        }
                    }
                    p{@include mixins.font(2.4, 2.8, 1.2, 700, 0, var(--color-off-black), "Magazine Grotesque");
                    
                    }
                }
                label{order:2; margin: 6px 0 7px;}
                small{order:3; margin-top: 5px;}
                div.description{ p{ order:1; margin-top: 8px; @include mixins.font(1.6, 2, 2.8rem, 400, 0.01rem, var(--color-off-black));} }
                p{
                     &.description{order:1; margin-top: 8px; @include mixins.font(1.6, 2, 2.8rem, 400, 0.01rem, var(--color-off-black));
                        &.small{font-size:clamp(1.4rem, 1.6vw, 1.6rem); line-height: 1.4;}
                         @include mixins.mq(860){margin-top:5px !important;}
                     }
                }                
                 &.card-cta{flex-direction:row;}            
            }
        }
        &.social-card{border:1px solid #D6D6D6; align-self: flex-start; padding-bottom: 16px;
            .card-header{display:flex; gap: 9px; padding: 11px 8px; align-items:center;
                label{@include mixins.font(1.4, 1.4, 1.32rem, 700, 0, var(--color-off-black)); margin-bottom: 3px;}
                span{@include mixins.font(1.4, 1.4, 1.32rem, 700, 0, var(--color-off-black));}
            }
             .card-title{display:none;
                ul{display:none;}
            }
             .social-controls{order:5; padding:0 19px 0 13px;
                .social-icons{display:flex; gap:7px; align-items:center; justify-content: space-between;}
                 ul{display:flex; gap:9px; align-items:center; margin:14px 0 15px;
                     li{}
                 }
                 label{order:6; @include mixins.font(1.4, 1.4, 1.432rem, 700,0, var(--color-off-black));}
             }
             p{
                 &.description{
                     &.small{padding: 0 13px; color:var(--color-off-black);font-size:clamp(1.4rem, 1.6vw, 1.6rem); line-height: 1.4; margin-top:16px !important;}                     
                 }
             }            
        }
         &.variable-width{
            &>a{               
                &>.card-image{order:inherit;}
                div.description{ p{font-size:1.6rem; line-height:1.4; color:var(--color-off-black); margin-top: 10px; } }
                p{
                    &.description{font-size:1.6rem; line-height:1.4; color:var(--color-off-black); margin-top: 10px;}                   
                }
                .card-title{margin: 2px 0 0;
                    p{@include mixins.font(2.4, 2.8, 1.2, 700, 0, var(--color-off-black), "Magazine Grotesque");}
                }
            }
        }
       
        &.has-overlay{grid-column:span 2;
            &>a{position:relative;
                &>.card-overlay{position:absolute; bottom:25px; left:25px; background:white; max-width:404px; padding:22px 24px;
                    .card-title{margin-top:0;
                        ul{margin-top:0; margin-bottom:10px;}
                        p{@include mixins.font(2.4, 2.8, 1.2, 700, 0, var(--color-off-black), "Magazine Grotesque");}
                    }
                    div.description{ p{ font-size:1.6rem; line-height:1.4; margin-top: 13px; color:var(--color-off-black); } }
                    p{
                        &.description{font-size:1.6rem; line-height:1.4; margin-top: 13px; color:var(--color-off-black);
                            @include mixins.mq(860){font-size:1.4rem; line-height: 2.42rem;}
                        }
                    }
                }             
            }
        }

        &.span-two{grid-column: span 2;
            @include mixins.mq(600){grid-column: span 1;}           
        }

        &.hide{opacity:0; pointer-events:none; transform:translateY(4px); display:none;}
 
    }
    @include mixins.mq(1160){
        &.four-col{grid-template-columns: repeat(3, 1fr);}
    }
    @include mixins.mq(900){grid-template-columns: repeat(2, 1fr); gap:28px 24px;}
    @include mixins.mq(860){
         &.three-col{grid-template-columns: repeat(2, 1fr); gap:30px;}
         &.two-col{gap:60px 30px;}
         &.four-col{grid-template-columns: repeat(2, 1fr); gap:40px 30px;}
    }
    @include mixins.mq(720){
        .card{
            &.has-overlay{
                &>a{
                    &>.card-overlay{position:relative; bottom: 0; left:0; margin-top: -140px; max-width:none; padding-bottom:5px; 
                        .card-title{}
                    }
                    &>.card-image{margin-left: -42px; width: calc(100% + 82px);}
                }
            }
        }
    }
    @include mixins.mq(620){margin-top: 50px;
        .card{
            .card-image{
                .offer-box{top:15px; left: 15px;}
                .type-box{top:15px; left: 15px;}
                .offer-circle{top:10px; left: 10px; height: 70px; width: 70px;
                    label{font-size: 1.4rem; line-height: 1.4rem;}
                }
            }
            &.has-overlay{
                &>a{
                    &>.card-overlay{
                        .card-title{}
                    }
                    &>.card-image{margin-left: -30px; width: calc(100% + 60px);}
                }
            }
        }
        &.four-col{grid-template-columns: 1fr;}
    }
    @include mixins.mq(550){grid-template-columns: 1fr; gap:45px 0; 
        &.two-col{grid-template-columns: 1fr; gap:40px 0;}
    }
    @include mixins.mq(520){
        &.three-col{grid-template-columns: 1fr; gap:40px 0;
            .card{
                .card-image{
                   img{max-height:375px;}                    
                }  
                &.has-overlay{grid-column: span 1;}
            }
        }
    }  

    @include mixins.mq(374){
        .card{
            &.has-overlay{
                &>a{
                    &>.card-overlay{
                        .card-title{}
                    }
                    &>.card-image{margin-left: -20px; width: calc(100% + 40px);}
                }
            }
        }
    }
    &.no-filters{ margin-top: 26px; }
    &.has-overlap{
        .card{
            &>a{
                &>div{
                    &.card-image{order:inherit;}
                    &:last-child{position:relative;
                        .card-title{
                            p{font-family: "Magazine Grotesque", serif; font-size:clamp(2.4rem, 2.8vw, 2.8rem); line-height: 1.1; color: var(--color-off-black);}
                          
                            ul{margin-bottom: 8px;}
                        }
                        &.offer{margin-right:60px;
                            .card-title{display:block;
                                p{@include mixins.font(2.4, 2.8, 1.4); color: var(--color-off-black); font-family: "Magazine Grotesque", serif; margin: 0px 0 0px; line-height: 1.4; color: var(--color-off-black);}
                                ul{
                                    &.rating{
                                        li{color:var(--color-off-black); font-size:1.4rem;}
                                    }
                                }
                            }
                            small{@include mixins.font(1.8, 1.8, 2.5rem, 700, 0, var(--color-off-black)); display: block; margin: 2px 0 12px;}
                            label{@include mixins.font(1.4, 1.4, 1, 400, 0.09rem, white); background-color: var(--color-off-black); display:inline-block; padding: 10px; margin: 19px 0 14px; text-transform: lowercase !important; border-radius: 8px;}
                            p{@include mixins.font(1.4, 1.6, 1.4, 400, 0,  var(--color-off-black)); margin-bottom: 5px;
                              &.based-on{color: colors.$grey3; font-size:1.4rem; line-height:1.9rem;}
                            }
                            span{@include mixins.font(1.6, 1.8, 2.3rem, 700, 0,  var(--color-off-black)); display:block; padding-bottom: 15px; border-bottom: 1px solid #e3e4e5;}
                        }
                        @include mixins.mq(900){margin-top: 0; padding: 8px 0 30px!important; width: 100% !important;}
                        @include mixins.mq(520){max-width: none; margin-right: 29px; padding-left: 30px;}
                        @include mixins.mq(374){padding-left: 20px;}
                    }
                    &.collection{background:#03AAC9; padding:20px;
                        .collection-title{display:flex; align-items:center; margin-bottom:13px; gap: 8px;
                            label{@include mixins.font(1.1, 1.1, 1rem, null, null, white); text-transform:uppercase;}
                        }
                        .card-title{display:block; margin-top:0;
                            p{@include mixins.font(2.4, 2.8, 1.1, null, 700, white, "Magazine Grotesque"); font-weight: 400;
                                &.description{@include mixins.font(1.4, 1.4, 2.2rem, 400, null, white);}
                            }
                        }
                        .collection-tags{display:flex; flex-wrap:wrap; margin-top: 20px;
                            li{@include mixins.font(1.4, 1.4, 1rem, null, null, white); text-transform:uppercase;}
                        }
                    }
                    &.simple-card{display:flex; flex-direction:column;
                        .card-title{order:1;
                            p{@include mixins.font(2.4, 2.8, 1.4); font-family: "Magazine Grotesque", serif; margin: 0px 0 11px; color: var(--color-off-black);}
                            ul{
                                &.rating{
                                    li{color: var(--color-off-black); font-size:1.4rem; text-transform: uppercase;}
                                }
                            }
                        }
                       
                        p{
                            &.description{order:2; font-size:1.6rem; line-height:1.4; color: var(--color-off-black); word-break: break-word;}
                        }
                        label{color: var(--color-off-black); font-size:1.8rem;}
                    }
                }
            }  
            
            // &:first-child{
            //     @include mq(520){margin-left: -30px; width: calc(100% + 60px);
            //         &>a{
            //             .simple-card{padding-left: 30px !important;}
            //         }
            //     }
            //     @include mq(374){margin-left: -20px; width: calc(100% + 40px);
            //         &>a{
            //             .simple-card{padding-left: 20px !important;}
            //         }
            //     }      
            // }   

            
            .collection-title, .card-title, .collection-tags{z-index: 2; position: relative;}


        }

    }
}

//some pages have the same cards, but smaller content/sizes/fonts
.small-cards{
    .accom-list-server-rendered-cards{
        .show-more-button{text-align: center; margin-top: 50px;
            .btn{background-color: white; border: solid var(--color-off-black) 1px; display: inline-block; color: var(--color-off-black); padding:11px 12px 12px; border-radius: 8px; font-size: 1.6rem; font-weight: 700; cursor:pointer;
                &.hide{display: none;}
            }           
        }
       
        .container{
            .cards{
                &.three-col{
                    .card{
                        .card{                           
                            .simple-card{
                                .card-title{
                                    p{font-size: clamp(1.8rem, 2.2vw, 2.2rem);}
                                }
                                label{color: var(--color-indigo); font-size: clamp(1.8rem, 1.86vw, 1.8rem);}
                                small{font-size: clamp(1.4rem, 1.6vw, 1.6rem); color: var(--color-off-black);}
                            }
                        }
                    }
                }
            }
        }
    }
}