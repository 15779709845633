@use "_mixins";
@use "_extends";
@use "_colors";

header{background:white; position:sticky;
    .container{
        svg{
            path{fill: var(--color-off-black);}
        }
        nav{
            ul{
                li{
                    @media(min-width: 761px){color:var(--color-off-black);
                        a{color:var(--color-off-black);}
                    }
                }                
            }
        }
        ul{
            &.header__cta{
                li{color:var(--color-off-black);
                   &.phone-number{ 
                       .light-icon{ display:none; }
                       .dark-icon{ display:block; }
                    }
                    a{color:var(--color-off-black);}
                    &#mobile-menu-trigger{
                        svg{
                            line{stroke:var(--color-off-black);}
                        }
                    }
                }
            }
        }
        nav{
            &#site-nav{
                &>ul{
                    li{
                        a{color:var(--color-off-black);}
                    }
                }
            }
        }
    }
}