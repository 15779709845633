@use "_mixins";
@use "_extends";
@use "_colors";
.wysiwyg {

    /*!
     * froala_editor v2.9.3 (https://www.froala.com/wysiwyg-editor)
     * License https://froala.com/wysiwyg-editor/terms/
     * Copyright 2014-2019 Froala Labs
     */
    // The below has been copy/pasted from \src\Kuoni.Wtp\node_modules\froala-editor\css\froala_style.css
    // as a basis for styling the things admins can do to content in the backend wysiwyg editors

    word-wrap: break-word;

    .clearfix::after { clear:both; display:block; content:""; height:0; }
    .hide-by-clipping { position:absolute; width:1px; height:1px; padding:0; margin:-1px; overflow:hidden; clip:rect(0, 0, 0, 0); border:0; }

    img.fr-rounded, .fr-img-caption.fr-rounded img { border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-background-clip: padding;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }

    img.fr-bordered,
    .fr-img-caption.fr-bordered img {
      border: solid 5px #CCC;
    }

    img.fr-bordered {
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
    }

    .fr-img-caption.fr-bordered img {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    img.fr-shadow,
    .fr-img-caption.fr-shadow img {
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
    }

    span[style~="color:"] a {
        color: inherit;
    }

    strong {
      font-weight: 700;
    }

    table {
      border: none;
      border-collapse: collapse;
      empty-cells: show;
      max-width: 100%;
    }
    table td {
      min-width: 5px;
    }
    table.fr-dashed-borders td,
    table.fr-dashed-borders th {
      border-style: dashed;
    }
    table.fr-alternate-rows tbody tr:nth-child(2n) {
      background: #f5f5f5;
    }
    table td,
    table th {
      border: 1px solid #dddddd;
    }
    table td:empty,
    table th:empty {
      height: 20px;
    }
    table td.fr-highlighted,
    table th.fr-highlighted {
      border: 1px double red;
    }
    table td.fr-thick,
    table th.fr-thick {
      border-width: 2px;
    }
    table th {
      background:#FDF8F3;
    }

    table.fr-no-borders td,
    table.fr-no-borders th {
      border:none;
    }

    table.fr-no-cell-padding td,
    table.fr-no-cell-padding th {
      padding:0;
    }

    table.fr-centered {
        margin:auto;
        width:auto !important;
    }

    table.fr-full-width {
        width:100% !important;
    }

    hr {
      clear: both;
      user-select: none;
      -o-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      page-break-after: always;
    }
    .fr-file {
      position: relative;
    }
    .fr-file::after {
      position: relative;
      content: "\1F4CE";
      font-weight: normal;
    }
    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow: visible;
    }

    blockquote{margin-inline:0;}

    span.fr-emoticon {
      font-weight: normal;
      font-family: "Apple Color Emoji", "Segoe UI Emoji", "NotoColorEmoji", "Segoe UI Symbol", "Android Emoji", "EmojiSymbols";
      display: inline;
      line-height: 0;
    }
    span.fr-emoticon.fr-emoticon-img {
      background-repeat: no-repeat !important;
      font-size: inherit;
      height: 1em;
      width: 1em;
      min-height: 20px;
      min-width: 20px;
      display: inline-block;
      margin: -0.1em 0.1em 0.1em;
      line-height: 1;
      vertical-align: middle;
    }
    .fr-text-gray {
      color: #AAA !important;
    }
    .fr-text-bordered {
      border-top: solid 1px #222;
      border-bottom: solid 1px #222;
      padding: 10px 0;
    }
    .fr-text-spaced {
      letter-spacing: 1px;
    }
    .fr-text-uppercase {
      text-transform: uppercase;
    }
    .fr-class-highlighted {
      background-color: #ffff00;
    }
    .fr-class-code {
      border-color: #cccccc;
      border-radius: 2px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-background-clip: padding;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      background: #f5f5f5;
      padding: 10px;
      font-family: "Courier New", Courier, monospace;
    }
    .fr-class-transparency {
      opacity: 0.5;
    }
    img {
      position: relative;
      max-width: 100%;
    }
    img.fr-dib {
      margin: 5px auto;
      display: block;
      float: none;
      vertical-align: top;
    }
    img.fr-dib.fr-fil {
      margin-left: 0;
      text-align: left;
    }
    img.fr-dib.fr-fir {
      margin-right: 0;
      text-align: right;
    }
    img.fr-dii {
      display: inline-block;
      float: none;
      vertical-align: bottom;
      margin-left: 5px;
      margin-right: 5px;
      max-width: calc(100% - (2 * 5px));
    }
    img.fr-dii.fr-fil {
      float: left;
      margin: 5px 5px 5px 0;
      max-width: calc(100% - 5px);
    }
    img.fr-dii.fr-fir {
      float: right;
      margin: 5px 0 5px 5px;
      max-width: calc(100% - 5px);
    }
    span.fr-img-caption {
      position: relative;
      max-width: 100%;
    }
    span.fr-img-caption.fr-dib {
      margin: 5px auto;
      display: block;
      float: none;
      vertical-align: top;
    }
    span.fr-img-caption.fr-dib.fr-fil {
      margin-left: 0;
      text-align: left;
    }
    span.fr-img-caption.fr-dib.fr-fir {
      margin-right: 0;
      text-align: right;
    }
    span.fr-img-caption.fr-dii {
      display: inline-block;
      float: none;
      vertical-align: bottom;
      margin-left: 5px;
      margin-right: 5px;
      max-width: calc(100% - (2 * 5px));
    }
    span.fr-img-caption.fr-dii.fr-fil {
      float: left;
      margin: 5px 5px 5px 0;
      max-width: calc(100% - 5px);
    }
    span.fr-img-caption.fr-dii.fr-fir {
      float: right;
      margin: 5px 0 5px 5px;
      max-width: calc(100% - 5px);
    }

    .fr-video {
      text-align: center;
      position: relative;
    }
    .fr-video.fr-rv {
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
    }
    .fr-video.fr-rv > iframe,
    .fr-video.fr-rv object,
    .fr-video.fr-rv embed {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .fr-video > * {
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      max-width: 100%;
      border: none;
      aspect-ratio: 16/9;
      height: auto;
    }
    .fr-video.fr-dvb {
      display: block;
      clear: both;
    }
    .two-video { display: grid; width: 100%; gap: 40px; grid-template-columns: 1fr 1fr; margin-bottom: 60px;
        @media(max-width:720px) { grid-template-columns:1fr; }
        .fr-video { margin-bottom:20px; }
    }
    .fr-video.fr-dvb.fr-fvl {
      text-align: left;
    }
    .fr-video.fr-dvb.fr-fvr {
      text-align: right;
    }
    .fr-video.fr-dvi {
      display: inline-block;
    }
    .fr-video.fr-dvi.fr-fvl {
      float: left;
    }
    .fr-video.fr-dvi.fr-fvr {
      float: right;
    }
    a.fr-strong {
      font-weight: 700;
    }
    a.fr-green {
      color: green;
    }
    .fr-img-caption {
      text-align: center;
    }
    .fr-img-caption .fr-img-wrap {
      padding: 0px;
      display: inline-block;
      margin: auto;
      text-align: center;
      width: 100%;
    }
    .fr-img-caption .fr-img-wrap img {
      display: block;
      margin: auto;
      width: 100%;
    }
    .fr-img-caption .fr-img-wrap > span {
      margin: auto;
      display: block;
      padding: 5px 5px 10px;
      font-size: 14px;
      font-weight: initial;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-opacity: 0.9;
      -moz-opacity: 0.9;
      opacity: 0.9;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      width: 100%;
      text-align: center;
    }
    button.fr-rounded,
    input.fr-rounded,
    textarea.fr-rounded {
      border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-background-clip: padding;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }
    button.fr-large,
    input.fr-large,
    textarea.fr-large {
      font-size: 24px;
    }

    /* List styles */
    li { margin-bottom:1em; }
    ul { margin:revert; padding:revert;
         li { list-style:inherit; }
    }

    ol { 
        &[type="1"], &:not([type]) { counter-reset:item; list-style-type:decimal;
            > li { display:block;
                &:before { content:counters(item, ".") " "; counter-increment:item; font-weight:700; }
            }
        }
        &[type]:not([type="1"]), 
        &[style="list-style-type: lower-alpha;"], &[style="list-style-type: upper-alpha;"], 
        &[style="list-style-type: lower-roman;"], &[style="list-style-type: upper-roman;"],
        &[style="list-style-type: lower-greek;"] {
            > li { display:list-item;
                &:before { content:unset; counter-increment:none; }
                &::marker { font-weight:700; }
            }
        }
    }
}

/* Froala toolbar should render above 'wtpinactiveonedit' */
.fr-toolbar { z-index:1000000000 !important; 
    .fr-dropdown-menu { z-index:1000000010 !important; }
}

/* Froala page edit sections always need some height so the admins can click into them */
wtp-page {
    .fr-view { min-height:58px; width:100%; }
    h1, h2, h3, h4, h5, h6 {
        .fr-wrapper { width:100%; }
    }
}