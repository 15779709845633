@use "_mixins";
@use "_extends";
@use "_colors";
.card-carousel{position:relative; 
    .staff-swiper{padding-bottom: 30px;
        .swiper-wrapper{ 
            @include mixins.mqmin(1151){justify-content: center;}
        }
    }
   
    .card-carousel__card{width:232px; overflow:hidden;
        .card-carousel__image{height:150px !important; width: 150px; margin: 0 auto; border-radius: 100%; margin-bottom: 20px;
            img{@extend %fill;}
        }       
        .card-carousel__details{background-color:white;
            h5{@include mixins.font(2.4, 2.4, 1.2, 700, 0, var(--color-off-black)); text-align: center; margin-bottom: 4px;}
            label{@include mixins.font(1.4, 1.4, 1.2, 400, null, var(--color-off-black)); display:block; text-align: center;}
            span{@include mixins.font(1.4, 1.4, 1.2, 700, null,  var(--color-off-black)); display: block; margin: 23px 0 2px;}
            p{@include mixins.font(1.4, 1.4, 1.4, 400, null, var(--color-off-black)); margin-bottom:22px;}
            a{@include mixins.font(1.2, 1.2, 1.1rem, 600, -0.01rem,  var(--color-off-black)); display:flex; gap:13px; align-items:center; max-width: 180px; cursor:pointer;}
        }    
    }    
    &+.swiper-button-next+.swiper-button-prev, &+.swiper-button-next {@extend %swiper-button; margin-top:0; top: 228px;        
 
    }
    &+.swiper-button-next{right:-20px;
        @include mixins.mq(374){right:-18px;}
    }
    &+.swiper-button-next+.swiper-button-prev{left:-20px; @extend %swiper-button-prev;                
        @include mixins.mq(374){left:-18px;}
    }
}

.card-carousel-wrapper{
    position:relative;
}