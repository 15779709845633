@use "_mixins";
@use "_extends";
@use "_colors";

footer {background: black; margin-top: 60px;
    .footer-navigation {
        display: flex;
        padding: 79px 0 0px;
        
        &__left {
            display: flex;
            gap: 30px;
            flex: 1;
            border-right: 1px solid rgba(white, 0.2);
            padding-right: 30px;

            &>div{flex:0 1 180px;
                p {
                    @include mixins.font(1.4, 1.6, 2.24rem, null, null, white);
                    margin: 0;                  
                }
                @include mixins.mq(550){flex:none;}
            }

            h6, .h6 {
                @include mixins.font(1.4, 1.6, null, 700, null, white, "Magazine Grotesque");               
                font-weight: 700;
                font-size: 1.6rem;
                text-transform: uppercase;
                text-align: left;
                padding-bottom: 18px;
                letter-spacing: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                &.active {
                    img {
                        transform: rotate(-180deg);
                    }
                }
                
                @media(max-width:550px) {
                    &+.list-links{transition: 0s;}
                    &.active {
                        &+.list-links{padding-bottom: 20px;}
                    }
                }

                @media(min-width:551px) {
                    cursor: default;

                    img {
                        display: none;
                    }
                }
            }

            ul.list-links {
                display: flex;
                flex-direction: column;
                gap: 18px;
                transition: 0.2s;

                summary { list-style: none; }

                li {
                    @include mixins.font(1.4, 1.6, null, null, null, white);

                    a {
                        color: inherit;
                        font-size: 1.6rem;
                       
                    }

                    ul {
                        margin-top: 16px;
                        display: flex;
                        flex-direction: column;
                        gap: 21px;
                        transition: 0.2s;

                        li{
                            a{
                                svg{transform: translateY(3px); margin-right: 3px;}
                            }
                        }
                    }

                    .tel-num-value {font-size: 16px; font-weight: 700; text-transform:uppercase; font-family: "Magazine Grotesque",sans-serif; margin-bottom: 8px;}

                    .tel-block{margin-top: 22px; padding-top: 20px; border-top: 1px solid white; display: grid; gap: 5px;
                        .tel-num{font-size: 26px; font-weight: 700; text-transform:uppercase; font-family: "Magazine Grotesque",sans-serif; margin:6px 0 8px;
                            @include mixins.mq(710) { text-decoration:underline; }
                        }
                        .tel-hours_all{
                            summary{cursor: pointer; margin-bottom: 7px;
                                svg{transform: translateY(4px);}
                            }
                        }
                    }

                    .footer-ctas-ul{
                        li{
                            a{text-decoration: underline;}
                        }
                    }
                }

                .footer-link_video-apnt, .footer-link_store-apnt {
                    @include mixins.font(1.4, 1.4, null, null, null, white);
                }

                @include mixins.mq(550) {
                    border-bottom: 1px solid rgba(white, 0.2);
                    height: 0;
                    overflow: hidden;
                }
            }

            .our-stores {
                flex: 0 1 327px;
                margin-right: 30px;

                h5 {
                    font-weight: 700;
                    text-transform: uppercase;
                    @include mixins.font(1.4, 1.4, null, null, null, var(--color-yellow));
                    margin-top: 3px;
                }

                p {
                    @include mixins.font(1.4, 1.4, 2.24rem, null, null, #C8CACC);
                    margin: 16px 0 4px;
                }

                a {
                    @include mixins.font(1.6, 1.6, null, null, null, white);

                    &.find-nearest {
                        margin-top: 26px;
                        gap: 20px;
                        display: flex;
                        align-items: center;
                    }
                }
            }

            @include mixins.mq(1180) {
                border-right: none;
                //border-bottom: 1px solid rgba(white, 0.2);
                padding: 60px;
                margin-bottom: 60px;
                padding-inline: 0;
                margin-right: 0;

                .our-stores {
                    margin-right: 0;
                    flex: 0 0 235px;
                }
            }

            @include mixins.mq(710) {
                flex-wrap: wrap;
                padding-bottom: 30px;
                margin-bottom: 30px;

                .our-stores {
                    width: 100%;
                    flex: none;
                }
            }

            @include mixins.mq(550) {
                flex-direction: column;
                gap:18px;
            }
        }

        &__right {
            flex: 0 0 345px;
            padding-left: 51px;

            ul {
                display: flex;
                align-items: center;
                gap: 23px;
            }

            h6 {
                @include mixins.font(2, 2, null, null, 0, white, "Magazine Grotesque");
                text-align: left;
                margin: 25px 0 8px;
            }

            p {
                @include mixins.font(1.2, 1.6, 1.4, null, 400, white);
            }

            @include mixins.mq(1300) {
               
                flex: 0 0 300px;
            }

            @include mixins.mq(1180) {
                display: flex;
                flex: none;
                flex-wrap: wrap;
                align-items: center;              
                gap: 10px 0;
                padding-left: 0;
                ul {
                    margin-bottom: 20px;

                    li {
                        a {
                            img {
                                max-height: 170px;
                            }
                        }
                    }
                }

                p {
                    padding-right: 30px;
                }

                h6 {
                    width: 100%;
                    margin: 0;
                }

                ul, p {
                    width: 50%;
                }
            }

            @include mixins.mq(710) {
                ul, p {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }

        .last {
            flex: 0 1 300px;
            @media(max-width:550px) {flex: none;
                .list-links{height: auto;
                    &>li{
                        &>ul{margin-bottom: 25px;}
                        .tel-block{margin-top: 0; padding-bottom: 20px;}
                    }
                }
                h6, .h6{
                    img{display: none;}
                    &.active{
                        &+.list-links{padding-bottom: 0;
                            &>li{
                                &>p, &>ul{display: grid;}   
                                .tel-block{margin-top: 22px;}                            
                            }
                        }
                    }
                }
            }
        }

        @include mixins.mq(1180) {
            padding-top: 0;
            flex-direction: column;
        }
    }

    .footer-top {
        padding: 76px 0 60px;
        @extend %center;

        ul {
            gap: 90px;
            @extend %center;
            li{
                img{max-width: 100px; width: 100%;}
            }
        }
    }

    @include mixins.mq(1400) {
        .footer-top {
            @include mixins.mq(1180) {
                padding-block: 60px;
            }
        }
    }

    @include mixins.mq(1000) {
        .footer-top {
            padding: 48px 0px 30px;

            ul {
                padding: 5px 0px;
                gap: 35px 28px;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    @include mixins.mq(790) {
        .footer-top {
            ul {
                justify-content: center;
            }
        }
    }

    @include mixins.mq(532) {
        .footer-top {
            ul {
                justify-content: space-between;
            }
        }
    }
}

.footer-bottom {background-color: var(--color-yellow); padding: 53px 0 150px; margin: 0 auto;

    &>.container{display: flex; justify-content: space-between; gap: 80px;}
 
    ul {
        @extend %center;
    }

    .footer-bottom__left {flex-direction: column; 
        display: flex;
        gap: 12px;

        a {
            display: flex;
        }

        img {
            align-self: center;
        }

        .legal p {
            color: var(--color-off-black);
            font-size: 1.2rem;
            line-height: 1.7rem;
            font-weight: 400;
            font-family: 'Archivo';
        }
    }

    .footer-bottom__right {
        display: flex;
        gap: 33px;
        align-items: flex-end;
        ul {
            display: flex;
            gap: 18px;

            li {
                a {
                    color:  var(--color-off-black);
                    font-size: 1.2rem;
                    line-height: 18px;
                    img{width:23px}
                }
            }

            &.links {
                gap: 15px;
            }
        }
    }

    @include mixins.mq(1180) {
        border-top: none;
        padding-top: 40;
    }

    @include mixins.mq(710) {
        border-top: 1px solid rgba(white, 0.2);
    }
}


@include mixins.mq(1400) {
    .footer-bottom {
        gap: 50px;

        &__left, &__right {
            flex-direction: column; text-align: center; align-items: center;
        }

        &__right {
            align-items: flex-end;
            gap: 23px;
            justify-content: flex-end;

            ul {
                order: 1;

                &.links {
                    order: 2;
                }
            }
        }

        &__left {
            max-width: 395px;

            .legal {
                br {
                    display: none;
                }
            }
        }
    }
}

@include mixins.mq(1000) {
    .footer-bottom {
        padding: 32px 0 115px;

        ul {
            align-items: flex-start;
        }
    }
}



@include mixins.mq(790) {
    .footer-bottom {       
        &__right {
            gap: 20px !important;
        }
    }
}

@include mixins.mq(750) {
    .footer-bottom {padding: 32px 0 115px;
        >.container{flex-direction: column; align-items: center; gap:15px;}
       
        

        &__right {
            align-items: flex-start;

            .links {
                flex-direction: column;
                //order: 1 !important;
            }
        }

        &__left {
            max-width: none;
        }
    }
}