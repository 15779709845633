@use "_mixins";
@use "_extends";
@use "_colors";
.author-section{padding: 80px 40px; background-color: transparent !important;
  
    .author-section__details{display: flex; gap:24px; max-width: 850px; margin: 0 auto; position: relative; border-radius: 8px; background-color: #FCF9F4; padding: 80px 40px 60px;
        &>img{position: absolute; left: 50%; top:0; transform:translate(-50%, -50%);}
        p{@include mixins.font(1.4, 1.6, 1.5, 400, null, inherit); margin-bottom: 11px; color: var(--color-off-black);}
        label{@include mixins.font(1.2, 1.6, 1.4, 700, null, inherit); color: var(--color-off-black);
            span{@include mixins.font(1.2, 1.6, 1.4, 400, null, inherit); color: var(--color-off-black); display: block;}
        }   
        .author-section__image{height:150px; width: 150px; flex: 0 0 150px; border-radius: 100%;     
            &:before{content: none!important;}
        }
    }  
    
    
    @include mixins.mq(860){padding: 68px 40px;}
    @include mixins.mq(500){padding: 68px 40px 40px;}

    @include mixins.mq(760){
        .author-section__details{flex-direction:column; gap:0; position:relative; padding: 48px 24px 24px;
            label{
                span{display:block; }
            }
            .author-section__image{height:48px; width:48px; flex: 0 0 48px; position:absolute; bottom:25px; right:24px;}
            p{margin-bottom: 28px;}
        }
    }
}
