@use "_mixins";
@use "_extends";
@use "_colors";
.our-picks{max-width: 1384px; position: relative; 
    h2{@include mixins.font(2.4, 4.5, 6.3rem, 400, -0.09rem, var(--color-off-black), "Magazine Grotesque"); text-align:center; margin-left: -77px; text-align: center;}
   
    .our-pick-card{width:308px; border-radius:8px; overflow:hidden;
        .our-pick-card__image{position:relative; height: 305px; border-radius: 8px; overflow: hidden;
            .tag{background:#E66E61; z-index: 2; display:flex; position:absolute; top:10px; left:10px; align-items: center; margin:0; border-radius: 8px; overflow: hidden;
                label{padding:7px 8px 6px; @include mixins.font(1.4, 1.4, 1.4rem, 700, 0.01rem, white); margin:0; font-family: "Magazine Grotesque", sans-serif;  text-transform: capitalize;}               
            }
            img{@extend %fill;}
        }
        .our-pick-card__content{background-color:white; padding: 19px 0px 31px;
            ul{display:flex; gap: 0px 27px; flex-wrap: wrap;
                li{@include mixins.font(1.4, 1.4, 1.97rem, 400, 0.01rem, var(--color-off-black)); text-transform: uppercase;
                    &:first-child{position:relative;
                        &:after{content:""; position:absolute; top:43%; right:-17px; transform:translate(-50%, -50%); height:4px; width:4px; background: var(--color-off-black); @extend %br-100;} 
                    }
                    &:last-child{
                        &:after{content:none;} 
                    }
                }
            }
            label{@include mixins.font(2, 2.6, 100%, 400, 0, var(--color-off-black), "Magazine Grotesque"); text-transform: uppercase; display: block; margin: 7px 0 13px; cursor: pointer;}
            p{@include mixins.font(1.4, 1.6, 2.1rem, 400, 0, var(--color-off-black)); margin-bottom: 13px; @extend %text-clamp;
                &.duration{color:#6C7175;}
            }
            span{@include mixins.font(1.8, 2.2, 2.5rem, 700, 0, var(--color-off-black), "Magazine Grotesque");}

            .offer{padding-top: 4px;
                small{@include mixins.font(1.8, 1.8, 2.5rem, 600, 0, colors.$indigo); display: block; margin: 7px 0 9px;}
                label{@include mixins.font(1.2, 1.2, 1, 400, 0.09rem, white); background-color: colors.$indigo; display:inline-block; padding: 10px; margin: 19px 0 14px; text-transform: lowercase !important; border-radius: 8px;}
                p{@include mixins.font(1.4, 1.4, 1.9rem, 400, 0, var(--color-off-black)); margin-bottom: 1px;
                    &.based-on{color: colors.$grey3; font-size:1.2rem; line-height:1.9rem;}
                }
                span{@include mixins.font(1.6, 1.8, 2.3rem, 600, 0, var(--color-off-black), "Magazine Grotesque"); display:block; padding-bottom: 15px; border-bottom: 1px solid #e3e4e5;}
            }
        }
        @include mixins.mq(620){width: 255px;}
    }           
    .swiper-pagination{@extend %swiper-bullets; bottom: 40px; padding-top: 0;
        .swiper-pagination-bullet{background-color:rgba(0, 0, 0, 0.2);}
    }
    
    .swiper-button-prev, .swiper-button-next{@extend %swiper-button; top: 174px; margin-top: 0 !important;}
    .swiper-button-prev{@extend %swiper-button-prev; left: -20px;
        //@include mixins.mq(620){left:10px;}
    }
    .swiper-button-next{right: -20px;
        @include mixins.mq(500){right:10px;}
    } 
 
    &.cards{align-items: flex-start;
        .our-pick-card{width:auto; height:auto;  box-shadow: 0px 8px 32px rgb(0 0 0 / 6%);
            .our-pick-card__content{
                label{margin:10px 0;}
                .offer{
                    label{margin: 0px 0 16px;}
                }
            }
        }
    }
}
