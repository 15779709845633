@use "_mixins";
@use "_extends";
@use "_colors";

.grid-panel{padding-left: 25px; padding-right: 25px; max-width: 1460px; margin: 0 auto;
    
    .grid-panel-titles{
        h2, h3{text-transform: uppercase; text-align: center; line-height: 1; letter-spacing: 0;}
        h2{font-size: clamp(1.2rem, 1.4vw, 1.4rem); font-family: "Archivo", sans-serif; line-height: 1.8rem; font-weight: 400; margin: 0 0 8px;
            @include mixins.mq(500) { margin:0 0 4px; } 
        }
        h3{font-size: clamp(3.2rem, 5.2vw, 5.2rem); margin: 0 0 24px;
            @include mixins.mq(940) { margin:0 0 8px; @include mixins.font(3.2, 3.2, 36px, 700, 0,  #1A1A1A); text-align: center; }
        }
        p{font-size: clamp(1.4rem, 1.6vw, 1.6rem); line-height: 1.5; text-align: center; max-width: 846px; margin: 0 auto 47px; }
        @media(max-width:940px){
            h2{margin-bottom: 4px;}
            h3{margin-bottom: 40px;}
            p{margin-bottom: 30px;}
        }
        @media(max-width:500px){
            h3{margin-bottom: 10px;}
            p{margin-bottom: 20px;}
        }
    }
   
    .grid-panel-content{display: grid; grid-template-columns: 0.706fr 1fr; gap: 16px; height: 80vw; max-height: 650px;
        .grid-panel-left{grid-template: 1.5fr 1fr / 1.5fr 1fr;
            .grid-panel-item{
                &:last-of-type{grid-column: 1/-1;}
            }
        }
        .grid-panel-right{grid-template: 1fr 1.5fr / 1fr 1.5fr; 
            .grid-panel-item{
                &:first-of-type{grid-column: 1/-1;}
            }
        }
        .grid-panel-left, .grid-panel-right{display: grid; gap:16px;
            .grid-panel-item{display: grid; align-content: center; justify-items: center; gap: 10px; height: auto; border-radius: 8px; min-height: 184px; position: relative; overflow: hidden; padding: 20px;
                a { position: absolute; left: 0; right: 0; bottom: 0; top: 0; display: block; cursor: pointer; z-index:3; font-size: 0; }
                label, h2{z-index: 2; text-align: center;}
                label, h2{max-width: 380px;}
                label{font-size: clamp(1.2rem, 1.4vw, 1.4rem); color:white; text-transform: uppercase;}
                h2{font-size: clamp(1.8rem, 2.2vw, 2.2rem); color:white; text-transform: uppercase;}
                &:before{content: ""; position: absolute; inset:0; background-color: rgba(black, 0.2); z-index:2;}
                .deadcenter.imagepicker{top:25px; left:50%; z-index: 3;}
            }
        }
        @media(max-width:1100px){grid-template-columns: 1fr; gap:12px; height: auto; max-height: none;
            .grid-panel-left, .grid-panel-right{gap:12px;
                .grid-panel-item{padding: 10px;}
            }
        }     
        @media(max-width:660px){
            .grid-panel-left{grid-template: 1fr 1fr / 1fr 1fr;}
            .grid-panel-right{grid-template: 1fr 1fr / 1fr 1fr;}
        }
    }

    @media(max-width: 767px){padding-left: 16px; padding-right: 16px;}
}




