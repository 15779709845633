@use "_mixins";
@use "_extends";
@use "_colors";

/* Content Block Offers */
.content-block-offers { overflow:hidden;
    h2 { width:100%; @include mixins.font(1.2, 1.4, 18px, 400, 0,  #1A1A1A); text-align:center; text-transform:uppercase; margin:0 0 8px;
        @include mixins.mq(500) { margin:0 0 4px; }   
    }
    h3 { width:100%; @include mixins.font(3.2, 5.2, 56px, 700, 0,  #1A1A1A); text-align:center; text-transform:uppercase; margin:0 0 24px;
        @include mixins.mq(940) { margin:0 0 8px; @include mixins.font(3.2, 3.2, 36px, 700, 0,  #1A1A1A); }
    }
    .wysiwyg { text-align:center; margin:0 auto 64px; max-width: 846px;
        @include mixins.mq(940) { margin:0 auto 24px; }
    }
    .offers-swiper { padding-bottom:64px; overflow: visible;
        @include mixins.mq(1260) { overflow: visible; }
        .cards { gap:0 !important;
            @include mixins.mq(940) { margin-top:24px; }
        }
        .card { 
            @include mixins.mq(1260) { width:264px; }
            @include mixins.mq(500) { width:240px; }
            .card-image { 
                @include mixins.mq(940) { min-height: 177px; }
            }
            .card-title { order:0; 
                p { @include mixins.font(2.2, 2.2, 26px, 700, 0,  #1A1A1A); text-transform:uppercase; }
                ul { 
                    li { position:relative; margin-right: 16px;
                        &:after { content: ""; position: absolute; top: 50%; right: -18px; transform: translate(-50%, -50%); height: 4px; width: 4px; background:#1A1A1A; border-radius: 100%; }
                        &:last-child { 
                            &:after { content:none; }
                        }
                    }
                }
            }
            .offer-box { order: 1; margin:16px 0 6px; display:flex;
                label { @include mixins.font(1.4, 1.4, 14px, 700, 0, #FFF); margin:0; background-color:#992525; border-radius: 4px; padding: 6px 8px; }
            }
            label { @include mixins.font(2.2, 2.2, 26px, 700, 0, #992525); margin:0; }
            small { @include mixins.font(1.4, 1.4, 20px, 400, 0, #666); }
        }
    }
    .swiper-button-next-offers, .swiper-button-prev-offers { top:75px; margin-top:0; }
    button { display:flex !important; justify-content:center; background-color:transparent !important; margin: 0 auto !important; height:auto !important; padding:0 !important;
        @include mixins.mq(520) { width:100%; }
        div { 
            @include mixins.mq(520) { width:100%; }
        }
        a { min-height:40px; display:flex; align-items:center; justify-content:center; padding:8px 16px; border-radius:8px; border:2px solid #000; @include mixins.font(1.6, 1.6, 22px, 700, 0,  #1A1A1A, "Magazine Grotesque"); 
            span { @include mixins.font(1.6, 1.6, 22px, 700, 0,  #1A1A1A); font-size:16px !important; }
        }
    }
}

