@use "_mixins";
@use "_extends";
@use "_colors";

/* Conten Block Awards */
.content-block-awards {
    .container { max-width:1000px; }
    section { text-align: center;
        h4 { @include mixins.font(2.4, 2.8, 32px, 700, 0,  #1A1A1A); text-align:center; margin:0 0 24px; }
        .wysiwyg { margin:0 0 40px; }
        .awards-grid { display:flex; justify-content: space-between; gap:40px; margin:0 0 40px;
            @include mixins.mq(760) { display: grid; grid-template-columns: 1fr 1fr; }
            .item {
                @include mixins.mq(760) { margin:0 auto; }
                img { height:110px; object-fit:contain !important; }
            }
        }
        .cta { display:flex; justify-content:center;
            a { min-height:40px; display:flex; align-items:center; justify-content:center; padding:8px 16px; border-radius:8px; border:2px solid #000; @include mixins.font(1.6, 1.6, 22px, 700, 0,  #1A1A1A, "Magazine Grotesque"); 
                @include mixins.mq(520) { width:100%; }
            }
        }
    }
}

