@use "_mixins";
@use "_colors";

%serif{font-family: "Magazine Grotesque", serif;}
%sans-serif{font-family: "Archivo", sans-serif;}
%h1{font-size: 6.2rem; font-weight: 400; line-height: 72px; @extend %serif; color: colors.$darkGray;}
%h2{font-size: clamp(2rem, 3.2vw, 3.2rem); line-height: 110%; letter-spacing: 0.13rem; @extend %serif; color: colors.$darkGray;}
%h3{font-size: 6rem; font-size: clamp(2rem, 6vw, 6rem);  line-height:140%; font-weight: 400; letter-spacing: -0.14rem; @extend %serif; color: colors.$darkGray;}
%h4{font-size: clamp(1.8rem, 2.6vw, 2.6rem); line-height: 100%; letter-spacing: -0.03rem;; @extend %serif; color: colors.$darkGray;}
%h5{font-size: clamp(3rem, 4.8vw, 4.8rem); line-height: 100%; font-weight: 400; letter-spacing: -0.09rem; @extend %serif; color: colors.$darkGray;}
%h6{font-size: clamp(3.2rem, 4.4vw, 4.4rem); line-height: 100%; font-weight: 400; letter-spacing: -0.13rem; margin-top: 130px; text-align: center; @extend %serif; color: colors.$darkGray;}
%text{font-size: clamp(1.4rem, 1.6vw, 1.6rem); line-height: 1.5; }
%text-medium{font-size: clamp(1.6rem, 2vw, 2rem); line-height: 100%; }
%text-large{font-size: clamp(1.6rem, 2.2vw, 2.2rem); line-height: 100%;}
%center{display: flex; align-items: center; justify-content: center;}
%pin{position: absolute; top:0; right:0; bottom:0; left:0;}
%uppercase{text-transform: uppercase;}
%br-10{border-radius:10px;}
%br-100{border-radius:100%;}
%fill{width:100%; height:100%; object-fit:cover;}
%scrollbar{padding: 10px 10px 10px 0px; height:100%;
    &>div{&::-webkit-scrollbar{width: 8px;} &::-webkit-scrollbar-track {background-color: rgba(black, 0.07); @extend %br-10; margin: 0 0;} &::-webkit-scrollbar-thumb {background-color: colors.$teal; @extend %br-10;} position:relative; height: 100%; overflow: auto;}   
}
%swiper-bullets{opacity:1; bottom:0 !important; position:relative; padding-top:40px;
    .swiper-pagination-bullet{background-color:rgba(255,255,255, 0.5); opacity:1; width:5px; height:5px; margin:0 4px; 
        &.swiper-pagination-bullet-active{background-color: black; opacity:1;}
    }
}
%swiper-button{margin-top: -70px; height: 40px; width: 40px; border-radius: 100%; overflow: hidden; 
    &:after{content: none;}
    &:before{content: ""; height: 100%; width: 100%; background: url("../../images/icons/swiper-arrow-white.svg") no-repeat center black; position: relative; left: 0; background-size: 7px;}
    &.swiper-button-disabled{opacity: .7;
        &:before{background: url("../../images/icons/swiper-arrow.svg") no-repeat center #E5E5E5; background-size: 7px;}
    }
    @include mixins.mq(680){margin-top: -100px;}
}
%swiper-button-prev{
    &::before{transform: rotate(-180deg); left:0;}
}
%shadow{box-shadow: 0px 6px 18px rgb(0 0 0 / 8%);} 
%text-clamp{overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 4;} @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {p{max-height: 80px;}}
