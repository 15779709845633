@use "../components/_mixins";
@use "../components/_extends";
@use "../components/_colors";
@use "../components/_main";
@use "../components/_titles";
@use "../components/_cards";
@use "../components/_buttons";
@use "../components/_imageGrid";
@use "../components/_heroStore";
@use "../components/_authorSection";
@use "../components/_storeSummary";
@use "../components/_cardCarousel";
@use "../components/_newsletterSignup";
@use "../components/_newsletterSignupLarge";
@use "../components/_locationsMap";
@use "../components/_sidebar";
@use "../components/_modal";
@use "../components/_tooltip";
@use "../components/_floatingCtaButton";

.page-content{
    margin-top: 120px;
}

.our-team{
    .title{padding-top: 80px;
        h2{letter-spacing: -0.087rem;
            font-weight: 700;
            font-size: clamp(3.2rem, 5.2vw, 5.2rem);
            text-transform: uppercase;        
        }
        @media(max-width: 660px){padding-top: 40px;}
    }
}

#find-store-trigger {display: flex; align-items: center; cursor: pointer; color: colors.$darkGray; gap: 3px; justify-content: end;
    &>img{width: 26px;}
    label {text-transform: uppercase; font-size: 1.4rem; font-family: "Archivo", sans-serif; font-weight: 700; letter-spacing: 0; cursor: pointer;}
    @media(max-width: 960px){label {font-size: 1.2rem;}}
    @media(max-width: 500px){label {max-width: none; line-height: 1.32rem;}}
}

.page-title{padding: 95px 0 126px;
    #find-store-trigger{transform: translateY(90%);
        @media(max-width: 600px){transform: none; padding-bottom: 20px; justify-content: center;}
    }
}
.store-summary{padding: 120px 0 100px;}
.author-section{margin: 20px 0 0;}
.card-carousel{padding:45px 0 0 20px;}
.picked-by-store-title{padding: 50px 0 70px;}
.where-to-find-title{
    &.is-partner{ padding-top: 37px;}
    padding: 0px 0 25px;
}

.locations-map{margin-bottom: 127px;}

.basic-address{ width:300px; text-align:center; margin:20px auto 180px; line-height:2rem;
    a{font-size: 1.6rem;margin-top:20px;display:block;}
}

@include mixins.mq(860){
    .page-title{padding: 80px 0;}
    .store-summary{padding: 100px 0 60px;}
    .author-section{margin: 10px 0 0;}
    .card-carousel{padding: 45px 0 0 20px;}
    .picked-by-store-title{padding: 40px 0 20px;}
    .where-to-find-title{
        &.is-partner{ padding-top: 80px;}
       
    }   
}

@include mixins.mq(620){
    .page-title{padding: 60px 0;}
    .card-carousel{padding: 45px 0 0 20px;}
    .store-summary{padding:60px 0 40px;}
    .picked-by-store-title{padding: 40px 0 20px;}
}