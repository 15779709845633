@use "_mixins";
@use "_extends";
@use "_colors";

/* Content Block Experts */
.content-block-experts { padding:0; 
    .cta-component__section {
        &.order-1 { order:1; }
        &.order-2 { order:2; }
        &.order-3 { order:3; }
        &.order-4 { order:4; }
    }
}
