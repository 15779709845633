@use "_mixins";
@use "_extends";
@use "_colors";

/* Conten Block Suggested */
.content-block-suggested { overflow:hidden;
    section {  overflow:visible;
        h2 { width:100%; @include mixins.font(1.2, 1.4, 18px, 400, 0,  #1A1A1A); text-align:center; text-transform:uppercase; margin:0 0 8px;
            @include mixins.mq(500) { margin:0 0 4px; }   
        }
        h3 { width:100%; @include mixins.font(3.2, 5.2, 56px, 700, 0,  #1A1A1A); text-align:center; text-transform:uppercase; margin:0 0 32px;
            @include mixins.mq(940) { margin:0 0 40px; @include mixins.font(3.2, 3.2, 36px, 700, 0,  #1A1A1A); }
        }
        .suggested-tabs-swiper { display:flex; margin-left: -77px; width: calc(100% + 154px); padding-left: 77px; padding-right: 77px;
            .suggested-tabs { display:flex; align-items:center; gap:0; margin-bottom:40px; width:auto;
                @include mixins.mq(1100) { white-space: nowrap; width: 100%; position: relative; padding: 0; }
                li { cursor:pointer; width:auto; display:flex; height:40px; padding:8px 16px; border-radius:8px; border:1px solid #000; @include mixins.font(1.6, 1.6, 22px, 400, 0,  #1A1A1A); 
                    &.active { background-color:#FFE186; border:1px solid #FFE186; }
                }
            }
            @include mixins.mq(860){margin-left: -42px; width: calc(100% + 82px); padding-left: 42px; padding-right: 42px;}
            @include mixins.mq(620){margin-left: -30px; width: calc(100% + 60px); padding-left: 30px; padding-right: 30px;}
            @include mixins.mq(374){margin-left: -20px; width: calc(100% + 40px); padding-left: 20px; padding-right: 20px;}
        }
        .content { overflow:hidden;
            @include mixins.mq(1260){overflow:visible;}
            .wysiwyg { max-width:846px; margin:0 auto 40px; text-align:center; }
            .suggested-tab{display:none; 
                &.active { display:block; }
                .wtp-card{
                    height: 180px;
                    width: 294px;
                    margin-left: 16px;
                    background-color: #bbf;
                    padding: 10px;
                }
            }
        }
        .suggested-swiper { margin:0 0 40px; overflow: visible;
            .card { 
                @include mixins.mq(1260) { width: 232px; }
                .card-image { height:300px; border-radius:8px; background-size: cover;
                    @include mixins.mq(1260) { height:177px; }
                }
                .card-title { margin:16px 0; 
                    p { @include mixins.font(2.2, 2.2, 26px, 700, 0,  #1A1A1A); text-transform:uppercase; }
                    ul { display: flex; gap: 1px 10px; font-size: 1.2rem; font-size: clamp(1.2rem, 1.4vw, 1.4rem); line-height: 1.4rem; font-weight: 400; color: var(--color-off-black); font-family: "Archivo"; margin-bottom: 9px; flex-wrap: wrap;
                        li { position:relative; margin-right: 16px; @include mixins.font(1.4, 1.4, 18px, 400, 0, #1A1A1A);
                            &:after { content: ""; position: absolute; top: 50%; right: -18px; transform: translate(-50%, -50%); height: 4px; width: 4px; background:#1A1A1A; border-radius: 100%; }
                            &:last-child { 
                                &:after { content:none; }
                            }
                        }
                    }
                }
                .price { margin:16px 0 ; @include mixins.font(2.2, 2.2, 26px, 700, 0, #1A1A1A); }
            }
            .swiper-button-next, .swiper-button-prev { top: 135px; }
        }
        button { display:flex !important; justify-content:center; background-color:transparent !important; margin: 0 auto !important; padding-left:0 !important; padding-right:0 !important;
            @include mixins.mq(520) { width:100%; }
            a { min-height:40px; display:flex; align-items:center; background-color:#FFD00D; justify-content:center; padding:8px 16px; border-radius:8px; border:0; @include mixins.font(1.6, 1.6, 22px, 700, 0,  #1A1A1A, "Magazine Grotesque"); 
                @include mixins.mq(520) { width:100%; }
                span { @include mixins.font(1.6, 1.6, 22px, 700, 0,  #1A1A1A); font-size:16px !important; }
            }
        }
    }
}

